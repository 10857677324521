import React, { useState } from 'react';

import TidLogo from 'img/tid.svg';
import ies21 from 'img/logo-ies.svg';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge';
import SubLoading from '../../loading';
import Avatar from '../../avatar';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenMenu } from 'redux/actions/aplication';
import Search from './search';

import { HeaderLogo, HeaderCont, HeaderTopButtons } from '../../styles';

function Header(props) {
    const dispatch = useDispatch();
    const openMenu = useSelector(state => state.aplication.openMenu);

    const {
        titulo,
        avatarData = undefined,
        utilesVisible = false,
        paginaRef = undefined,
        loading,
        imagesLoaded = false,
        isTid = false,
        showPreguntasBtn = false,
        showPreguntas = undefined,
        openPreguntas = false,
        showUtilsBtn = false,
        showUtils = undefined,
        openUtils = false,
        sendAction = undefined,
        preguntasSize = 0,
    } = props;

    const [visibleSearch, setvisibleSearch] = useState(false);

    const showSearch = paginaRef !== undefined ? true : false;
    const showSend = sendAction !== undefined ? true : false;

    const trigger = useScrollTrigger();

    const handleOpenMenu = () => {
        if (!loading) dispatch(setOpenMenu(!openMenu));
    };

    return (
        <>
            <Slide in={visibleSearch || loading ? true : !trigger}>
                <HeaderCont styleParams={{ showPreguntasBtn, showUtilsBtn }}>
                    <HeaderLogo styleLoading={loading} onClick={handleOpenMenu}>
                        {isTid ? <img src={TidLogo} alt="" /> : <img src={ies21} className="ieslogo" alt="" />}
                    </HeaderLogo>

                    <h1>
                        {avatarData ? (
                            <strong className="dispInFlex">
                                <Avatar usuario={avatarData} />
                                <span className="ml7">
                                    {avatarData.nombres} {avatarData.apellidos}
                                </span>
                            </strong>
                        ) : (
                            <>
                                {loading && <SubLoading />}
                                {titulo}
                            </>
                        )}
                    </h1>
                    {!loading && (
                        <>
                            {showSearch && (
                                <HeaderTopButtons>
                                    {showUtilsBtn && !visibleSearch ? <div className="menu" onClick={() => showUtils(!openUtils)}></div> : null}
                                    {showPreguntasBtn && !visibleSearch ? (
                                        <Badge badgeContent={preguntasSize} className="pregunta-badge">
                                            <div className="preguntas" onClick={() => showPreguntas(!openPreguntas)}></div>
                                        </Badge>
                                    ) : null}

                                    <Search
                                        utilesVisible={utilesVisible}
                                        paginaRef={paginaRef}
                                        loading={loading}
                                        imagesLoaded={imagesLoaded}
                                        visibleSearch={visibleSearch}
                                        setvisibleSearch={setvisibleSearch}
                                    />
                                </HeaderTopButtons>
                            )}

                            {showSend && (
                                <button className="enviarh" onClick={sendAction}>
                                    Enviar
                                </button>
                            )}
                        </>
                    )}
                </HeaderCont>
            </Slide>
        </>
    );
}
export default React.memo(Header, (prevProps, nextProps) => {
    // console.log({prevProps, nextProps})
});
