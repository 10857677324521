import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import IconTID from '../../img/material.svg';
import { Button } from '@material-ui/core';
import { DownIcon } from '../../components/icons';
import Titulo from 'components/TituloH2';
import ProgresoItem from './ProgresoItem';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    margin-bottom: 40px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
`;
const TidContainer = styled('div')`
    color: #fff;
    font-size: 24px;
    background-color: ${props => props.bg};
    padding: 5px 15px;
    height: 120px;
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-position: center;
    small {
        font-size: 14px;
    }
    cursor: pointer;
    :hover {
        opacity: 0.75;
    }
`;

const DataMateria = styled('div')`
    padding: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    h3 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
    div div.MuiCircularProgress-colorPrimary {
        color: #00aae4;
    }
`;

const Line = styled('div')`
    border: none;
    height: 1px;
    background-color: #c1c1aa;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
`;

const Footer = styled('div')`
    display: flex;
    justify-content: flex-end;
    button {
        margin: 15px !important;
    }
`;

export default function TidBox({ data, rol = 'alumno' }) {
    //console.log(data);
    const history = useHistory();

    const { desc, id_tid, is_TID6, avance, url_pdf, oldTid, cond } = data;

    const realPercentView = Math.ceil(avance);

    const handleClick = () => {
        //console.log(id_tid, is_TID6);
        if (is_TID6) {
            history.push(`/tid/${id_tid}`);
        } else {
            window.open(oldTid, '_blank');
        }
    };

    return (
        <div>
            <Titulo>TEXTO DE ESTUDIO</Titulo>
            <Container>
                <TidContainer bg={'#ff6859'} onClick={handleClick} icon={IconTID}></TidContainer>
                <DataMateria>
                    <div style={{ width: '100%' }}>
                        <h3>{desc}</h3>
                        {/* <p>El diseño gráfico es una disciplina indispensable como recurso ordenador.</p> */}
                    </div>
                    {rol !== 'docente' && is_TID6 && cond !== 'N' && <ProgresoItem progress={realPercentView} />}
                </DataMateria>
                {url_pdf !== '' && (
                    <>
                        <Line />
                        <Footer>
                            <Button onClick={() => window.open(`${url_pdf}`, '_blank')} variant="outlined" startIcon={<DownIcon />}>
                                Versión PDF
                            </Button>
                        </Footer>
                    </>
                )}
            </Container>
        </div>
    );
}
