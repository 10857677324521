import React, { useRef } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Avatar from 'components/avatar';

export default function Respuesta({ usuario, dataRespuesta, usuarioRespuesta, denunciarHandle, eliminarHandle, upvotesHandle }) {
    const { time, body, elimina, denuncia, upvotes, isUpvotes, id } = dataRespuesta;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleClickMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const data = {
        id: id,
    };

    const upvotesRef = useRef();
    const handleUpvotes = () => {
        upvotesRef.current.disabled = true;
        data.onSuccess = onUpvotes;
        data.tipo = 'respuestas';
        upvotesHandle(data);
    };
    const onUpvotes = () => {
        upvotesRef.current.disabled = false;
    };

    const denunciar = () => {
        setAnchorEl(null);
        denunciarHandle({ dni: usuario.dni, id, tipo: 'respuestas' });
    };

    const eliminar = () => {
        setAnchorEl(null);
        eliminarHandle(id, 'respuestas');
    };

    return (
        <div className="question-details--answer-container">
            <div className="answer--answer">
                <div className="answer--image">
                    <Avatar usuario={usuarioRespuesta} radius={48} />
                </div>
                <div className="answer--info">
                    <div className="answer--reply-header-container">
                        <span>
                            <span>{usuarioRespuesta.nombres.split(' ')[0]}</span> <br />
                            <small>{time}</small>
                        </span>

                        <div className="question-details--upvotes">
                            <div className="num-upvotes--container">
                                <div className={isUpvotes ? 'num-upvotes--num-upvotes user-upvoted' : 'num-upvotes--num-upvotes'}>{upvotes}</div>
                                <button type="button" ref={upvotesRef} onClick={handleUpvotes} className="btn btn-xs">
                                    <span className={isUpvotes ? 'iesi iesi-arrow-up user-upvoted' : 'iesi iesi-arrow-up'}></span>
                                </button>
                            </div>
                        </div>

                        <div>
                            {(denuncia || elimina) && (
                                <div className="action-menu--dropdown">
                                    <button type="button" aria-controls="menu-pregunta" onClick={handleClickMenu} className="btn btn-xs" aria-haspopup="true">
                                        <span className="iesi iesi-ellipsis-v"></span>
                                    </button>
                                    <Menu id="menu-pregunta" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                        {denuncia && <MenuItem onClick={denunciar}>Denunciar</MenuItem>}
                                        {elimina && (
                                            <MenuItem onClick={eliminar}>
                                                <span className="gRojo">Eliminar</span>
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="answer--body" dangerouslySetInnerHTML={{ __html: body }}></div>
                </div>
            </div>
        </div>
    );
}
