export const URL_API = 'https://deproe.ies21.edu.ar/';

export const API_BASE = `${URL_API}api/miies/v1/`;

export const get_urlTid = TID => `${URL_API}tx/${TID}/pub/app/`;

export const IS_IPAD = navigator.userAgent.match(/iPad/i) != null,
    IS_IPHONE = !IS_IPAD && (navigator.userAgent.match(/iPhone/i) != null || navigator.userAgent.match(/iPod/i) != null),
    IS_IOS = IS_IPAD || IS_IPHONE,
    IS_ANDROID = !IS_IOS && navigator.userAgent.match(/android/i) != null,
    IS_PC = navigator.userAgent.match(/Windows/i) != null,
    IS_MOBILE = !IS_PC && (IS_IOS || IS_ANDROID),
    VERAPP = '1.0.0';

export const device_data =
    'platform:' + navigator.platform + '\nuserAgent:' + navigator.userAgent + '\nvendor:' + navigator.vendor + '\nnavigator' + navigator.language;

export const localVar = {
    getItem: varName => {
        if (localStorage.getItem('perma') === 'false') {
            return sessionStorage.getItem(varName);
        }
        return localStorage.getItem(varName);
    },

    setItem: (varName, varValue) => {
        if (localStorage.getItem('perma') === 'false') {
            sessionStorage.setItem(varName, varValue);
        } else {
            localStorage.setItem(varName, varValue);
        }
    },
    removeItem: varName => {
        if (localStorage.getItem('perma') === 'false') {
            sessionStorage.removeItem(varName);
        } else {
            localStorage.removeItem(varName);
        }
    },
};
export const fromApp = localStorage.getItem('fromApp') !== null && parseInt(localStorage.getItem('fromApp')) === 1 ? true : false;

export const winSize = () => {
    return { width: window.innerWidth, height: window.innerHeight };
};

export const scrollToBottom = () => {
    setTimeout(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    }, 200);
};

export const getWidthPanmat = () => {
    let ww = parseInt(window.innerWidth);
    if (ww > 780) ww = 640;
    const ww86 = parseInt(ww * 0.86);

    return ww86 - 52 - 52 + 'px';
};

export const getScrollbarWidth = () => {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
};

export const winSizeModal = () => {
    const winWH = winSize();
    if (winWH.width < 720) {
        return '90%';
    } else {
        return '50%';
    }
};

export const removeClass = (e, c) => {
    e.className = e.className.replace(new RegExp('(?:^|s)' + c + '(?!S)'), '');
};

export const isDark = false;
// export const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

export const setImages = pagina => {
    const images_im = pagina.getElementsByClassName('im');
    const wcontrol = pagina.offsetWidth;
    for (let i = 0; i < images_im.length; i++) {
        const el = images_im.item(i);
        //console.log(el.parentNode.getElementsByClassName('fuente-page').length);
        if (
            el.alt !== 'IES21' &&
            el.alt !== 'undefined' &&
            el.alt !== '' &&
            el.parentNode.getElementsByClassName('fuente-page').length < 1 &&
            el.className.indexOf('sy') === -1
        ) {
            let spalt = document.createElement('span');

            spalt.textContent = 'Fuente: ' + el.alt;
            spalt.className = 'fuente-page';

            // prevent async onload images class
            const originalClassName = el.className.replace(' loadingimg', '');
            // console.log(el.className, originalClassName)
            if (originalClassName.indexOf(' l') > -1 || originalClassName.indexOf(' r') > -1) {
                spalt.textContent = '* Fuente: ' + el.alt;
                if (originalClassName.indexOf(' l') > -1) {
                    spalt.className = 'fuente-page left';
                } else if (originalClassName.indexOf(' r') > -1) {
                    spalt.className = 'fuente-page right';
                }
            }
            // console.log(el.parentNode.parentNode.tagName)
            if (el.parentNode.parentNode.tagName === 'DIV') {
                el.parentNode.insertBefore(spalt, el.nextSibling);
            } else {
                try {
                    el.parentNode.parentNode.insertBefore(spalt, el.nextSibling);
                } catch (e) {
                    console.log(el.parentNode.parentNode, el.parentNode.parentNode.tagName, e);
                }
            }
            //el.parentNode.appendChild(spalt);
        }
        if (wcontrol >= el.naturalWidth && el.parentNode.tagName !== 'A') {
            if (el.className.indexOf('dcursor') === -1) {
                el.className += ' dcursor';
            }
        } else {
            removeClass(el, 'dcursor');
        }
    }
    const images_ic = pagina.getElementsByClassName('ic');
    for (let i = 0; i < images_ic.length; i++) {
        const el = images_ic.item(i);
        if (!el.id && el.parentNode.tagName !== 'A') {
            el.className += ' dcursor';
        }
    }
};

export const getOffsetTop = elem => {
    let offsetTop = 0;
    if (elem.offsetParent) {
        while (elem) {
            offsetTop += elem.offsetTop;
            elem = elem.offsetParent;
        }
    }
    return offsetTop >= 0 ? offsetTop : 0;
};

export const getFreeSizeLocalstorage = () => {
    let max = 'x',
        s,
        lower_bound = 1,
        upper_bound = 1,
        middle;

    try {
        while (true) {
            localStorage.setItem('test', max);
            lower_bound = upper_bound;
            upper_bound *= 2;
            max = max + max;
        }
    } catch (e) {}

    while (upper_bound - lower_bound > 2) {
        try {
            middle = (lower_bound + upper_bound) / 2;
            s = max.substr(0, middle);
            localStorage.setItem('test', s);
            lower_bound = middle;
        } catch (e) {
            upper_bound = middle;
        }
    }
    localStorage.removeItem('test');
    return lower_bound / 1024;
};

export const checkRemainingBytes = () => {
    console.log('checkRemainingBytes');
    let _lsTotal = 0,
        _xLen,
        _x;
    for (_x in localStorage) {
        _xLen = ((localStorage[_x].length || 0) + (_x.length || 0)) * 2;
        _lsTotal += _xLen;
    }
    return _lsTotal / 1024;
};

export const esMobile = () => {
    if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true;
    } else {
        return false;
    }
};

export const getRandomString = length => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const stringToHslColor = str => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return 'hsl(' + h + ', ' + 40 + '%, ' + 60 + '%)';
};

export const restoreSelection = range => {
    if (range) {
        if (window.getSelection) {
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            return true;
        } else if (document.getSelection && range.select) {
            range.select();
            return true;
        }
    } else {
        return false;
    }
};

export const saveSelection = () => {
    if (window.getSelection) {
        const sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            return sel.getRangeAt(0);
        }
    } else if (document.getSelection && document.createRange) {
        return document.createRange();
    }
    return null;
};

export const coloresModulos = [
    ['860000', 'd83c2d', 'ff7b6c', 'ffccbb', 'ff7b6c', 'd83c2d', '860000', 'd83c2d', 'ff7b6c', 'ffccbb'],
    ['8b0029', 'ba1c57', 'ff6ca8', 'febcd8', 'ff6ca8', 'ba1c57', '8b0029', 'ba1c57', 'ff6ca8', 'febcd8'],
    ['1e013b', '6d248d', 'aa69c5', 'ddc6e0', 'aa69c5', '6d248d', '1e013b', '6d248d', 'aa69c5', 'ddc6e0'],
    ['10266f', '0167b1', '23a7f1', '39d5fe', '23a7f1', '0167b1', '10266f', '0167b1', '23a7f1', '39d5fe'],
    ['005c01', '009c41', '3ddc80', '8effc1', '3ddc80', '009c41', '005c01', '009c41', '3ddc80', '8effc1'],
    ['a84410', 'e67f22', 'f9b32f', 'fde3a8', 'f9b32f', 'e67f22', 'a84410', 'e67f22', 'f9b32f', 'fde3a8'],
    ['932210', 'd3623a', 'ffa17b', 'ffdcb6', 'ffa17b', 'd3623a', '932210', 'd3623a', 'ffa17b', 'ffdcb6'],
];

export const _imageEncode = response => {
    let image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
};

export const passwordValidation = pass => {
    if (pass.length < 6) {
        return { ok: false, error: 'Las contraseñas deben tener longitud mínima de 6 caracteres' };
    }
    if (pass.length > 15) {
        return { ok: false, error: 'La contraseña no debe superar 15 caracteres' };
    }
    if (pass.match(/^\S+$/) === null) {
        return { ok: false, error: 'La contraseña no debe tener espacios' };
    }
    if (null !== pass.match(/(.)(.*\1){5}/)) {
        return { ok: false, error: 'La contraseña es demasiado débil' };
    }
    if (pass.length < 4) {
        return { ok: false, error: 'Escriba la contraseña actual' };
    }

    return { ok: true };
};
