import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import Titulo from 'components/TituloH2';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    margin-bottom: 40px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    padding: 15px;
`;

export default function EncuestaBox({ data }) {
    if (!data || data.length < 1) return null;
    return (
        <div>
            <Titulo>ENCUESTA SOBRE LA MATERIA</Titulo>
            <Container>
                <p>¿Cómo te fue {data.encuesta.materia} este semestre? Tu opinión nos ayuda a mejorar.</p>
                <p></p>
                <Button variant="outlined">Encuesta</Button>
            </Container>
        </div>
    );
}
