import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
    margin: 15px;
    display: flex;
`;

const Fecha = styled('div')`
    display: flex;
    flex-direction: column;
    background-color: #00aae4;
    color: #fff;
    width: 64px;
    height: 64px;
    min-width: 64px;
    justify-content: center;
    align-items: center;
    div.mes {
        font-size: 13px;
        font-weight: bold;
    }
    div.dia {
        font-size: 40px;
        font-weight: bold;
        line-height: 1;
    }
`;

const Info = styled('div')`
    display: flex;
    flex-direction: column;
    color: #323232;
    padding-left: 15px;
    a {
        color: #00aae4;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
    }
`;

export default function Evento({ data, style = null }) {
    const { dia, hora, lugar, mes, titulo, url } = data;

    return (
        <Container style={style}>
            <Fecha>
                <div className="mes">{mes}</div>
                <div className="dia">{dia}</div>
            </Fecha>
            <Info>
                <a href={`https://ies21.edu.ar/${url}`} target="_blank" rel="noopener noreferrer">
                    {titulo}
                </a>
                <span>{lugar}</span>
                <span>{hora}</span>
            </Info>
        </Container>
    );
}
