import { SNACKBAR_MSG, SET_OPEN_MENU, SET_APP_LOADING, SET_APP_TITULO, SET_APP_ISMENUTOP } from '../types';

// const ss = {txt:'Oh! Se ha perdido la conexión! 😱', rest:{ variant: 'warning', anchorOrigin: {vertical: 'top', horizontal: 'center'}}}
export function mostrarSnack(txt, rest = null) {
    return dispatch => {
        console.log(txt, rest);
        dispatch({
            type: SNACKBAR_MSG,
            payload: {
                txt,
                rest,
            },
        });
    };
}

export function setOpenMenu(open) {
    return dispatch => {
        dispatch({
            type: SET_OPEN_MENU,
            payload: open,
        });
    };
}

export function setLoading(v) {
    return dispatch => {
        dispatch({
            type: SET_APP_LOADING,
            payload: v,
        });
    };
}

export function setTitulo(v) {
    return dispatch => {
        dispatch({
            type: SET_APP_TITULO,
            payload: v,
        });
    };
}

export function setIsMenuTop(istop) {
    return dispatch => {
        dispatch({
            type: SET_APP_ISMENUTOP,
            payload: istop,
        });
    };
}
