import React, { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import { mostrarAlerta } from 'redux/actions/alertaActions';
import clienteAxios from 'config/axios';
import Skeleton from 'react-loading-skeleton';
import { _imageEncode } from '../../components/constants';
import styled from '@emotion/styled';
import { winSize } from '../../components/constants';

const Credential = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.h}px;
    margin-top: 5px;
    img {
        height: 100%;
    }
`;

export default function Credencial() {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);

    const [credencial, setCredencial] = useState();

    useTitle('mi IES - Perfil');

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));

            try {
                let result = await clienteAxios.get(`/credencial`, { responseType: 'arraybuffer', timeout: 30000 });

                setCredencial(_imageEncode(result));
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (Object.keys(usuario).length > 0) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [usuario]);

    useEffect(() => {
        dispatch(setTitulo('Credencial IES'));
    }, [dispatch]);

    const wh = winSize().height - 75 - 62;
    const ww = wh * 0.68;

    return (
        <>
            {credencial && (
                <Credential h={wh}>
                    <img src={credencial} alt="" />
                </Credential>
            )}
            {loading && (
                <div style={{ margin: 'auto', marginTop: '5px', textAlign: 'center' }}>
                    <Skeleton width={ww} height={wh} />
                </div>
            )}
        </>
    );
}
