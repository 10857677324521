import React, { useEffect } from 'react';
import { useTitle } from 'react-use';
import Skeleton from 'react-loading-skeleton';

import { setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import CarreraItem from '../components/carrera/CarreraItem';
import Titulo from 'components/TituloH2';

export default function CarrerasList() {
    const dispatch = useDispatch();
    const usuario = useSelector(state => state.usuario.usuario);
    const carreras = useSelector(state => state.homeData.carreras);

    useTitle('mi IES - Mis Carreras');

    useEffect(() => {
        dispatch(setTitulo('Carreras'));
    }, [dispatch]);

    if (!Object.keys(usuario).length > 0) return null;

    return (
        <>
            {carreras && (
                <div>
                    {usuario.rol === 'docente' && carreras.docente && carreras.docente.length > 0 && (
                        <>
                            <Titulo>Carreras docente</Titulo>
                            <div>
                                {carreras.docente.map((item, key) => {
                                    return <CarreraItem data={item} key={key} />;
                                })}
                            </div>
                            <hr />
                        </>
                    )}

                    {usuario.rol === 'alumno' && carreras.alumno && carreras.alumno.length > 0 && (
                        <>
                            <Titulo>Carreras cursando</Titulo>
                            <div>
                                {carreras.alumno.map((item, key) => {
                                    return <CarreraItem data={item} key={key} />;
                                })}
                            </div>
                            <hr />
                        </>
                    )}
                </div>
            )}
            {!carreras && (
                <>
                    <div style={{ padding: '15px' }}>
                        <Skeleton height={130} />
                    </div>
                </>
            )}
        </>
    );
}
