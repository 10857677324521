import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SubLoading from 'components/loading';
import { useSnackbar } from 'notistack';
import { passwordValidation } from '../../components/constants';

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    hr {
        width: 100%;
        height: 1px;
        background: #c0c0c0;
        margin-top: 15px;
    }
    div:nth-of-type(1) {
        display: flex;
        flex-direction: row;
        width: 100%;
        div {
            display: flex;
            flex-direction: column;
        }
    }
`;
const Label = styled('div')`
    font-size: 16px;
    color: #999;
`;
const Content = styled('div')`
    margin-top: 6px;
    font-size: 14px;
    color: #323232;
`;

const ButtonStyled = styled(Button)`
    font-size: 16px !important;
    font-weight: normal !important;
    text-transform: none !important;
    width: fit-content;
`;

const ButtonStyledCancel = styled(ButtonStyled)`
    min-width: 30px !important;
    .MuiButton-label {
        color: #d42a2a;
    }
`;

const InputEdit = styled('input')`
    color: #000;
    text-align: left;
    margin: 0;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 14px;
    height: 33px;
`;

const AuxText = styled('span')`
    color: rgba(0, 0, 0, 0.45);
    margin-top: 0.33333em;
    transition: opacity 0.15s ease-out;
    text-align: left;
    font-size: 12px;
`;

export default function EditBox({ label, value, name, saveItem, type = 'text', helpTx, disbaled, onChange, options }) {
    const { enqueueSnackbar } = useSnackbar();
    const loading = useSelector(state => state.aplication.loading);

    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(false);
    const [newVal, setNewVal] = useState(value);

    const [passval, setPassval] = useState('');
    const [passval1, setPassval1] = useState('');
    const [passval2, setPassval2] = useState('');

    const handleClick = () => {
        setError(false);
        setEdit(true);
    };

    const handleClickCancel = () => {
        setError(false);
        setEdit(false);
    };

    return (
        <Container>
            <div>
                <div>
                    {!(type === 'password' && edit) && <Label>{label}</Label>}
                    {edit && !disbaled ? (
                        <>
                            {type === 'password' ? (
                                <>
                                    <Label>Contraseña actual</Label>

                                    <InputEdit
                                        type={type}
                                        value={passval}
                                        onChange={e => {
                                            setPassval(e.target.value);
                                        }}
                                        name="passval"
                                        autoFocus
                                    />
                                    <Label>Contraseña nueva</Label>
                                    <InputEdit
                                        type={type}
                                        value={passval1}
                                        onChange={e => {
                                            setPassval1(e.target.value);
                                        }}
                                        name="passval1"
                                    />
                                    <Label>Repita contraseña nueva</Label>
                                    <InputEdit
                                        type={type}
                                        value={passval2}
                                        onChange={e => {
                                            setPassval2(e.target.value);
                                        }}
                                        name="passval2"
                                    />
                                    {error && <p className="mensaje error">{error}</p>}
                                </>
                            ) : (
                                <>
                                    {type === 'select' ? (
                                        <select
                                            name={name}
                                            value={value || ''}
                                            onChange={e => {
                                                setNewVal(e.target.value);
                                                if (onChange) {
                                                    onChange(e);
                                                }
                                            }}
                                            className="form-control"
                                            autoFocus
                                        >
                                            <option>Seleccione</option>
                                            {options.map((op, key) => {
                                                return (
                                                    <option key={key} value={op.nombre}>
                                                        {op.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    ) : (
                                        <InputEdit
                                            type={type}
                                            value={newVal}
                                            onChange={e => {
                                                setNewVal(e.target.value);
                                                if (onChange) {
                                                    onChange(e);
                                                }
                                            }}
                                            name={name}
                                            autoFocus
                                        />
                                    )}
                                    {error && <p className="mensaje error">{error}</p>}
                                </>
                            )}
                        </>
                    ) : (
                        <Content>{value}</Content>
                    )}
                </div>

                {!disbaled && (
                    <div style={{ justifyContent: 'flex-end' }}>
                        {!edit ? (
                            <ButtonStyled disabled={loading} onClick={handleClick}>
                                Editar
                            </ButtonStyled>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ButtonStyled
                                    disabled={loading}
                                    onClick={async () => {
                                        if (saveItem) {
                                            let res = false;
                                            if (type === 'password') {
                                                if (passval1 !== passval2) {
                                                    setError('Las contraseñas deben ser iguales.');
                                                    return;
                                                }
                                                if (passval1 === passval) {
                                                    setError('La contraseña nueva debe ser distinta a la actual');
                                                    return;
                                                }
                                                if (passval.length < 4) {
                                                    setError('Escriba la contraseña actual');
                                                    return;
                                                }
                                                let valid = passwordValidation(passval1);
                                                if (!valid.ok) {
                                                    setError(valid.error);
                                                    return;
                                                }
                                                res = await saveItem(name, { oldpassword: passval, password: passval1 });
                                            } else {
                                                if (newVal.length < 2) {
                                                    setError('Complete el campo');
                                                    return;
                                                }
                                                res = await saveItem(name, newVal);
                                            }
                                            if (res && res.data.r === 1) {
                                                setEdit(false);
                                            } else {
                                                if (res.data?.text) {
                                                    enqueueSnackbar(res.data.text, {
                                                        variant: 'error',
                                                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                                                    });
                                                } else {
                                                    console.log(res);
                                                }
                                            }
                                        } else {
                                            setEdit(false);
                                        }
                                    }}
                                >
                                    {loading ? <SubLoading /> : <>Guardar</>}
                                </ButtonStyled>
                                <ButtonStyledCancel onClick={handleClickCancel}>X</ButtonStyledCancel>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {edit && helpTx && <AuxText>{helpTx}</AuxText>}
            <hr />
        </Container>
    );
}
