import React, { useState, useEffect, useRef } from 'react';
import LinkWindow from './linkWindow';
import { restoreSelection, saveSelection } from '../constants';

let savedSelection;

export default function Editor({ autoFocus, getHtml, defaultHtml, placeHolder = 'Detalles de la pregunta...' }) {
    const [rtePlaceHolder, setrtePlaceHolder] = useState(placeHolder);
    const [bold, setBold] = useState('');
    const [italic, setItalic] = useState('');

    const [selTxt, setSelTxt] = useState('');
    const [selUrl, setSelUrl] = useState('');

    const [inlineTool, setinlineTool] = useState({ open: false, top: '0px', left: '0px', innerText: '', href: '' });

    const rtEditor = useRef();

    const editableClickBtn = op => {
        document.execCommand(op);
        setChangeRte();
        rtEditor.current.focus();
    };

    const changeRte = () => {
        if (rtePlaceHolder !== '') {
            setrtePlaceHolder('');
        }
        setChangeRte();
    };

    const setChangeRte = () => {
        const valOpBold = document.queryCommandState('bold') ? 'active' : '';
        setBold(valOpBold);
        const valOpItalic = document.queryCommandState('italic') ? 'active' : '';
        setItalic(valOpItalic);
    };

    const handleChange = () => {
        getHtml(rtEditor.current.innerHTML);
    };

    const handleClickRte = e => {
        setChangeRte();
        e.preventDefault();
        let { pageX, pageY } = e;
        let { tagName, href, innerText } = e.target;

        if (tagName === 'A') {
            let left = pageX ? pageX : e.changedTouches[0].pageX;

            left = left - (window.innerWidth - document.getElementById('panelder').offsetWidth);

            let top = pageY ? pageY : e.changedTouches[0].pageY;
            setinlineTool({ open: true, href, innerText, top, left });

            const selection = window.getSelection();
            const ran = document.createRange();
            ran.selectNode(window.getSelection().focusNode);
            selection.removeAllRanges();
            selection.addRange(ran);
            setSelTxt(innerText);
            setSelUrl(href);
            savedSelection = saveSelection();
        } else {
            if (inlineTool.open) {
                setinlineTool({ open: false, href: '', innerText: '', top: '0px', left: '0px' });
            }
        }
    };

    const setCode = () => {
        const selection = document.all ? document.selection.createRange().text : document.getSelection();
        const selection_text = selection.toString();
        const range = selection.getRangeAt(0);

        if (range.startContainer.parentNode.tagName === 'CODE') {
            const txt = range.startContainer.parentNode.innerText;
            //selection.baseNode.remove();
            range.startContainer.parentNode.remove();

            const noCode = document.createDocumentFragment();
            noCode.textContent = txt;
            range.deleteContents();
            range.insertNode(noCode);
        } else {
            if (selection.getRangeAt(0).collapsed) return;

            const span = document.createElement('code');
            span.textContent = selection_text;

            range.deleteContents();
            range.insertNode(span);
        }
        rtEditor.current.focus();
    };

    const openLinkWindow = () => {
        setOpenDialog(true);
    };

    const [openDialog, setOpenDialog] = useState(false);
    const onCloseDoalog = () => {
        setOpenDialog(false);
        if (rtePlaceHolder !== '') {
            setrtePlaceHolder('');
        }
        rtEditor.current.focus();
    };

    const onTextAreaBlur = () => {
        if ((window.getSelection().anchorNode !== null && window.getSelection().anchorNode.parentElement === null) || inlineTool.open) {
            return;
        }

        if (window.getSelection() && window.getSelection().anchorNode !== null && window.getSelection().anchorNode.parentElement !== null) {
            setSelTxt(window.getSelection().toString());
            setSelUrl(window.getSelection().anchorNode.parentElement.href ? window.getSelection().anchorNode.parentElement.href : '');
        }
        if (window.getSelection().anchorNode.parentElement.href) {
            const selection = window.getSelection();
            setSelTxt(window.getSelection().anchorNode.textContent);
            const ran = document.createRange();
            ran.selectNode(window.getSelection().focusNode);
            selection.removeAllRanges();
            selection.addRange(ran);
        }
        savedSelection = saveSelection();
    };

    const desvincular = () => {
        const restored = restoreSelection(savedSelection);
        if (restored) {
            console.log(savedSelection);
            savedSelection.deleteContents();
            document.execCommand('insertHTML', false, selTxt);
            setinlineTool({ open: false });
        }
    };
    const editarHref = () => {
        setinlineTool({ open: false });
        setOpenDialog(true);
    };

    useEffect(() => {
        document.execCommand('defaultParagraphSeparator', false, 'p');
        if (autoFocus) rtEditor.current.focus();
    }, [autoFocus]);

    return (
        <>
            <LinkWindow
                open={openDialog}
                onClose={onCloseDoalog}
                selTxt={selTxt}
                selUrl={selUrl}
                savedSelection={savedSelection}
                restoreSelection={restoreSelection}
            />

            {inlineTool.open && (
                <div className="rt-anchor-tooltip popover--popover--click" style={{ top: inlineTool.top, left: inlineTool.left }} data-placement="bottom">
                    <div className="popover--popper-content">
                        <div className="popover--body">
                            <div className="">
                                <span>
                                    <a href={inlineTool.href} target="_blank" rel="noopener noreferrer">
                                        {inlineTool.innerText}
                                    </a>{' '}
                                    |{' '}
                                    <a href={true} onClick={editarHref}>
                                        Editar
                                    </a>{' '}
                                    |{' '}
                                    <a href={true} onClick={desvincular}>
                                        Desvincular
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <button type="button" onClick={() => setinlineTool({ open: false })} className="popover--close btn btn-xs btn-link">
                        <span aria-label="Cerrar ventana emergente" className="iesi iesi-close"></span>
                    </button>
                    <div data-purpose="arrow" className="popover--arrow" data-placement="bottom"></div>
                </div>
            )}

            <div>
                <div className="rt-menu-bar-container">
                    <div className="rt-menu-bar fx-lt">
                        <div className="btn-group">
                            <button title="Negrita" type="button" className={`btn btn-link ${bold}`} onClick={() => editableClickBtn('bold')}>
                                <span className="iesi iesi-bold"></span>
                            </button>
                            <button title="Cursiva" type="button" className={`btn btn-link ${italic}`} onClick={() => editableClickBtn('italic')}>
                                <span className="iesi iesi-italic"></span>
                            </button>
                            <button title="Insertar enlace" type="button" className="btn btn-link" onClick={openLinkWindow}>
                                <span className="iesi iesi-link"></span>
                            </button>
                            <button title="Código" type="button" className="btn btn-link" onClick={setCode}>
                                <span className="iesi iesi-curly-braces"></span>
                            </button>
                        </div>
                        <div className="fx">&nbsp;</div>
                        <div className="btn-group"></div>
                    </div>
                </div>
            </div>
            <div className="rt-editor rt-editor--empty rt-editor--wysiwyg-mode">
                <div
                    ref={rtEditor}
                    onBlur={onTextAreaBlur}
                    onKeyDown={changeRte}
                    onClick={handleClickRte}
                    onInput={handleChange}
                    autoFocus={autoFocus}
                    contentEditable="true"
                    placeholder={defaultHtml ? '' : rtePlaceHolder}
                    className="ProseMirror"
                    suppressContentEditableWarning={true}
                    dangerouslySetInnerHTML={{ __html: defaultHtml }}
                ></div>
            </div>
        </>
    );
}
