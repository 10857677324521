import { combineReducers } from 'redux';
import usuarioReducer from './usuarioReducer';
import alertaReducer from './alertaReducer';
import notificacionesReducer from './notificacionesReducer';
import tidReducer from './tidReducer';
import aplication from './aplication';
import homeData from './homeData';

export default combineReducers({
    usuario: usuarioReducer,
    alerta: alertaReducer,
    notificaciones: notificacionesReducer,
    dataTid: tidReducer,
    aplication: aplication,
    homeData: homeData,
});
