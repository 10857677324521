import INF from '../img/carreras/1.svg';
import MKT from '../img/carreras/2.svg';
import PUB from '../img/carreras/3.svg';
import ADMIN from '../img/carreras/4.svg';
import DG from '../img/carreras/5.svg';
import RPI from '../img/carreras/6.svg';
import RRHH from '../img/carreras/7.svg';
import CI from '../img/carreras/8.svg';
import AGRO from '../img/carreras/9.svg';
import TUR from '../img/carreras/10.svg';
import DM from '../img/carreras/13.svg';
import GC from '../img/carreras/17.svg';
import LOG from '../img/carreras/18.svg';
import VID from '../img/carreras/60.svg';
import HIG from '../img/carreras/65.svg';
import D3D from '../img/carreras/66.svg';
import ROB from '../img/carreras/67.svg';
import IA from '../img/carreras/69.svg';

export default function useCarreraDesign() {
    const getCarreraDesign = id_carrera => {
        switch (id_carrera) {
            case 1:
                return { icon: INF, color: '#FDCD45' };
            case 2:
                return { icon: MKT, color: '#005193' };
            case 3:
                return { icon: PUB, color: '#E62E4F' };
            case 4:
                return { icon: ADMIN, color: '#F08754' };
            case 5:
                return { icon: DG, color: '#A42A5C' };
            case 6:
                return { icon: RPI, color: '#5F9ECE' };
            case 7:
                return { icon: RRHH, color: '#803E90' };
            case 8:
                return { icon: CI, color: '#7D3F5D' };
            case 9:
                return { icon: AGRO, color: '#A8BE8F' };
            case 10:
                return { icon: TUR, color: '#E95666' };
            case 13:
                return { icon: DM, color: '#81C8BD' };
            case 17:
                return { icon: GC, color: '#5861A6' };
            case 18:
                return { icon: LOG, color: '#0091AB' };
            case 60:
                return { icon: VID, color: '#44BDDD' };
            case 65:
                return { icon: HIG, color: '#5AA28C' };
            case 66:
                return { icon: D3D, color: '#EB6852' };
            case 67:
                return { icon: ROB, color: '#BDBBBB' };
            case 69:
                return { icon: IA, color: '#007CA2' };
            default:
                return { icon: IA, color: '#007CA2' };
        }
    };

    return {
        getCarreraDesign,
    };
}
