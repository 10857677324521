import React, { useState, useEffect, useContext, useRef } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import DenunciaWin from './denunciaWin';
import Avatar from 'components/avatar';
import Respuesta from './respuesta';
import Editor from 'components/editor/editor';
import SubLoading from 'components/loading';

import preguntasContext from 'context/preguntas/preguntasContext';
import { mostrarAlerta } from 'redux/actions/alertaActions';

import { onClickAskUserPermission, onClickSubscribe } from 'redux/actions/notificacionesActions';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export default function VerPregunta({ isOpen, preguntaId, usuario }) {
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const userConsent = useSelector(state => state.notificaciones.userConsent);
    const soportaNotificaciones = useSelector(state => state.notificaciones.soportaNotificaciones);
    const estaSuscripto = useSelector(state => state.notificaciones.estaSuscripto);
    const isReady = useSelector(state => state.notificaciones.isReady);

    const [editorInput, setEditorInput] = useState(false);
    const [details, setDetails] = useState('');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleClickMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const [dataDenuncia, setDatadenuncia] = useState();
    const [openDenuncia, setOpenDenuncia] = useState(false);
    const denunciarHandle = d => {
        setDatadenuncia(d);
        setAnchorEl(null);
        setOpenDenuncia(true);
    };
    const handleCloseDenuncia = () => {
        setOpenDenuncia(false);
    };

    const {
        getPregunta,
        pregunta,
        mensaje,
        resetMensaje,
        loading,
        success,
        loadingInline,
        errorformulario,
        agregarRespuesta,
        mostrarError,
        resetForm,
        eliminarPregunta,
        follow,
        upvotesHandle,
    } = useContext(preguntasContext);

    const data = {
        pregunta_id: preguntaId,
        esDocente: usuario.esDocente,
    };

    const followRef = useRef();
    const handleFollow = () => {
        if (!soportaNotificaciones) {
            setFollow();
        }
        if (isReady) {
            if (estaSuscripto) {
                setFollow();
            } else {
                if (!pregunta.isFollow) {
                    // si deja de seguir no verifica
                    dispatch(
                        mostrarAlerta(
                            'Para recibir seguir respuestas a preguntas, debe suscribirse a las notificaciones',
                            'Seguir respuestas',
                            continueSuscripcion,
                            'Suscribirme'
                        )
                    );
                } else {
                    setFollow();
                }
            }
        } else {
            setFollow();
        }
    };
    const continueSuscripcion = () => {
        if (userConsent === 'denied') {
            dispatch(
                mostrarAlerta(
                    'Para recibir notificaciones debe desbloquear el consentimiento desde las preferencias de la página en su navegador',
                    'Seguir respuestas'
                )
            );
            return;
        }
        if (userConsent === 'default') {
            dispatch(
                mostrarAlerta(
                    'Para recibir notificaciones en este navegador debe aceptar el permiso a continuación',
                    'Seguir respuestas',
                    setDialogSuscripcion,
                    'Aceptar',
                    null
                )
            );
        }
        if (userConsent === 'granted') {
            setDialogSuscripcion();
        }
    };
    const setDialogSuscripcion = () => {
        dispatch(onClickAskUserPermission(onShowNotificationPermissionDialog));
    };
    const setFollow = () => {
        followRef.current.disabled = true;
        data.onSuccess = onFollow;
        follow(data);
    };
    const onShowNotificationPermissionDialog = () => {
        //console.log(userConsent);
        if (userConsent === 'granted' && isReady) {
            dispatch(onClickSubscribe());
            setFollow();
        }
    };
    const onFollow = () => {
        followRef.current.disabled = false;
    };

    const eliminarHandle = (id, tipo) => {
        setAnchorEl(null);
        dispatch(
            mostrarAlerta('¿Está seguro que desea eliminar la ' + tipo + '?', 'Preguntas', eliminarPregunta, 'Eliminar', 'Cancelar', {
                id,
                tipo,
                dni: usuario.dni,
                onSuccess: onElimina,
            })
        );
    };

    const onElimina = tipo => {
        if (tipo === 'pregunta') {
            isOpen(false);
        }
        enqueueSnackbar('Tu ' + tipo + ' ha sido eliminada', {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
    };

    const onSubmitRespuesta = e => {
        console.log('onSubmitRespuesta');

        e.preventDefault();

        if (details === '') {
            mostrarError();
            return;
        }
        data.details = details;
        agregarRespuesta(data);
    };

    // Obtener pregunta cuando carga el componente
    useEffect(() => {
        // si hay un error
        if (mensaje) {
            dispatch(mostrarAlerta(mensaje));
            resetMensaje();
        }
        // eslint-disable-next-line
    }, [mensaje]);

    useEffect(() => {
        // si envio correctamente
        if (success) {
            enqueueSnackbar('Tu respuesta ha sido enviada!', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            setDetails('');
            setEditorInput(false);
            resetForm();
        }
        // eslint-disable-next-line
    }, [success]);

    useEffect(() => {
        getPregunta({ id_pregunta: preguntaId });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <button type="button" className="btn btn-default" onClick={() => isOpen(false)}>
                Volver a las preguntas
            </button>

            <div className="question-details--details">
                {loading ? (
                    <p>
                        <SubLoading /> Cargando...
                    </p>
                ) : (
                    <div>
                        {pregunta ? (
                            <>
                                <DenunciaWin open={openDenuncia} handleClose={handleCloseDenuncia} data={dataDenuncia} />

                                <div className="question-details--question">
                                    <div className="question-details--avatar-container">
                                        <Avatar usuario={pregunta.usuario} radius={48} />
                                    </div>
                                    <div className="question-details--info">
                                        <div className="question-details--question-header-container">
                                            <div className="question-details--title">{pregunta.pregunta.txt}</div>

                                            <div className="question-details--upvotes">
                                                {/* <div className="num-upvotes--container">
                                        <div className="num-upvotes--num-upvotes">0</div>
                                        <button type="button" className="btn btn-xs">
                                            <span className="iesi iesi-arrow-up"></span>
                                        </button>
                                    </div> */}
                                            </div>

                                            <div>
                                                {(pregunta.denuncia || pregunta.elimina) && (
                                                    <div className="action-menu--dropdown">
                                                        <button
                                                            type="button"
                                                            aria-controls="menu-pregunta"
                                                            onClick={handleClickMenu}
                                                            className="btn btn-xs"
                                                            aria-haspopup="true"
                                                        >
                                                            <span className="iesi iesi-ellipsis-v"></span>
                                                        </button>

                                                        <Menu
                                                            id="menu-pregunta"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleCloseMenu}
                                                        >
                                                            {pregunta.denuncia && (
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        denunciarHandle({
                                                                            dni: usuario.dni,
                                                                            id: pregunta.pregunta.id,
                                                                            tipo: 'preguntas',
                                                                        })
                                                                    }
                                                                >
                                                                    Denunciar
                                                                </MenuItem>
                                                            )}
                                                            {pregunta.elimina && (
                                                                <MenuItem onClick={() => eliminarHandle(pregunta.pregunta.id, 'pregunta')}>
                                                                    <span className="gRojo">Eliminar</span>
                                                                </MenuItem>
                                                            )}
                                                        </Menu>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="font-text-xs">
                                            {pregunta.usuario.nombres.split(' ')[0]} · <span>{pregunta.pregunta.time}</span>
                                        </div>
                                        <div className="question-details--body" dangerouslySetInnerHTML={{ __html: pregunta.pregunta.body }}></div>
                                    </div>
                                </div>

                                <div className="question-details--separator-container">
                                    <div className="question-details--separator-numanswers">{pregunta.respuestas.length} respuestas</div>
                                    <button
                                        type="button"
                                        disabled={!isReady}
                                        ref={followRef}
                                        onClick={handleFollow}
                                        className="question-details--separator-follow-replies btn btn-sm btn-quintinary"
                                    >
                                        {pregunta.isFollow ? <>Dejar de seguir</> : <>Seguir respuestas</>}
                                    </button>
                                </div>

                                <div>
                                    <div>
                                        {pregunta.respuestas.map(data => (
                                            <Respuesta
                                                key={data.respuesta.id}
                                                usuario={usuario}
                                                usuarioRespuesta={data.usuario}
                                                dataRespuesta={data.respuesta}
                                                denunciarHandle={denunciarHandle}
                                                eliminarHandle={eliminarHandle}
                                                upvotesHandle={upvotesHandle}
                                            />
                                        ))}
                                    </div>

                                    {pregunta.responde && (
                                        <div className="question-details--dummy-answer-input-container">
                                            <Avatar usuario={usuario} radius={48} />
                                            <div className="question-details--dummy-answer-input form-group">
                                                <label className="control-label sr-only">Responder</label>
                                                {editorInput ? (
                                                    <form onSubmit={onSubmitRespuesta}>
                                                        <div>
                                                            <Editor getHtml={setDetails} autoFocus={true} />
                                                        </div>
                                                        {errorformulario ? <p className="mensaje error">Escriba su pregunta</p> : null}
                                                        <div className="question-editor--buttons btn-toolbar">
                                                            <button
                                                                disabled={details.length < 3 || loadingInline}
                                                                type="submit"
                                                                className="w100p btn btn-primary"
                                                            >
                                                                {loadingInline && <SubLoading />}Publicar
                                                            </button>
                                                        </div>
                                                    </form>
                                                ) : (
                                                    <input
                                                        placeholder="Responder"
                                                        style={{ marginBottom: '-12px' }}
                                                        type="text"
                                                        className="form-control"
                                                        onClick={() => setEditorInput(true)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : null}
                    </div>
                )}
            </div>
        </>
    );
}
