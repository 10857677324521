import {
    PUSH_LOADIG,
    READY_PUSH,
    PUSH_SUPPORTED,
    PUSH_ESTASUSCRIPTO,
    PUSH_PARAMS,
    PUSH_ERRORSUSCRIBE,
    PUSH_USCONSENT,
    PUSH_SUSCRIBE,
    PUSH_UNSUSCRIBE,
} from 'redux/types';

const NotificationSupported = 'Notification' in window;

const initialState = {
    soportaNotificaciones: false,
    isReady: false,
    loadingPush: false,
    estaSuscripto: false,
    pushToken: null,
    hwid: null,
    userId: null,
    errorsuscribe: null,
    userConsent: NotificationSupported ? Notification.permission : false,
};


// eslint-disable-next-line
export default function (state = initialState, action) {

    const Pushwoosh = window.Pushwoosh;
    
    switch (action.type) {
        case PUSH_LOADIG:
            return {
                ...state,
                loadingPush: action.payload,
            };
        case PUSH_SUPPORTED:
            console.log('PUSH_SUPPORTED', action)
            return {
                ...state,
                soportaNotificaciones: action.payload,
            };
        case PUSH_SUSCRIBE:
            if (state.estaSuscripto) {
                return state;
            }            
            Pushwoosh.subscribe();
            console.log("suscribiendo")
            return {
                ...state,
                estaSuscripto: true,
                loadingPush: false,
            };
        case PUSH_UNSUSCRIBE:
            if (!state.estaSuscripto) {
                return state;
            }
            Pushwoosh.unsubscribe();
            console.log("unsuscribiendo")
            return {
                ...state,
                estaSuscripto: false,
                loadingPush: false,
            };
        case PUSH_ESTASUSCRIPTO:
            return {
                ...state,
                estaSuscripto: action.payload,
            };
        case PUSH_PARAMS:
            return {
                ...state,
                pushToken: action.payload.pushToken,
                hwid: action.payload.hwid,
                userId: action.payload.userId,
            };
        case PUSH_ERRORSUSCRIBE:
            return {
                ...state,
                errorsuscribe: action.payload,
            };
        case PUSH_USCONSENT:
            return {
                ...state,
                userConsent: action.payload,
            };
        case READY_PUSH:
            return {
                ...state,
                isReady: action.payload,
            };
        default:
            return state;
    }
}
