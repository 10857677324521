import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '../avatar';
import styled from '@emotion/styled';

import useCarreraDesign from '../../hooks/useCarreraDesign';

import ProgresoItem from './ProgresoItem';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    cursor: ${props => (props.cond !== 'N' && props.cond !== 'Q') && 'pointer'};
    opacity: ${props => (props.cond === 'N' ? 0.3 : 1)};
    :hover {
        color: ${props => (props.cond !== 'N' && props.cond !== 'Q') && '#00aae4'};
    }
`;
const DataCarrera = styled('div')`
    color: #fff;
    font-size: 24px;
    background-color: ${props => props.design.color};
    padding: 5px 15px;
    height: 120px;
    background-image: url(${props => props.design.icon});
    background-repeat: no-repeat;
    background-position: center;
    small {
        font-size: 14px;
    }
`;

const StatePoint = styled('span')`
    display: inline-block;
    min-width: 11px;
    width: 11px;
    height: 11px;
    background-color: #4cb684;
    background-color: ${props => props.color};
    border-radius: 50%;
`;

const DataMateria = styled('div')`
    padding: 15px;
    display: flex;
    font-size: 14px;
    h3 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;

const DataDocente = styled('div')`
    padding: 15px;
    display: flex;
    font-size: 14px;
    h3 {
        font-size: 16px;
        font-weight: normal;
        margin: 0;
    }
    .docName {
        margin-left: 7px;
    }
    .docente {
        width: 100%;
        display: flex;
        align-items: center;
    }
`;

const Line = styled('div')`
    border: none;
    height: 1px;
    background-color: #c1c1aa;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
`;

export default function MateriaItem({ data, rol = 'alumno' }) {
    const history = useHistory();

    const { desc, id_tid, is_TID6, avance, cuatri, docente, id_carrera, cond, id } = data;

    let realPercentView = Math.ceil(avance);
    const handleClick = cond => {
        if (cond === 'N' || cond === 'Q') return;
        let urlRol = rol === 'alumno' ? '' : 'docente';
        history.push(`/app/materia${urlRol}/${id_carrera}/${id}`);
        console.log(id_tid, is_TID6);
        /*
        if (is_TID6) {
            history.push(`/tid/${id_tid}`);
        }*/
    };

    let condicion = 'regular';
    let color = '#ccc';
    if (cond === 'C') {
        condicion = 'en curso';
        color = '#4cb684';
    }
    if (cond === 'L') {
        condicion = 'libre';
        color = '#d42a2a';
    }
    if (cond === 'A') {
        condicion = 'aprobado';
        color = '#00aae4';
    }

    if (cond === 'P') {
        condicion = 'promociona';
        color = '#0035e4';
    }

    if (cond === 'N') {
        condicion = 'no inscripto';
        color = '#eee';
    }

    if (cond === 'Q') {
        condicion = 'equivalencia';
        color = '#eee';
    }

    const { getCarreraDesign } = useCarreraDesign();

    return (
        <Container onClick={() => handleClick(cond)} cond={cond}>
            <DataCarrera design={getCarreraDesign(id_carrera)}>
                {cuatri > 0 && (
                    <>
                        {cuatri} <small>semestre</small>
                    </>
                )}
            </DataCarrera>
            <DataMateria color={color} progress={realPercentView}>
                <div style={{ width: '100%' }}>
                    <h3>{desc}</h3>
                    {/* <p>El diseño gráfico es una disciplina indispensable como recurso ordenador.</p> */}
                </div>
                {rol !== 'docente' && is_TID6 && cond !== 'N' && cond !== 'Q' && <ProgresoItem progress={realPercentView} />}
            </DataMateria>
            {rol !== 'docente' && (
                <>
                    <Line />
                    <DataDocente>
                        <div className="docente">
                            {docente && cond !== 'Q' && (
                                <>
                                    <Avatar usuario={docente} />
                                    <div className="docName">
                                        {docente.nombres}, {docente.apellidos}
                                    </div>
                                </>
                            )}
                        </div>
                        <div style={{ whiteSpace: 'nowrap', alignItems: 'center', display: 'flex' }}>
                            <div>
                                <StatePoint color={color}></StatePoint> {condicion}
                            </div>
                        </div>
                    </DataDocente>
                </>
            )}
        </Container>
    );
}
