import React from 'react';

import {Provider} from 'react-redux';
import store from './redux/store';

import {SnackbarProvider} from 'notistack';

import App from './App';

export default function MainApp() {
    return (
        <SnackbarProvider
            preventDuplicate
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Provider store={store}>
                <App />
            </Provider>
        </SnackbarProvider>
    );
}
