import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
    margin: 15px;
    display: flex;
`;

const Foto = styled('div')`
    display: flex;
    background-color: #00aae4;
    width: 64px;
    height: 64px;
    min-width: 64px;
    background-image: url(${props => props.foto});
    background-repeat: no-repeat;
    background-position: center;
`;

const Info = styled('div')`
    display: flex;
    flex-direction: column;
    color: #323232;
    padding-left: 15px;
    a {
        color: #00aae4;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
    }
`;
const Categoria = styled('div')`
    font-size: 12px;
    font-weight: normal;
    color: #7f7f7f;
    text-transform: uppercase;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
`;

export default function Noticia({ data }) {
    const { imagen, titulo, categoria, url } = data;

    return (
        <Container>
            <Foto foto={imagen}></Foto>
            <Info>
                {categoria !== '' && <Categoria>{categoria}</Categoria>}
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {titulo}
                </a>
            </Info>
        </Container>
    );
}
