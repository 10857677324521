import React, { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

import clienteAxios from 'config/axios';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import MateriaItem from 'components/carrera/MateriaItem';
import { useParams } from 'react-router';

import ImgExpand from '../img/expand_more.svg';
import Titulo from 'components/TituloH2';

import { Grilla } from 'components/styles';

const Anio = styled('h2')`
    font-size: 25px;
    font-weight: normal;
    color: #323232;
    text-transform: uppercase;
    padding: 0 15px;
    background-color: #ececec;
    margin-top: 0;
    height: 60px;
    line-height: 60px;
    position: relative;
    cursor: pointer;

    ::before {
        content: '';
        position: absolute;
        right: 15px;
        top: 20px;
        width: 24px;
        height: 24px;
        background-image: url(${ImgExpand});
        background-repeat: no-repeat;
        background-position: center;
        background-position-x: 97%;
        transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
    }
`;

export default function Carrera() {
    const dispatch = useDispatch();

    const { id, rol } = useParams();

    const urlRol = rol === 'docente' ? 'docente' : 'alumno';

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);

    const [anios, setAnios] = useState([]);
    let OA = localStorage.getItem('OpenAnios' + id) ? JSON.parse(localStorage.getItem('OpenAnios' + id)) : [true, true, true, true];
    const [openAnios, setOpenAnios] = useState(OA);

    useTitle('mi IES - Mis materias');

    useEffect(() => {
        if (Object.keys(usuario).length > 0) {
            const setCarrera = data => {
                let materias = data[urlRol].carreras[0].materias;
                let cursando = materias.filter(m => m.cursando === 1);
                if (cursando && cursando.length > 0 && !localStorage.getItem('OpenAnios' + id)) {
                    cursando = cursando[0].anio;
                    let OA = [false, false, false, false];
                    OA[cursando - 1] = true;
                    setOpenAnios(OA);
                }
                let a0s0 = materias.filter(m => m.anio === 0);
                let a1s1 = materias.filter(m => m.anio === 1).filter(m => m.cuatri === 1);
                let a1s2 = materias.filter(m => m.anio === 1).filter(m => m.cuatri === 2);
                let a2s1 = materias.filter(m => m.anio === 2).filter(m => m.cuatri === 1);
                let a2s2 = materias.filter(m => m.anio === 2).filter(m => m.cuatri === 2);
                let a3s1 = materias.filter(m => m.anio === 3).filter(m => m.cuatri === 1);
                let a3s2 = materias.filter(m => m.anio === 3).filter(m => m.cuatri === 2);
                let a4s1 = materias.filter(m => m.anio === 4).filter(m => m.cuatri === 1);
                let a4s2 = materias.filter(m => m.anio === 4).filter(m => m.cuatri === 2);

                setAnios([a0s0, a1s1, a1s2, a2s1, a2s2, a3s1, a3s2, a4s1, a4s2]);

                if (id !== 0) dispatch(setTitulo(`${data[urlRol].carreras[0].carrera} ${rol === 'docente' ? '(docente)' : ''}`));
            };
            const fetchTids = async () => {
                dispatch(setLoading(true));

                let result;

                let time = new Date();
                let lastQuery = parseInt(time.getTime() / 1000);

                let idCarr = id !== undefined ? id : 0;
                let antlastQuery = localStorage.getItem('lastQuery' + idCarr) || 0;
                antlastQuery = parseInt(antlastQuery);

                console.log(lastQuery - antlastQuery);
                if (antlastQuery > 0 && lastQuery - antlastQuery > 180) {
                    console.log('consultas');
                } else {
                    let dataCarr = localStorage.getItem('carrera' + idCarr) || false;
                    if (dataCarr) {
                        dataCarr = JSON.parse(dataCarr);
                        setCarrera(dataCarr);
                        dispatch(setLoading(false));
                        return;
                    }
                }

                try {
                    result = await clienteAxios.get(`/get_tids`, { params: { id_carrera: idCarr, rol } });

                    if (result.data.r === 0) {
                        dispatch(mostrarAlerta(result.data.text, 'Error'));
                        return false;
                    }
                    localStorage.setItem('lastQuery' + idCarr, lastQuery);
                    localStorage.setItem('carrera' + idCarr, JSON.stringify(result.data));
                    setCarrera(result.data);
                } catch (error) {
                    dispatch(mostrarAlerta(error, 'Error'));
                } finally {
                    dispatch(setLoading(false));
                }
            };
            fetchTids();
        }
        // eslint-disable-next-line
    }, [usuario]);

    useEffect(() => {
        dispatch(setTitulo('Carrera'));
    }, [dispatch]);

    if (!Object.keys(usuario).length > 0) return null;

    const handleClickAnio = OpenAnios => {
        setOpenAnios(OpenAnios);
        localStorage.setItem('OpenAnios' + id, JSON.stringify(OpenAnios));
    };

    return (
        <>
            {anios.length > 0 && (
                <>
                    {anios[0].map((anio, key) => {
                        return <MateriaItem data={anio} key={key} rol={rol} />;
                    })}

                    {anios[1] && (
                        <>
                            {anios[1].length > 0 && (
                                <Anio
                                    open={openAnios[0]}
                                    onClick={() => {
                                        let newOpenAnios = [...openAnios];
                                        newOpenAnios[0] = !openAnios[0];
                                        handleClickAnio(newOpenAnios);
                                    }}
                                >
                                    1 AÑO
                                </Anio>
                            )}
                            {openAnios[0] && (
                                <>
                                    {anios[1].length > 0 && <Titulo>Primer Semestre</Titulo>}
                                    <Grilla>
                                        {anios[1].map((a1s1, key) => {
                                            return <MateriaItem data={a1s1} key={key} />;
                                        })}
                                    </Grilla>
                                </>
                            )}
                        </>
                    )}

                    {openAnios[0] && anios[2] && (
                        <>
                            {anios[2].length > 0 && <Titulo>Segundo Semestre</Titulo>}
                            <Grilla>
                                {anios[2].map((a1s1, key) => {
                                    return <MateriaItem data={a1s1} key={key} />;
                                })}
                            </Grilla>
                        </>
                    )}

                    {anios[3] && (
                        <>
                            {anios[3].length > 0 && (
                                <Anio
                                    open={openAnios[1]}
                                    onClick={() => {
                                        let newOpenAnios = [...openAnios];
                                        newOpenAnios[1] = !openAnios[1];
                                        setOpenAnios(newOpenAnios);
                                    }}
                                >
                                    2 AÑO
                                </Anio>
                            )}
                            {openAnios[1] && (
                                <>
                                    {anios[3].length > 0 && <Titulo>Primer Semestre</Titulo>}
                                    <Grilla>
                                        {anios[3].map((a1s1, key) => {
                                            return <MateriaItem data={a1s1} key={key} />;
                                        })}
                                    </Grilla>
                                </>
                            )}
                        </>
                    )}

                    {openAnios[1] && anios[4] && (
                        <>
                            {anios[4].length > 0 && <Titulo>Segundo Semestre</Titulo>}
                            <Grilla>
                                {anios[4].map((a1s1, key) => {
                                    return <MateriaItem data={a1s1} key={key} />;
                                })}
                            </Grilla>
                        </>
                    )}

                    {anios[5] && (
                        <>
                            {anios[5].length > 0 && (
                                <Anio
                                    open={openAnios[2]}
                                    onClick={() => {
                                        let newOpenAnios = [...openAnios];
                                        newOpenAnios[2] = !openAnios[2];
                                        setOpenAnios(newOpenAnios);
                                    }}
                                >
                                    3 AÑO
                                </Anio>
                            )}
                            {openAnios[2] && (
                                <>
                                    {anios[5].length > 0 && <Titulo>Primer Semestre</Titulo>}
                                    <Grilla>
                                        {anios[5].map((a1s1, key) => {
                                            return <MateriaItem data={a1s1} key={key} />;
                                        })}
                                    </Grilla>
                                </>
                            )}
                        </>
                    )}

                    {openAnios[2] && anios[6] && (
                        <>
                            {anios[6].length > 0 && <Titulo>Segundo Semestre</Titulo>}
                            <Grilla>
                                {anios[6].map((a1s1, key) => {
                                    return <MateriaItem data={a1s1} key={key} />;
                                })}
                            </Grilla>
                        </>
                    )}

                    {anios[7] && (
                        <>
                            {anios[7].length > 0 && (
                                <Anio
                                    open={openAnios[3]}
                                    onClick={() => {
                                        let newOpenAnios = [...openAnios];
                                        newOpenAnios[3] = !openAnios[3];
                                        setOpenAnios(newOpenAnios);
                                    }}
                                >
                                    4 AÑO
                                </Anio>
                            )}
                            {openAnios[3] && (
                                <>
                                    {anios[7].length > 0 && <Titulo>Primer Semestre</Titulo>}
                                    <Grilla>
                                        {anios[7].map((a1s1, key) => {
                                            return <MateriaItem data={a1s1} key={key} />;
                                        })}
                                    </Grilla>
                                </>
                            )}
                        </>
                    )}

                    {openAnios[3] && anios[8] && (
                        <>
                            {anios[8].length > 0 && <Titulo>Segundo Semestre</Titulo>}
                            <Grilla>
                                {anios[8].map((a1s1, key) => {
                                    return <MateriaItem data={a1s1} key={key} />;
                                })}
                            </Grilla>
                        </>
                    )}
                </>
            )}
            {/* 

            {carrerasAlumno.length > 0 && (
                <>
                    {carrerasAlumno.map((carrera, key) => {
                        return (
                            <div key={key}>
                                <Titulo>{carrera.carrera}</Titulo>
                                <div>
                                    {carrera.materias.map((item, key1) => {
                                        return <MateriaItem data={item} key={key1} />;
                                    })}
                                </div>
                                <hr />
                            </div>
                        );
                    })}
                </>
            )} */}

            {loading && (
                <>
                    <Grilla>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={270} />
                        </div>
                    </Grilla>
                </>
            )}
        </>
    );
}
