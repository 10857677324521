import React from 'react';
import styled from '@emotion/styled';
import Titulo from 'components/TituloH2';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    margin-bottom: 40px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    padding: 15px;
    display: flex;
    justify-content: space-evenly;
`;

const Item = styled('div')`
    display: flex;
    align-items: center;
`;
const Nota = styled('div')`
    min-width: 27px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
`;

export default function NotasBox({ data }) {
    if (!data || data.length < 1) return null;
    return (
        <div>
            <Titulo>NOTAS</Titulo>
            <Container>
                {data.map((item, key) => {
                    return (
                        <Item key={key}>
                            <span>{item[0]}</span>
                            <Nota>{item[1] === '' ? '-' : item[1]}</Nota>
                        </Item>
                    );
                })}
            </Container>
        </div>
    );
}
