import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMenuTop } from 'redux/actions/aplication';
import styled from '@emotion/styled';
import Header from './Header/Header';
import Footer from '../components/Footer/Footer';

const Container = styled('div')`
    padding-top: ${props => props.paddingtop};
    padding-bottom: 75px;
    background-color: #fff;
`;

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export default function Layout(props) {
    const dispatch = useDispatch();
    const isMenuTop = useSelector(state => state.aplication.isMenuTop);
    const widthLimit = 800;
    //let [isMenuTop, setIsMenuTop] = useState(getWidth() > widthLimit);

    useEffect(() => {
        dispatch(setIsMenuTop(getWidth() > widthLimit));
        const resizeListener = () => {
            dispatch(setIsMenuTop(getWidth() > widthLimit));
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, [dispatch]);

    return (
        <Container paddingtop={isMenuTop ? '72px' : '52px'}>
            <Header />
            {props.children}
            <Footer />
        </Container>
    );
}
