import React, { useState, useEffect } from 'react';
import { useTitle } from 'react-use';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { mostrarAlerta } from 'redux/actions/alertaActions';
import { Grilla } from 'components/styles';
import VerifyDialog from 'components/VerifyDialog';

import Avatar from 'components/avatar';
import LayoutBox from 'components/LayoutBox';
import InfoBox from './InfoBox';

import clienteAxios from 'config/axios';

const Container = styled('div')`
    background: #ececec;
    hr {
        width: 100%;
        height: 1px;
        background: #707070;
    }
`;
const UserContainer = styled('div')`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: #fff;
    padding: 30px 0;
    h3 {
        margin: 0;
        font-size: 18px;
    }
    div:nth-of-type(1) {
        margin-bottom: 5px;
    }
`;

export default function Datospersonales() {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    //const loading = useSelector(state => state.aplication.loading);
    const [usuarioExtraInfo, setUsuarioExtraInfo] = useState({});
    const [localidades, setlocalidades] = useState([]);

    const [openVerify, setopenVerify] = useState(false);
    const [verifyData, setverifyData] = useState();

    const onCloseEmailVerify = email => {
        if (email) setUsuarioExtraInfo({ ...usuarioExtraInfo, email });
        setopenVerify(false);
        setverifyData(null);
    };

    useTitle('mi IES - Perfil');

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));

            try {
                let result = await clienteAxios.get(`/perfil`);
                if (result.data.r === 0) {
                    dispatch(mostrarAlerta(result.data.text, 'Error'));
                    return false;
                }
                setUsuarioExtraInfo(result.data.usuarioExtraInfo);
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (Object.keys(usuario).length > 0) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [usuario]);

    useEffect(() => {
        dispatch(setTitulo('Datos personales'));
    }, [dispatch]);

    if (!Object.keys(usuario).length > 0) return null;
    if (!usuarioExtraInfo || Object.keys(usuarioExtraInfo).length < 1) return null;

    const handleSaveItem = async (name, value) => {
        console.log(name, value);
        dispatch(setLoading(true));
        let result = false;
        try {
            let data = {
                input: name,
                value,
            };
            result = await clienteAxios.patch(`/perfil`, data);
            /*
            let newusuarioExtraInfo = { ...usuarioExtraInfo };
            newusuarioExtraInfo[name] = value;
            setUsuarioExtraInfo({ ...newusuarioExtraInfo });
            */
            if (result.data.verify_code) {
                setverifyData({ verify_text: result.data.verify_text, verify_time: result.data.verify_time });
                setopenVerify(true);
            } else {
                setUsuarioExtraInfo({ ...usuarioExtraInfo, [name]: result.data.value });
            }
        } catch (error) {
            dispatch(mostrarAlerta(error, 'Error'));
        } finally {
            dispatch(setLoading(false));
            return result;
        }
    };

    const handleChange = async e => {
        console.log(e);
        if (e.target.name === 'cp') {
            if (e.target.value.length === 4) {
                let newusuarioExtraInfo = { ...usuarioExtraInfo };
                try {
                    let data = {
                        cp: e.target.value,
                    };
                    let result = await clienteAxios.get(`/get_provincia`, { params: data });
                    if (result.data.r === 1) {
                        console.log(result.data.provincia.nombre);

                        newusuarioExtraInfo.provincia = result.data.provincia.nombre;

                        if (result.data.localidades.length === 1) {
                            newusuarioExtraInfo.localidad = result.data.localidades[0].nombre;
                        } else {
                            newusuarioExtraInfo.localidad = '';
                        }
                        setlocalidades(result.data.localidades);
                        setUsuarioExtraInfo({ ...newusuarioExtraInfo });
                    } else {
                        newusuarioExtraInfo.provincia = '';
                        newusuarioExtraInfo.localidad = '';
                        setlocalidades([]);
                        setUsuarioExtraInfo({ ...newusuarioExtraInfo });
                    }
                } catch (error) {
                    dispatch(mostrarAlerta(error, 'Error'));
                } finally {
                    dispatch(setLoading(false));
                }
            }
        }
    };

    return (
        <Container>
            {openVerify && <VerifyDialog type="email" open={openVerify} onClose={onCloseEmailVerify} data={verifyData} />}
            <UserContainer>
                <div>
                    <Avatar usuario={{ ...usuario, foto: usuarioExtraInfo.foto }} radius={94} />
                </div>
                <div>
                    <h3>
                        {usuario.nombres} {usuario.apellidos}
                    </h3>
                </div>
            </UserContainer>

            <Grilla>
                <LayoutBox title="Acceso">
                    <InfoBox label="DNI" disbaled value={usuario.dni} name="dni" />
                    <InfoBox label="Email" value={usuario.email} name="email" type="email" saveItem={handleSaveItem} />
                    <InfoBox label="Contraseña" value={usuario.password || '*********'} name="password" type="password" saveItem={handleSaveItem} />
                </LayoutBox>
                <LayoutBox title="Teléfonos">
                    <InfoBox
                        label="Celular"
                        name="tel1"
                        value={usuarioExtraInfo.tel1}
                        helpTx="Código de área + Nº. Ejemplo: 011 2345-6789"
                        saveItem={handleSaveItem}
                    />
                    <InfoBox
                        label="Fijo"
                        name="tel2"
                        value={usuarioExtraInfo.tel2}
                        helpTx="Código de área + Nº. Ejemplo: 011 2345-6789"
                        saveItem={handleSaveItem}
                    />
                </LayoutBox>
                <LayoutBox title="Domicilio postal">
                    <InfoBox
                        disbaled={true}
                        label="Código postal"
                        name="cp"
                        onChange={handleChange}
                        value={usuarioExtraInfo.cp}
                        saveItem={handleSaveItem}
                        helpTx={
                            <a href="https://codigo-postal.co/" target="_blank" rel="noopener noreferrer">
                                No sé mi código postal
                            </a>
                        }
                    />
                    <InfoBox label="Provincia" name="provincia" disbaled value={usuarioExtraInfo.provincia} saveItem={handleSaveItem} />
                    <InfoBox
                        disbaled={true}
                        label="Localidad / Barrio"
                        name="localidad"
                        type="select"
                        onChange={e => {
                            setUsuarioExtraInfo({ ...usuarioExtraInfo, localidad: e.target.value });
                        }}
                        options={localidades}
                        value={usuarioExtraInfo.localidad}
                        saveItem={handleSaveItem}
                    />
                    <InfoBox disbaled={true} label="Calle" name="calle" value={usuarioExtraInfo.calle} saveItem={handleSaveItem} />
                    <InfoBox disbaled={true} label="Número" name="numero" value={usuarioExtraInfo.numero} saveItem={handleSaveItem} />
                    <InfoBox disbaled={true} label="Piso / Departamento (opcional)" name="piso" value={usuarioExtraInfo.piso} saveItem={handleSaveItem} />
                </LayoutBox>
            </Grilla>
        </Container>
    );
}
