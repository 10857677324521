import React, { useState } from 'react';
import styled from '@emotion/styled';
import Titulo from 'components/TituloH2';
import Editor from 'components/editor/editor';
import SubLoading from 'components/loading';
import clienteAxios from 'config/axios';
import { useParams } from 'react-router';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    margin-bottom: 40px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    padding: 8px;
    cursor: ${props => (props.interactive ? 'pointer' : 'default')};
    :hover {
        background-color: ${props => (props.interactive ? '#ebebeb' : 'transparent')};
    }
    > div {
        padding: 7px;
    }
    > div img {
        margin-right: 7px;
    }
`;

export default function AnuncioBox({ data, changeData, rol = 'alumno' }) {
    const defaultText = data.anuncio ? data.anuncio.details : '';
    const [details, setDetails] = useState(defaultText);
    const [clicked, setClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id_materia, id_carrera } = useParams();

    if (!data.anuncio && rol === 'alumno') return null;

    const handleClick = () => {
        if (rol === 'alumno' || clicked) return;
        setClicked(true);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        let sendData = {
            details,
            id_materia,
            id_carrera,
        };
        try {
            setLoading(true);
            let result = await clienteAxios.post(`/anuncio`, sendData);
            changeData({ ...data, anuncio: result.data.anuncio });
            setClicked(false);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Titulo>ANUNCIO</Titulo>

            <Container onClick={handleClick} interactive={rol === 'docente' && !clicked}>
                <div>
                    {clicked ? (
                        <div className="pandercont">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <Editor
                                        autoFocus
                                        getHtml={setDetails}
                                        defaultHtml={defaultText}
                                        placeHolder={'Escriba un mensaje general para alumnos de la materia'}
                                    />
                                </div>
                                <div className="question-editor--buttons btn-toolbar">
                                    <button disabled={details.length < 3 || loading} type="submit" className="w100p btn btn-primary">
                                        {loading && <SubLoading />}Publicar
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <>
                            {data.anuncio ? (
                                <>
                                    <span dangerouslySetInnerHTML={{ __html: details }}></span>
                                    <div className="font-text-xs">
                                        actualizado {data.anuncio.date_update} por {data.anuncio.usuario.apellidos} {data.anuncio.usuario.nombres}
                                    </div>
                                </>
                            ) : (
                                <span>Escriba un mensaje general para alumnos de la materia.</span>
                            )}
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
}
