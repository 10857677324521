import React, {useState, useEffect} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useTitle } from 'react-use';
import { useSelector } from 'react-redux';

import {API_BASE} from '../components/constants';

import PajaroImg from 'img/pajaro.png';

export default function P404() {

    useTitle('Colegio Universitario IES');

    const usuario = useSelector( state => state.usuario.usuario );
    const token = useSelector((state) => state.usuario.token);
    
    let location = useLocation();

    const [enlace, setEnlace] = useState('https://alumnos.ies21.edu.ar/');

    useEffect(() => {
        if(Object.keys(usuario).length > 0){
            setEnlace(`${API_BASE}/autogestion?token=${token}`);
        }
        // eslint-disable-next-line
    }, [usuario]);

    return (
        <div className="seccion">
            <div className="contp">
                <div className="flex-column-row">
                    <div>
                        <br /><p className="thome">Oh!<br />
                        Página <code>{location.pathname}</code> no encontrada</p><br />
                        <p>Esta página ya no está disponible o cambió de dirección.<br />Revise el acceso si es correcto.</p><br /><br />
                    </div>
                    <div>
                        <img src={PajaroImg} alt="" />
                    </div>
                </div>
            </div>
            <div className="contp">
                <Link className="btn" to={'/'} >Home</Link>
                <a className="btn" href={enlace} target="_blank" rel="noopener noreferrer">Autogestión</a>
            </div>
        </div>
    )
}