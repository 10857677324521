import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Page from './pages/tid/page';
import Editorial from './pages/tid/editorial';
import Home from './pages/home';
import Consulta from './pages/tid/consulta';
import Errores from './pages/tid/errores';
import Login from './pages/login';
import P404 from './pages/P404';
import PERROR from './pages/PERROR';
import Alerta from './components/alerta';

import { useSelector, useDispatch } from 'react-redux';
import { iniciarSesion } from 'redux/actions/usuarioActions';
import { mostrarAlerta } from 'redux/actions/alertaActions';

import { initPush } from 'redux/actions/notificacionesActions';

import { localVar } from './components/constants';
import NetworkControl from './components/NetworkControl';
import Snackbar from './components/Snackbar';
import MenuHome from './components/tid/menu/MenuHome';
import Layout from './components/Layout';
import Carrera from 'pages/carrera';
import CarrerasList from 'pages/carreras';
import Materia from 'pages/materia';
import Calendario from 'pages/calendario';
import Tramites from 'pages/tramites';
import Perfil from 'pages/perfil';
import Datospersonales from 'pages/perfil/datospersonales';
import Credencial from 'pages/perfil/credencial';
import Estadodecuenta from 'pages/perfil/estadodecuenta';
import Analitico from 'pages/perfil/analitico';
import Mensajes from 'pages/mensajes';
import Mensaje from 'pages/mensaje';
import Ajustes from 'pages/perfil/ajustes';

export default function App() {
    const usuario = useSelector(state => state.usuario.usuario);
    const token = useSelector(state => state.usuario.token);
    const loading = useSelector(state => state.usuario.loading);
    const errorUsuario = useSelector(state => state.usuario.error);

    const dispatch = useDispatch();

    useEffect(() => {
        if (errorUsuario) {
            dispatch(mostrarAlerta(errorUsuario));
        }
    }, [errorUsuario, dispatch]);

    useEffect(() => {
        if (usuario.dni) {
            console.log('initPush', usuario.dni);
            dispatch(initPush(usuario.dni));
        }
    }, [usuario, dispatch]);

    useEffect(() => {
        if (!token && !loading && !localVar.getItem('token')) {
            iesAuth.signout();
            // window.location.replace('/');
        }
    }, [token, loading]);

    useEffect(() => {
        if (localVar.getItem('token')) {
            dispatch(iniciarSesion({}));
        }
    }, [dispatch]);

    return (
        <Router>
            <>
                <Alerta />
                <NetworkControl />
                <Snackbar />

                <React.StrictMode>
                    <Switch>
                        <Route path="/tid/:TID?/:path?/:path?" exact>
                            {Object.keys(usuario).length > 0 && <MenuHome />}
                            <Switch>
                                <PrivateRoute exact path="/tid/:TID/errores">
                                    <Errores />
                                </PrivateRoute>
                                <PrivateRoute exact path="/tid/:TID/consulta">
                                    <Consulta />
                                </PrivateRoute>
                                <PrivateRoute exact path="/tid/:TID/editorial">
                                    <Editorial />
                                </PrivateRoute>
                                <PrivateRoute exact path="/tid/:TID/page/:idn">
                                    <Page />
                                </PrivateRoute>
                                <PrivateRoute exact path="/tid/:TID">
                                    <Page />
                                </PrivateRoute>
                            </Switch>
                        </Route>

                        <Route path="/app/:path?/:path?/:path?" exact>
                            <Switch>
                                <Layout>
                                    <PrivateRoute exact path="/app/perfil/datospersonales">
                                        <Datospersonales />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/app/perfil/ajustes">
                                        <Ajustes />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/app/perfil/analitico/:id_carrera">
                                        <Analitico />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/app/perfil/estadodecuenta">
                                        <Estadodecuenta />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/app/perfil/credencial">
                                        <Credencial />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/app/perfil">
                                        <Perfil />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/app/materiadocente/:id_carrera/:id_materia">
                                        <Materia rol="docente" />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/app/materia/:id_carrera/:id_materia">
                                        <Materia />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/app/carreras">
                                        <CarrerasList />
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/app/carrera/:id?/:rol?">
                                        <Carrera />
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/app/calendario">
                                        <Calendario />
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/app/tramites">
                                        <Tramites />
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/app/mensaje/:id">
                                        <Mensaje />
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/app/mensajes">
                                        <Mensajes />
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/app/">
                                        <Home />
                                    </PrivateRoute>
                                </Layout>
                            </Switch>
                        </Route>

                        <Route
                            path="/app/"
                            render={routerProps => {
                                return iesAuth ? <PrivateRoute {...routerProps} /> : <Redirect to="/login/" />;
                            }}
                        />

                        <Route exact path="/perror/:origen">
                            <PERROR />
                        </Route>
                        <Route exact path="/login">
                            <Login iesAuth={iesAuth} />
                        </Route>

                        <Route path="/">
                            <Redirect to="/app/" />
                        </Route>

                        <Route path="*">
                            <P404 iesAuth={iesAuth} />
                        </Route>
                    </Switch>
                </React.StrictMode>
            </>
        </Router>
    );
}

const iesAuth = {
    isAuthenticated: localVar.getItem('token') ? true : false,
    authenticate(cb) {
        iesAuth.isAuthenticated = true;
        setTimeout(cb, 100);
    },
    signout(cb) {
        iesAuth.isAuthenticated = false;
        setTimeout(cb, 100);
    },
};

function PrivateRoute({ children, ...props }) {
    return (
        <Route
            {...props}
            render={({ location }) =>
                iesAuth.isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
