import React from 'react';
import styled from '@emotion/styled';
import IconFechas from '../../img/fecha.svg';
import Titulo from 'components/TituloH2';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    margin-bottom: 40px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    padding: 8px;
    div {
        padding: 7px;
    }
    div img {
        margin-right: 7px;
    }
`;

export default function DictadoBox({ data }) {
    /*data = [
        { dia: 'Miércoles', horario: '11:50 a 13:20', aula: 'Aula 4.5', url: '' },
        { dia: 'Jueves', horario: '11:50 a 13:20', aula: 'Aula 4.5', url: '' },
    ];*/
    if (!data || data.length < 1) return null;
    return (
        <div>
            <Titulo>DICTADO DE LA MATERIA</Titulo>
            <Container>
                {data.map((item, key) => {
                    return (
                        <div key={key}>
                            <img src={IconFechas} alt="" />
                            <strong>{item.dia}</strong> / {item.horario} / {item.aula}
                        </div>
                    );
                })}
            </Container>
        </div>
    );
}
