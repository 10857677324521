import { 
    OBTENER_PREGUNTAS,
    PREGUNTA_ACTUAL,
    AGREGAR_PREGUNTA,
    PREGUNTA_ERROR,
    VALIDAR_FORMULARIO,
    RESET_FORM,
    REMOTE_LOADING,
    SEARCH_QUESTIONS,
    AGREGAR_RESPUESTA,
    REMOTE_LOADING_INLINE,
    ELIMINAR_PREGUNTA,
    ELIMINAR_RESPUESTA,
    RESET_MENSAJE,
    PREGUNTA_FOLLOW,
    RESPUESTA_UPVOTES
} from 'redux/types';

// eslint-disable-next-line
export default (state, action) => {
    let preguntaNew;
    let preguntasNew;

    switch(action.type) {
        case REMOTE_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case REMOTE_LOADING_INLINE:
            return {
                ...state,
                loadingInline: action.payload,
                success: false
            }
        case SEARCH_QUESTIONS:
            return {
                ...state,
                preguntasfilter: state.preguntas.filter(pregunta => pregunta.pregunta.txt.toLowerCase().includes(action.payload))
            }
        case PREGUNTA_FOLLOW:
            preguntaNew = {...state.pregunta};
            preguntaNew.isFollow = action.payload;
            return{
                ...state,
                pregunta: preguntaNew
            }
        case RESPUESTA_UPVOTES:
            preguntaNew = {...state.pregunta};
            for (let i = 0; i < preguntaNew.respuestas.length; i++ ){
                if(preguntaNew.respuestas[i].respuesta.id === action.payload.id){
                    preguntaNew.respuestas[i].respuesta.isUpvotes = action.payload.isUpvotes;
                    preguntaNew.respuestas[i].respuesta.upvotes = action.payload.upvotes;
                }
            }
            return{
                ...state,
                pregunta: preguntaNew
            }
        case AGREGAR_RESPUESTA:
            preguntaNew = {...state.pregunta};
            preguntaNew.respuestas = [...state.pregunta.respuestas,  action.payload];
            preguntasNew = [...state.preguntas];

            for (let i = 0; i < preguntasNew.length; i++ ){
                if(preguntasNew[i].pregunta.id === preguntaNew.pregunta.id){
                    preguntasNew[i].pregunta.answers = preguntaNew.respuestas.length;
                }
            }
            return {
                ...state,
                pregunta: preguntaNew,
                preguntas: preguntasNew,
                preguntasfilter: preguntasNew,
                success: true,
                loadingInline: false,
                errorformulario: false
            }
        case ELIMINAR_RESPUESTA:            
            preguntaNew = {...state.pregunta};
            preguntaNew.respuestas = state.pregunta.respuestas.filter(respuesta => respuesta.respuesta.id !== action.payload );
            preguntasNew = [...state.preguntas];
            
            for (let i = 0; i < preguntasNew.length; i++ ){
                if(preguntasNew[i].pregunta.id === preguntaNew.pregunta.id){
                    preguntasNew[i].pregunta.answers = preguntaNew.respuestas.length;
                }
            }
            return {
                ...state,
                pregunta: preguntaNew,
                preguntas: preguntasNew,
                preguntasfilter: preguntasNew,
                success: true,
                loadingInline: false,
                errorformulario: false
            }
        case ELIMINAR_PREGUNTA:
            preguntasNew = [...state.preguntas.filter(pregunta => pregunta.pregunta.id !== action.payload )];
            return {
                ...state,
                preguntas: preguntasNew,
                pregunta: null,
                preguntasfilter: preguntasNew,
                total: preguntasNew.length,
                loading: false
            }
        case OBTENER_PREGUNTAS:
            return {
                ...state,
                loading: false,
                preguntas: action.payload,
                preguntasfilter: action.payload,
                total: action.payload.length
            }
        case AGREGAR_PREGUNTA:
             return {
                ...state,
                preguntas: [...state.preguntas, action.payload],
                preguntasfilter: [...state.preguntas, action.payload],
                total: state.preguntas.length + 1,
                success: true,
                loading: false,
                errorformulario: false
            }
        case VALIDAR_FORMULARIO:
            return {
                ...state, 
                errorformulario: true
            }
        
        case PREGUNTA_ACTUAL:
            return {
                ...state,
                pregunta: action.payload,
                loading: false
            }
        case PREGUNTA_ERROR:
            return {
                ...state,
                mensaje: action.payload,
                loading: false,
                loadingInline: false
            }
        case RESET_FORM:
            return {
                ...state,
                success : false,
                loading: false,
                loadingInline: false,
                errorformulario: false,
                mensaje: null
            }
        case RESET_MENSAJE:
            return {
                ...state,
                mensaje: null
            }
        default:
            return state;
    }
}