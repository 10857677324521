import React from 'react';
import styled from '@emotion/styled';

const Titulo = styled('h2')`
    font-size: 16px;
    font-weight: normal;
    color: #7f7f7f;
    text-transform: uppercase;
    padding: 0 15px;
`;

export default function TituloH2({ children }) {
    return <Titulo>{children}</Titulo>;
}
