import React, { useReducer } from 'react';

import preguntasContext from './preguntasContext';
import preguntasReducer from './preguntasReducer';
import {
    AGREGAR_PREGUNTA,
    PREGUNTA_ERROR,
    OBTENER_PREGUNTAS,
    VALIDAR_FORMULARIO,
    PREGUNTA_ACTUAL,
    RESET_FORM,
    REMOTE_LOADING,
    SEARCH_QUESTIONS,
    AGREGAR_RESPUESTA,
    REMOTE_LOADING_INLINE,
    ELIMINAR_PREGUNTA,
    ELIMINAR_RESPUESTA,
    RESET_MENSAJE,
    PREGUNTA_FOLLOW,
    RESPUESTA_UPVOTES,
} from 'redux/types';

import clienteAxios from 'config/axios';

const PreguntasState = props => {
    const initialState = {
        preguntas: [],
        total: 0,
        success: false,
        loading: true,
        loadingInline: false,
        errorformulario: false,
        pregunta: null,
        mensaje: null,
    };

    // Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(preguntasReducer, initialState);

    // Obtener preguntas
    const obtenerPreguntas = async data => {
        dispatch({
            type: PREGUNTA_ERROR,
            payload: null,
        });

        dispatch({
            type: REMOTE_LOADING,
            payload: true,
        });

        try {
            const result = await clienteAxios.get('/preguntas', { params: data });

            if (result.data.r === 0) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: result.data.text,
                });
                return;
            }
            if (result.data === undefined) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: 'No se pudo obtener datos. Disculpe las molestias',
                });
                return;
            }

            dispatch({
                type: OBTENER_PREGUNTAS,
                payload: result.data.preguntas,
            });
        } catch (error) {
            let errorstr = typeof error === 'object' ? error.toString() : error;
            /*if(error.response.status === 401){

            }*/

            dispatch({
                type: PREGUNTA_ERROR,
                payload: errorstr,
            });
        }
    };

    // Agregar nueva pregunta
    const agregarPregunta = async pregunta => {
        dispatch({
            type: PREGUNTA_ERROR,
            payload: null,
        });

        dispatch({
            type: REMOTE_LOADING,
            payload: true,
        });

        try {
            const result = await clienteAxios.post('/preguntas-add', pregunta);

            if (result.data.r === 0) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: result.data.text,
                });
                return;
            }
            if (result.data === undefined) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: 'No se pudo guardar la consulta, disculpe las molestias.',
                });
                return;
            }

            // Insertar el pregunta en el state
            dispatch({
                type: AGREGAR_PREGUNTA,
                payload: result.data.pregunta,
            });
        } catch (error) {
            let errorstr = typeof error === 'object' ? error.toString() : error;
            /*if(error.response.status === 401){

            }*/

            dispatch({
                type: PREGUNTA_ERROR,
                payload: errorstr,
            });
        }
    };

    const follow = async params => {
        const { onSuccess } = params;

        try {
            const result = await clienteAxios.post('/pregunta-follow', params);

            if (result.data.r === 0 || result.data === undefined) {
                console.log(result);
                return;
            }

            dispatch({
                type: PREGUNTA_FOLLOW,
                payload: result.data.isFollow,
            });

            onSuccess();
        } catch (error) {
            let errorstr = typeof error === 'object' ? error.toString() : error;
            /*if(error.response.status === 401){

            }*/
            dispatch({
                type: PREGUNTA_ERROR,
                payload: errorstr,
            });
        }
    };

    const upvotesHandle = async params => {
        const { onSuccess } = params;

        try {
            const result = await clienteAxios.post('/pregunta-upvotes', params);

            if (result.data.r === 0 || result.data === undefined) {
                console.log(result);
                return;
            }

            dispatch({
                type: RESPUESTA_UPVOTES,
                payload: result.data,
            });

            onSuccess();
        } catch (error) {
            let errorstr = typeof error === 'object' ? error.toString() : error;
            /*if(error.response.status === 401){

            }*/
            dispatch({
                type: PREGUNTA_ERROR,
                payload: errorstr,
            });
        }
    };

    const eliminarPregunta = async params => {
        const { id, tipo, onSuccess } = params;

        dispatch({
            type: PREGUNTA_ERROR,
            payload: null,
        });

        if (tipo === 'pregunta') {
            dispatch({
                type: REMOTE_LOADING,
                payload: true,
            });
        } else {
            dispatch({
                type: REMOTE_LOADING_INLINE,
                payload: true,
            });
        }

        try {
            const result = await clienteAxios.delete(`/pregunta-eliminar`, params);
            console.log(result);

            if (result.data.r === 0) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: result.data.text,
                });
                return;
            }
            if (result.data === undefined) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: 'No se pudo eliminar la ' + tipo + ', disculpe las molestias.',
                });
                return;
            }

            // Eliminar pregunta/respuesta del state
            if (tipo === 'pregunta') {
                dispatch({
                    type: ELIMINAR_PREGUNTA,
                    payload: id,
                });
            } else {
                dispatch({
                    type: ELIMINAR_RESPUESTA,
                    payload: id,
                });
            }

            onSuccess(tipo);
        } catch (error) {
            let errorstr = typeof error === 'object' ? error.toString() : error;
            /*if(error.response.status === 401){

            }*/
            dispatch({
                type: PREGUNTA_ERROR,
                payload: errorstr,
            });
        }
    };
    
    // Agregar respuesta
    const agregarRespuesta = async respuesta => {
        dispatch({
            type: PREGUNTA_ERROR,
            payload: null,
        });

        dispatch({
            type: REMOTE_LOADING_INLINE,
            payload: true,
        });

        try {
            const result = await clienteAxios.post('/pregunta-responder', respuesta);
            console.log(result.data);

            if (result.data.r === 0) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: result.data.text,
                });
                return;
            }
            if (result.data === undefined) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: 'No se pudo guardar la consulta, disculpe las molestias.',
                });
                return;
            }

            // Insertar la respuesta en el state de la pregunta
            dispatch({
                type: AGREGAR_RESPUESTA,
                payload: result.data.respuesta,
            });
        } catch (error) {
            let errorstr = typeof error === 'object' ? error.toString() : error;
            /*if(error.response.status === 401){

            }*/

            dispatch({
                type: PREGUNTA_ERROR,
                payload: errorstr,
            });
        }
    };

    const searchQuestions = s => {
        dispatch({
            type: SEARCH_QUESTIONS,
            payload: s,
        });
    };

    const resetForm = () => {
        dispatch({
            type: RESET_FORM,
        });
    };

    const resetMensaje = () => {
        dispatch({
            type: RESET_MENSAJE,
        });
    };

    // Valida el formulario por errores
    const mostrarError = () => {
        dispatch({
            type: VALIDAR_FORMULARIO,
        });
    };

    const getPregunta = async data => {
        dispatch({
            type: PREGUNTA_ACTUAL,
            payload: null,
        });

        dispatch({
            type: PREGUNTA_ERROR,
            payload: null,
        });

        dispatch({
            type: REMOTE_LOADING,
            payload: true,
        });

        try {
            const result = await clienteAxios.get('/pregunta', {params:data});

            if (result.data.r === 0) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: result.data.text,
                });
                return;
            }
            if (result.data === undefined) {
                dispatch({
                    type: PREGUNTA_ERROR,
                    payload: 'No se pudo obtener datos, disculpe las molestias',
                });
                return;
            }

            // Insertar el pregunta en el state
            dispatch({
                type: PREGUNTA_ACTUAL,
                payload: result.data.pregunta,
            });
        } catch (error) {
            let errorstr = typeof error === 'object' ? error.toString() : error;
            /*if(error.response.status === 401){

            }*/

            dispatch({
                type: PREGUNTA_ERROR,
                payload: errorstr,
            });
        }
    };

    return (
        <preguntasContext.Provider
            value={{
                preguntas: state.preguntasfilter,
                total: state.total,
                success: state.success,
                errorformulario: state.errorformulario,
                pregunta: state.pregunta,
                mensaje: state.mensaje,
                loading: state.loading,
                loadingInline: state.loadingInline,
                resetMensaje,
                obtenerPreguntas,
                searchQuestions,
                agregarPregunta,
                eliminarPregunta,
                agregarRespuesta,
                mostrarError,
                getPregunta,
                resetForm,
                follow,
                upvotesHandle,
            }}
        >
            {props.children}
        </preguntasContext.Provider>
    );
};

export default PreguntasState;
