import {
    MOSTRAR_ALERTA,
    OCULTAR_ALERTA
} from '../types';

// Muestra alerta
export function mostrarAlerta(text, title, actionBtn1 = null, btn1 = 'Aceptar', btn2 = 'Cancelar', actionBtn1Params = null) {
    
    if(typeof(text) === 'object'){
        actionBtn1 = text.actionBtn1 ? text.actionBtn1 : null;
        btn1 = text.btn1 ? text.btn1 : null;
        btn2 = text.btn2 ? text.btn2 : null;
        actionBtn1Params = text.actionBtn1Params ? text.actionBtn1Params : null;
        title = text.title;
        text = text.text;
    }

    return (dispatch) => {
        dispatch({
            type: MOSTRAR_ALERTA,
            payload: {
                title, text, actionBtn1, btn1, btn2, actionBtn1Params
            }
        });
    }
}

// ocultar alerta
export function ocultarAlerta() {
    return (dispatch) => {
        dispatch({
            type: OCULTAR_ALERTA
        });
    }
}
