import React from 'react';
import { useParams, Link } from "react-router-dom";
import { useTitle } from 'react-use';
import PajaroImg from 'img/pajaro.png';

export default function PERROR() {

    useTitle('Colegio Universitario IES');

    const {origen} = useParams();

    const orgDecode = decodeURIComponent(origen);

    return (
        <div className="seccion">
            <div className="contp">
                <div className="flex-column-row">
                    <div>
                        <br /><p className="thome">Oh!<br />
                        Algo salió mal, revise su conexión.</p><br /><br />
                    </div>
                    <div>
                        <img src={PajaroImg} alt="" />
                    </div>
                </div>
            </div>
            <div className="contp">
                <Link className="btn" to={'/'} >Home</Link>
                <a className="btn" href={orgDecode}>Cargar nuevamente la página</a>
            </div>
        </div>
    )
}