import React from 'react';
import styled from '@emotion/styled';
import mensajesIcon from '../../img/mensajes.svg';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Badge from '@material-ui/core/Badge';

const Container = styled('div')`
    display: flex;
    flex-direction: row-reverse;
    margin-right: 5px;
    &.istopmenu {
        margin-left: 13px;
        border-left: 1px solid #ccc;
        padding-left: 13px;
    }

    button {
        width: ${props => props.buttonwidth};
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: normal;
        border-radius: 0;
        margin: 0 1%;
        background-image: url(${props => props.bgback});
        background-repeat: no-repeat;
        background-position: center;
        div {
            text-transform: uppercase;
            font-size: 8px;
            font-weight: normal;
        }
        &:enabled.active,
        :hover:enabled {
            height: 58px;
            color: #00aae4;
            border-top: 2px solid #00aae4;
        }
    }
    .MuiBadge-badge {
        top: 15px;
        right: 13px;
    }
`;

export default function MessageButton() {
    const history = useHistory();
    const mensajes = useSelector(state => state.homeData.mensajes);
    const loading1 = useSelector(state => state.usuario.loading);
    const loading2 = useSelector(state => state.aplication.loading);
    const loading3 = useSelector(state => state.homeData.loading);
    const isMenuTop = useSelector(state => state.aplication.isMenuTop);

    let loading = loading1 || loading2 || loading3;

    return (
        <Container className={isMenuTop ? 'istopmenu' : null} buttonwidth={isMenuTop ? '100%' : '50px'} bgback={mensajesIcon}>
            <Badge badgeContent={mensajes} color="error">
                <button disabled={loading} type="button" onClick={() => history.push('/app/mensajes')}>
                    <div className="Img"></div>
                    {isMenuTop && <div style={{ marginTop: '49px' }}>MENSAJES</div>}
                </button>
            </Badge>
        </Container>
    );
}
