import React, { useState, useEffect } from 'react';
import { useNetworkState } from 'react-use';
import { useSnackbar } from 'notistack';

export default function NetworkControl() {
    const { enqueueSnackbar } = useSnackbar();
    const [ubodesconexion, setUbodesconexion] = useState(false);

    const network = useNetworkState();
    useEffect(() => {
        if (Object.keys(network).length === 0) return;
        if (!network.online) {
            console.log(network);
            enqueueSnackbar('Oh! Se ha perdido la conexión! 😱', {
                variant: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            setUbodesconexion(true);
        } else if (ubodesconexion) {
            enqueueSnackbar('Se ha restablecido la conexión🙂', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });

            setUbodesconexion(false);
        }
    }, [network, enqueueSnackbar, ubodesconexion]);

    return <></>;
}
