import React, { useEffect, useRef, useReducer, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLocalStorage, useTitle } from 'react-use';
import clienteAxios from 'config/axios';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import PreguntasState from 'context/preguntas/preguntasState';

import { mostrarAlerta } from 'redux/actions/alertaActions';

import { useDispatch, useSelector } from 'react-redux';

import { setImages, winSize, IS_MOBILE, isDark } from '../../components/constants';

import useUtiles from 'hooks/useUtiles';

import Media from '../../components/tid/media';
import Scroll from '../../components/tid/scroll/scroll';
import Footer from '../../components/tid/footer/footer';
import Header from '../../components/tid/header/header';
import Utiles from '../../components/tid/utiles/utiles';
import PanelUtiles from '../../components/tid/paneles/panelUtiles';
import PanelPreguntas from '../../components/tid/paneles/panelPreguntas';

import { cargarTid, setAvance, guardaSesion } from 'redux/actions/tidActions';

const ACTIONS = {
    SET_MEDIA_DATA: 'SET_MEDIA_DATA',
    SET_LOADING: 'SET_LOADING',
    SET_TEXT_LOADING: 'SET_TEXT_LOADING',
    SET_PROPS_UTILES: 'SET_PROPS_UTILES',
    IS_LOADED_IMAGES: 'IS_LOADED_IMAGES',
    SHOW_UTILS: 'SHOW_UTILS',
    SHOW_PREGUNTAS: 'SHOW_PREGUNTAS',
    SET_PREGUNTAS: 'SET_PREGUNTAS',
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_MEDIA_DATA:
            return {
                ...state,
                mediaData: action.payload,
                openMedia: Object.keys(action.payload).length > 0 ? true : false,
            };
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
                loadingTxt: 'Cargando datos...',
            };
        case ACTIONS.SET_TEXT_LOADING:
            return {
                ...state,
                loadingTxt: action.payload,
            };
        case ACTIONS.SET_PROPS_UTILES:
            return {
                ...state,
                propsUtiles: action.payload,
            };
        case ACTIONS.IS_LOADED_IMAGES:
            return {
                ...state,
                imagesLoaded: action.payload,
            };
        case ACTIONS.SHOW_UTILS:
            return {
                ...state,
                openUtils: action.payload,
            };
        case ACTIONS.SHOW_PREGUNTAS:
            return {
                ...state,
                openPreguntas: action.payload,
            };
        case ACTIONS.SET_PREGUNTAS:
            return {
                ...state,
                preguntasSize: action.payload,
            };
        default:
            return state;
    }
};

export default function Page() {
    const dispatch = useDispatch();

    const esDocente = useSelector(state => state.usuario.usuario.esDocente);
    const dni = useSelector(state => state.usuario.usuario.dni);
    const rol = useSelector(state => state.usuario.usuario.rol);
    const dataTid = useSelector(state => state.dataTid.dataTid);
    const sesionTid = useSelector(state => state.dataTid.sesionTid);
    const errorTid = useSelector(state => state.dataTid.error);

    let history = useHistory();

    let { TID, idn } = useParams();

    idn = parseInt(idn);
    TID = parseInt(TID);

    useTitle(!dataTid ? 'Colegio Universitario IES' : 'IES - ' + dataTid.materia);

    const paginaRef = useRef(null);

    const [state, dispatchReducer] = useReducer(reducer, {
        openMedia: false,
        mediaData: {},
        loading: true,
        loadingTxt: 'Cargando datos...',
        propsUtiles: { visible: false },
        imagesLoaded: false,
        openUtils: false,
        openPreguntas: false,
        preguntasSize: 0,
    });

    const { openMedia, mediaData, loading, loadingTxt, propsUtiles, imagesLoaded, openUtils, openPreguntas, preguntasSize } = state;

    const {
        updateUtiles,
        resaltarSelectedText,
        unResaltarSelectedText,
        noteSelectedText,
        viewNote,
        panelresums,
        panelnots,
        eliminarNotas,
        eliminarResumenes,
        NoteNode,
    } = useUtiles({ paginaRef });

    const minWinHeight = winSize().height - 52;

    const getNodoData = idn => (dataTid ? dataTid.indice.filter(obj => obj.id === parseInt(idn))[0] : { id: null, t: null });

    const showUtils = open => {
        dispatchReducer({
            type: ACTIONS.SHOW_UTILS,
            payload: open,
        });
    };

    const toggleDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        showUtils(open);
    };

    const showPreguntas = open => {
        dispatchReducer({
            type: ACTIONS.SHOW_PREGUNTAS,
            payload: open,
        });
    };

    const setPreguntas = num => {
        dispatchReducer({
            type: ACTIONS.SET_PREGUNTAS,
            payload: num,
        });
    };

    const toggleDrawerPreguntas = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        showPreguntas(open);
    };

    const closeMedia = () => {
        dispatchReducer({
            type: ACTIONS.SET_MEDIA_DATA,
            payload: {},
        });
    };

    const onEndSelect = useCallback(
        e => {
            if (dataTid && !loading) {
                const sel = window.getSelection().toString().trim();
                if (sel !== '' && e.isTrusted) {
                    let left = e.pageX ? e.pageX : e.changedTouches[0].pageX;
                    let top = e.pageY ? e.pageY : e.changedTouches[0].pageY;
                    top = Math.round(top + 20) + 'px';
                    left = Math.round(Math.min(winSize().width - 200, left)) + 'px';
                    let position = 'absolute';
                    let right = 'inherit';

                    if (IS_MOBILE) {
                        top = '52px';
                        right = '28px';
                        left = 'inherit';
                        position = 'fixed';
                    }

                    let propsUt = {
                        position,
                        left,
                        right,
                        top,
                        visible: true,
                    };

                    dispatchReducer({
                        type: ACTIONS.SET_PROPS_UTILES,
                        payload: propsUt,
                    });
                } else {
                    if (propsUtiles.visible) closeUtiles();
                }
            }
        },
        [dataTid, loading, propsUtiles]
    );

    const clickPage = useCallback(
        e => {
            if (loading) return;

            let { id, tagName, className, naturalWidth, naturalHeight, src, alt, href, parentElement } = e.target;

            let width = naturalWidth;
            let height = naturalHeight;

            let dataWh = e.target.getAttribute('data-wh');
            if (dataWh) {
                let Wh = dataWh.split('x');
                width = parseInt(Wh[0]);
                height = parseInt(Wh[1]);
            }

            if (parentElement.tagName === 'A' && !id && parentElement.href.indexOf('media/') > -1) {
                id = parentElement.href.split('media/')[1];
            }
            if (tagName === 'A' && !id && href.indexOf('media/') > -1) {
                id = href.split('media/')[1];
            }

            if (tagName === 'P' || className === 'section' || id === 'sc') {
                return;
            }

            if (tagName === 'SPAN' && className === 'note' && id) {
                console.log(tagName, id, className);

                viewNote(e.target);

                return;
            }

            if (tagName === 'SPAN' && className === 's' && id) {
                return;
            }

            if (id) {
                if (!dataTid.medias[id]) {
                    return;
                }
                const URLTID = dataTid.url_tid;
                e.preventDefault();

                dispatchReducer({
                    type: ACTIONS.SET_MEDIA_DATA,
                    payload: { ...dataTid.medias[id], id, istxt: className === 'txa', URLTID },
                });
            } else {
                if (tagName === 'IMG') {
                    if (className === 'ic' && parentElement.tagName === 'A' && parentElement.className === 'im-icon') {
                        e.preventDefault();
                        dispatchReducer({
                            type: ACTIONS.SET_MEDIA_DATA,
                            payload: { src: parentElement.href, width, height, alt, m: 1 },
                        });
                    }
                    if (className === 'im' || className === 'im l' || className === 'im r' || className === 'im sy') {
                        e.preventDefault();
                        dispatchReducer({
                            type: ACTIONS.SET_MEDIA_DATA,
                            payload: { src, width, height, alt, m: 1 },
                        });
                    }
                } else if (tagName === 'A') {
                    if (className === 'im-icon') {
                        e.preventDefault();
                        dispatchReducer({
                            type: ACTIONS.SET_MEDIA_DATA,
                            payload: { src: href, width, height, alt, m: 1 },
                        });
                    }
                }
            }
        },
        [dataTid, loading, viewNote]
    );

    const onImagesLoaded = useCallback(totalImg => {
        const images_im = paginaRef.current.getElementsByClassName('loadingimg');

        dispatchReducer({
            type: ACTIONS.SET_TEXT_LOADING,
            payload: 'Cargando imagenes...',
        });

        const onloadImg = e => {
            // console.log(totalImg);

            e.target.className = e.target.className.replace(' loadingimg', '');
            e.target.removeAttribute('style');

            totalImg--;

            if (totalImg > 0) {
                dispatchReducer({
                    type: ACTIONS.SET_TEXT_LOADING,
                    payload: `Cargando imagenes ${totalImg}...`,
                });
            } else {
                dispatchReducer({
                    type: ACTIONS.IS_LOADED_IMAGES,
                    payload: true,
                });
            }
        };

        for (let index = 0; index < images_im.length; index++) {
            const element = images_im[index];

            element.onload = onloadImg;
        }
    }, []);

    useEffect(() => {
        if (imagesLoaded !== false) {
            dispatchReducer({
                type: ACTIONS.SET_LOADING,
                payload: false,
            });
            setImages(paginaRef.current);
            dispatch(setAvance());
            updateUtiles();
        }
        // eslint-disable-next-line
    }, [imagesLoaded]);

    useEffect(() => {
        if (errorTid) {
            dispatch(mostrarAlerta(errorTid));
            history.push(`/`);
        }
    }, [errorTid, dispatch, history]);

    useEffect(() => {
        if (dataTid && dataTid.TID === TID) {
            closeUtiles();

            if (!idn || idn === 0) {
                if (sesionTid.idn !== 0) {
                    history.push(`/tid/${TID}/page/${sesionTid.idn}`);
                } else {
                    history.push(`/tid/${TID}/page/${dataTid.indice[0].id}`);
                }
                return;
            }

            const fetchPage = async () => {
                dispatchReducer({
                    type: ACTIONS.SET_LOADING,
                    payload: true,
                });

                dispatchReducer({
                    type: ACTIONS.IS_LOADED_IMAGES,
                    payload: false,
                });

                const data = { TID, idn, dni, id_materia: dataTid.id_materia, id_carrera: dataTid.id_carrera, esDocente };

                try {
                    const result = await clienteAxios.get('/get_page', { params: data });

                    // console.log(result.data);

                    if (result.data.r === 0) {
                        dispatch(mostrarAlerta(result.data.text, 'Error en página'));
                        dispatchReducer({
                            type: ACTIONS.SET_LOADING,
                            payload: false,
                        });
                        return;
                    }

                    setPreguntas(result.data.preguntas);

                    //console.log(dataTid)

                    let totalImg =  (result.data.nodo.match(/loadingimg/g) || []).length;
                    console.log(totalImg, result.data.totalImg)
                    let htmlNode = result.data.nodo;
                    htmlNode = htmlNode.replace(/src="me\//g, `src="${dataTid.url_tid}me/`);
                    htmlNode = htmlNode.replace(/href="me\//g, `href="${dataTid.url_tid}me/`);
                    htmlNode = htmlNode.replace(/href="me\//g, `href="${dataTid.url_tid}me/`);
                    htmlNode += '<div class="finh" style="visibility: hidden;"></div>';

                    dispatch(guardaSesion(idn));

                    window.scrollTo(0, 0);

                    paginaRef.current.innerHTML = htmlNode;

                    if (totalImg > 0) {
                        onImagesLoaded(totalImg);
                    } else {
                        dispatchReducer({
                            type: ACTIONS.IS_LOADED_IMAGES,
                            payload: true,
                        });
                    }
                } catch (error) {
                    dispatch(mostrarAlerta(error, 'Error al obtener página'));
                    dispatchReducer({
                        type: ACTIONS.SET_LOADING,
                        payload: false,
                    });
                }
            };
            fetchPage();
        }
        // eslint-disable-next-line
    }, [idn, TID, dataTid]);

    useEffect(() => {
        if (TID) {
            //setTID(TID);
            dispatch(cargarTid(TID, rol));
            // eslint-disable-next-line
        }
    }, [TID, rol, dispatch]);

    useEffect(() => {
        if (dataTid) {
            dispatch(setAvance());
        }
    }, [dataTid, dispatch]);

    const closeUtiles = () => {
        dispatchReducer({
            type: ACTIONS.SET_PROPS_UTILES,
            payload: { visible: false },
        });
    };

    const [tidConfig] = useLocalStorage('tidConfig', { aumentarTxt: false });

    return (
        <div className={tidConfig.aumentarTxt ? 'aumentarTxt' : null}>
            {openMedia && <Media open={openMedia} closeMedia={closeMedia} mediaData={mediaData} />}

            {dataTid !== null && idn && (
                <>
                    <NoteNode title={getNodoData(idn).t} />

                    <Utiles
                        propsUtiles={propsUtiles}
                        closeUtiles={closeUtiles}
                        noteSelectedText={noteSelectedText}
                        resaltarSelectedText={resaltarSelectedText}
                        unResaltarSelectedText={unResaltarSelectedText}
                    />
                </>
            )}

            <PreguntasState>
                {dataTid !== null && idn && (
                    <>
                        <SwipeableDrawer anchor="right" disableSwipeToOpen={true} open={openUtils} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                            <PanelUtiles
                                setOpenPanel={showUtils}
                                title={getNodoData(idn).t}
                                panelnots={panelnots}
                                panelresums={panelresums}
                                eliminarNotas={eliminarNotas}
                                eliminarResumenes={eliminarResumenes}
                            />
                        </SwipeableDrawer>

                        <SwipeableDrawer
                            anchor="right"
                            disableSwipeToOpen={true}
                            open={openPreguntas}
                            onClose={toggleDrawerPreguntas(false)}
                            onOpen={toggleDrawerPreguntas(true)}
                            className="panelPreguntas"
                        >
                            <PanelPreguntas
                                setOpenPanel={showPreguntas}
                                idn={idn}
                                title={getNodoData(idn).t}
                                setPreguntas={setPreguntas}
                                preguntasSize={preguntasSize}
                            />
                        </SwipeableDrawer>
                    </>
                )}

                <Header
                    titulo={!loading ? dataTid.materia : loadingTxt}
                    avatarData={false}
                    utilesVisible={propsUtiles.visible}
                    paginaRef={paginaRef}
                    loading={loading}
                    imagesLoaded={imagesLoaded}
                    isTid={true}
                    showUtilsBtn={panelresums.length > 0 || panelnots.length > 0}
                    showPreguntasBtn={true}
                    showPreguntas={showPreguntas}
                    showUtils={showUtils}
                    openUtils={openUtils}
                    openPreguntas={openPreguntas}
                    preguntasSize={preguntasSize}
                    sendAction={undefined}
                />
            </PreguntasState>

            {(dataTid && dataTid.TID === TID) || !(loadingTxt || loading) ? (
                <>
                    <br />
                    <br />
                    <div
                        ref={paginaRef}
                        id="sc"
                        className="section"
                        style={{ minHeight: `${minWinHeight}px` }}
                        onMouseUp={onEndSelect}
                        onTouchEnd={onEndSelect}
                        onTouchCancel={onEndSelect}
                        onClick={clickPage}
                    ></div>
                    {imagesLoaded && idn > 0 && <Scroll paginaRef={paginaRef} idn={idn} />}
                </>
            ) : (
                <>
                    {isDark ? (
                        <SkeletonTheme color="#202020" highlightColor="#444">
                            <div className="section">
                                <p className="th">
                                    <Skeleton />
                                </p>
                                <Skeleton count={15} />
                            </div>
                        </SkeletonTheme>
                    ) : (
                        <div className="section">
                            <p className="th">
                                <Skeleton />
                            </p>
                            <Skeleton count={15} />
                        </div>
                    )}
                </>
            )}

            <Footer loading={loading} getNodoData={getNodoData} idn={idn} history={history} />
        </div>
    );
}
