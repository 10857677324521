import React from 'react';

import { useSelector } from 'react-redux';
import Avatar from './avatar';
import styled from '@emotion/styled';

import ImgHome from '../img/footer/Home.svg';
import ImgHomeHover from '../img/footer/HomeHover.svg';
import ImgCalendario from '../img/footer/Calendario.svg';
import ImgCalendarioHover from '../img/footer/CalendarioHover.svg';
import ImgTramites from '../img/footer/Tramites.svg';
import ImgTramitesHover from '../img/footer/TramitesHover.svg';
import ImgPerfil from '../img/footer/Perfil.svg';
import ImgPerfilHover from '../img/footer/PerfilHover.svg';
import { useHistory, useLocation } from 'react-router-dom';
import BtnCarreras from './Footer/BtnCarreras';

const Button = styled('button')`
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: normal;
    border-radius: 0;
    margin: 0 1%;
    .Img {
        width: 100%;
        height: 27px;
        background-image: url(${props => props.bg});
        background-repeat: no-repeat;
        background-position: center;
    }
    &:enabled.active,
    :hover:enabled {
        height: 73px;
        color: #00aae4;
        border-top: ${props => props.istop !== 1 && '2px solid #00aae4'};
        border-bottom: ${props => props.istop === 1 && '2px solid #00aae4'};
    }
    :hover:disabled .Img {
        background-image: url(${props => props.bg});
    }
    &:enabled.active .Img,
    :hover:enabled .Img {
        background-image: url(${props => props.bgh});
    }
`;

export default function MenuItems() {
    const isMenuTop = useSelector(state => state.aplication.isMenuTop);
    const usuario = useSelector(state => state.usuario.usuario);
    const loading1 = useSelector(state => state.usuario.loading);
    const loading2 = useSelector(state => state.aplication.loading);
    const loading3 = useSelector(state => state.homeData.loading);
    const history = useHistory();
    const location = useLocation();

    const getMyClass = v => {
        //let ub = location.pathname.split('app').pop().split('/').pop();
        if (location.pathname.split('app').pop().split('/')[1] === v) {
            return 'active';
        }
        return null;
    };

    let loading = loading1 || loading2 || loading3;

    return (
        <>
            <Button
                disabled={loading}
                onClick={() => history.push('/app')}
                className={location.pathname === '/app' ? 'active' : null}
                type="button"
                bg={ImgHome}
                bgh={ImgHomeHover}
                istop={isMenuTop ? 1 : 0}
            >
                <div className="Img"></div>
                <div style={{ marginTop: '7px' }}>Home</div>
            </Button>

            <BtnCarreras Button={Button} istop={isMenuTop ? 1 : 0} />

            <Button
                disabled={loading}
                onClick={() => history.push('/app/calendario')}
                bg={ImgCalendario}
                bgh={ImgCalendarioHover}
                className={getMyClass('calendario')}
                type="button"
                istop={isMenuTop ? 1 : 0}
            >
                <div className="Img"></div>
                <div style={{ marginTop: '7px' }}>Calendario</div>
            </Button>

            <Button disabled={loading} onClick={() => history.push('/app/tramites')} bg={ImgTramites} bgh={ImgTramitesHover} className={getMyClass('tramites')} type="button" istop={isMenuTop ? 1 : 0}>
                <div className="Img"></div>
                <div style={{ marginTop: '7px' }}>Trámites</div>
            </Button>

            <Button
                disabled={loading}
                onClick={() => history.push('/app/perfil')}
                bg={ImgPerfil}
                bgh={ImgPerfilHover}
                className={getMyClass('perfil')}
                type="button"
                istop={isMenuTop ? 1 : 0}
            >
                {usuario ? <Avatar usuario={usuario} radius={30} /> : <div className="Img"></div>}
                <div style={{ marginTop: '7px' }}>Perfil</div>
            </Button>
        </>
    );
}
