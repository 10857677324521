import React from 'react';
import styled from '@emotion/styled';
import BgIcon from '../img/logo-negativo.svg';

const SelectMod = styled('button')`
    border: none;
    background-color: #00aae4;
    color: #fff;
    width: 100%;
    height: 52px;
    margin: auto;
    margin-bottom: 15px;
    font-size: 16px;

    img {
        margin-right: 10px;
    }

    :hover {
        background-color: #00aae4;
        opacity: 0.5;
    }
`;

export default function ButtonMiES({ onClick, children }) {
    return (
        <SelectMod type="button" onClick={() => onClick('alumno')}>
            <img src={BgIcon} alt="" /> {children}
        </SelectMod>
    );
}
