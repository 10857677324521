import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import clienteAxios from 'config/axios';

import { getFreeSizeLocalstorage, checkRemainingBytes } from '../../components/constants';

import Header from '../../components/tid/header/header';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import { useSelector, useDispatch } from 'react-redux';
import { cargarTid } from 'redux/actions/tidActions';

export default function Errores() {
    const { TID } = useParams();

    const dispatch = useDispatch();

    const dataTid = useSelector(state => state.dataTid.dataTid);
    const sesionTid = useSelector(state => state.dataTid.sesionTid);

    const usuario = useSelector(state => state.usuario.usuario);

    useTitle(!dataTid ? 'Colegio Universitario IES' : 'IES - Reporte de problema - ' + dataTid.materia);

    const mensajeRef = useRef();

    const [loading, setLoading] = useState(true);
    const [storageData, setStorageData] = useState({ espaciousado: 0, espaciolibre: 0, total: 0, percocup: 0 });

    const sendAction = async () => {
        const mensaje = mensajeRef.current.value.trim();

        if (mensaje.length < 10) {
            dispatch(mostrarAlerta('Mensaje demasiado corto.', 'Reportar problema'));
            return;
        }

        const data = {
            email: usuario.email,
            nombre: `${usuario.apellidos}, ${usuario.nombres}`,
            TID: parseInt(TID),
            mensaje: mensaje,
            espacio_libre: storageData.espaciolibre,
            idn: sesionTid.idn,
            sesion: sesionTid.sesion,
            avanceNodos: sesionTid.avanceNodos,
        };

        setLoading(true);

        try {
            const result = await clienteAxios.post('/consulta_error', data);
            dispatch(mostrarAlerta(result.data.text, 'Reportar problema'));
        } catch (error) {
            dispatch(mostrarAlerta(error, 'Reportar problema'));
        }

        setLoading(false);
    };

    useEffect(() => {
        if (dataTid && Object.keys(usuario).length > 0) {
            //setTimeout(() => {
            setLoading(false);

            let espaciousado = checkRemainingBytes();
            let espaciolibre = getFreeSizeLocalstorage();
            let total = (espaciousado + espaciolibre).toFixed(0);
            let percocup = ((espaciousado * 100) / (espaciousado + espaciolibre)).toFixed(1);

            espaciousado = espaciousado.toFixed(0);
            espaciolibre = espaciolibre.toFixed(0);

            setStorageData({
                espaciousado,
                espaciolibre,
                total,
                percocup,
            });
            //}, 1000);
        }
        // eslint-disable-next-line
    }, [dataTid, usuario]);

    useEffect(() => {
        if (TID) {
            //setTID(TID);
            dispatch(cargarTid(TID, usuario.rol));
            // eslint-disable-next-line
        }
    }, [TID, dispatch, usuario]);

    /*
    useEffect(() => { 
        setTID(parseInt(TID));
        // eslint-disable-next-line
    }, []); 
    */
    return (
        <>
            <Header
                titulo={!loading ? 'Reportar problema en ' + dataTid.materia : 'Cargando datos...'}
                avatarData={undefined}
                utilesVisible={false}
                paginaRef={undefined}
                loading={loading}
                imagesLoaded={false}
                isTid={false}
                showPreguntasBtn={false}
                showPreguntas={undefined}
                openPreguntas={false}
                showUtilsBtn={false}
                showUtils={undefined}
                openUtils={false}
                sendAction={sendAction}
            />

            {!loading && (
                <div className="section media flex nosombra pl0 pr0 absPos">
                    <div className="contp f16">
                        <strong>De:</strong> {usuario.email}
                    </div>
                    <div className="contp bgmgris" onClick={() => mensajeRef.current.focus()}>
                        Describí el problema o compratí tus ideas.
                    </div>
                    <textarea className="txfull" autoFocus ref={mensajeRef}></textarea>
                </div>
            )}
        </>
    );
}
