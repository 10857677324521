import { SNACKBAR_MSG, SET_OPEN_MENU, SET_APP_LOADING, SET_APP_TITULO, SET_APP_ISMENUTOP } from '../types';

const initialState = {
    snackmsg: null,
    openMenu: false,
    loading: false,
    titulo: null,
    isMenuTop: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case SNACKBAR_MSG:
            return {
                ...state,
                snackmsg: action.payload,
            };
        case SET_OPEN_MENU:
            return {
                ...state,
                openMenu: action.payload,
            };
        case SET_APP_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_APP_ISMENUTOP:
            return {
                ...state,
                isMenuTop: action.payload,
            };
        case SET_APP_TITULO:
            return {
                ...state,
                titulo: action.payload,
            };
        default:
            return state;
    }
}
