import React, { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

import clienteAxios from 'config/axios';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Titulo from 'components/TituloH2';
import TidBox from 'components/carrera/TidBox';
import DictadoBox from 'components/carrera/DictadoBox';
import InasistenciasBox from 'components/carrera/InasistenciasBox';
import NotasBox from 'components/carrera/NotasBox';
import ArchivosBox from 'components/carrera/ArchivosBox';
import ExamenesBox from 'components/carrera/ExamenesBox';
import EncuestaBox from 'components/carrera/EncuestaBox';
import Avatar from 'components/avatar';
import { Grilla } from 'components/styles';
import AnuncioBox from 'components/carrera/AnuncioBox';

const DataDocente = styled('div')`
    padding: 15px;
    display: flex;
    font-size: 18px;
    .docName {
        margin-left: 7px;
    }
    .docente {
        width: 100%;
        display: flex;
        align-items: center;
    }
    a {
        display: block;
    }
`;

export default function Materia({ rol = 'alumno' }) {
    const dispatch = useDispatch();

    const { id_materia, id_carrera } = useParams();

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);

    const [materia, setMateria] = useState();

    useTitle('mi IES - materia');

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));

            let result;

            try {
                result = await clienteAxios.get(`/get_materia`, { params: { id_materia, id_carrera, rol } });

                if (result.data.r === 0) {
                    dispatch(mostrarAlerta(result.data.text, 'Error'));
                    return false;
                }

                setMateria(result.data.materia);
                dispatch(setTitulo(result.data.materia.desc));
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (Object.keys(usuario).length > 0) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [usuario, id_materia, id_carrera]);

    useEffect(() => {
        dispatch(setTitulo('Materia'));
    }, [dispatch]);

    if (!Object.keys(usuario).length > 0) return null;

    //const rol = usuario.esDocente ? 'docente' : 'alumno';

    return (
        <Grilla>
            {materia && (
                <>
                    <TidBox data={materia} rol={rol} />

                    <AnuncioBox data={materia} rol={rol} changeData={setMateria}/>

                    <DictadoBox data={materia.detalle_cursado.dictado} />

                    <InasistenciasBox data={materia.detalle_cursado.inasistencias} />

                    <NotasBox data={materia.detalle_cursado.notas} />

                    <ArchivosBox data={materia} rol={rol} changeData={setMateria} />

                    <ExamenesBox data={materia.examenes} />

                    <EncuestaBox data={materia.encuesta} />

                    {materia.detalle_cursado.docente && (
                        <div>
                            <Titulo>DOCENTE</Titulo>
                            <DataDocente>
                                <div className="docente">
                                    <>
                                        <Avatar usuario={materia.detalle_cursado.docente} radius={76} />
                                        <div className="docName">
                                            <strong>
                                                {materia.detalle_cursado.docente.nombres}, {materia.detalle_cursado.docente.apellidos}
                                            </strong>
                                            <a href={`mailto:${materia.detalle_cursado.docente.email}`} target="_blank" rel="noopener noreferrer">
                                                {materia.detalle_cursado.docente.email}
                                            </a>
                                        </div>
                                    </>
                                </div>
                            </DataDocente>
                        </div>
                    )}
                </>
            )}

            {loading && (
                <div>
                    <div style={{ padding: '15px' }}>
                        <Skeleton height={130} />
                    </div>
                    <div style={{ padding: '15px' }}>
                        <Skeleton height={130} />
                    </div>
                    <div style={{ padding: '15px' }}>
                        <Skeleton height={130} />
                    </div>
                    <div style={{ padding: '15px' }}>
                        <Skeleton height={130} />
                    </div>
                </div>
            )}
        </Grilla>
    );
}
