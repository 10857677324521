import React from 'react';

import './utiles.css';

export default function Utiles({noteSelectedText, resaltarSelectedText, unResaltarSelectedText, propsUtiles, closeUtiles}){

    if(!propsUtiles.visible) return null;

    const onclickutil = action => {
        closeUtiles();
        action();
    }

    return(
        <div className="indicador" style={{position: propsUtiles.position, right: propsUtiles.right, top:propsUtiles.top, left:propsUtiles.left}}>
            <button className="anotar" title="agregar nota"  onClick={() => onclickutil(noteSelectedText)}></button>
            <button className="pintar" title="marcar selección"  onClick={() => onclickutil(resaltarSelectedText)}></button>
            <button className="despintar" title="desmarcar"  onClick={() => onclickutil(unResaltarSelectedText)}></button>
        </div>
    )
}