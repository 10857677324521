import { CARGA_APP, SETEAR_LOADING_APP, ERROR_CARGA_APP, CARGA_APP_NUMMENSAJESNUEVOS } from 'redux/types';

const initialState = {
    carreras: null,
    mensajes: 0,
    loading: false,
    error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case SETEAR_LOADING_APP:
            return {
                ...state,
                loading: action.payload,
            };
        case ERROR_CARGA_APP:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CARGA_APP_NUMMENSAJESNUEVOS:
            return {
                ...state,
                mensajes: action.payload,
                error: null,
            };
        case CARGA_APP:
            return {
                ...state,
                carreras: action.payload.carreras,
                loading: false,
                error: null,
            };
        default:
            return state;
    }
}
