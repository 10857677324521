import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import useCarreraDesign from '../../hooks/useCarreraDesign';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    cursor: pointer;
    :hover {
        color: #00aae4;
    }
`;

const IconCarrera = styled('div')`
    color: #fff;
    font-size: 24px;
    background-color: ${props => props.design.color};
    height: 120px;
    background-image: url(${props => props.design.icon});
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    min-width: 65px;
    width: 65px;
    height: 65px;
    margin: 16px;
    padding: 3px 6px 6px 4px;
`;

const CarreraName = styled('h3')`
    font-size: 24px;
    font-weight: normal;
    margin: 0;
`;

const CarreraWrap = styled('div')`
    display: flex;
    align-items: center;
`;
const CarreraData = styled('div')`
    font-size: 14px;
    display: flex;
    margin-left: 20px;
    margin-bottom: 15px;
`;

function CarreraItem({ data }) {
    const history = useHistory();
    const rol = useSelector(state => state.usuario.usuario.rol);

    const { carrera, id, total, total_aprobadas } = data;

    const handleClick = () => {
        history.push(`/app/carrera/${id}/${rol}`);
    };

    const { getCarreraDesign } = useCarreraDesign();

    if (!rol) return null;

    return (
        <Container onClick={handleClick}>
            <CarreraWrap>
                <IconCarrera design={getCarreraDesign(id)}></IconCarrera>
                <CarreraName>{carrera}</CarreraName>
            </CarreraWrap>
            {rol === 'alumno' && (
                <CarreraData>
                    {total_aprobadas} / {total} materias completadas
                </CarreraData>
            )}
        </Container>
    );
}

export default React.memo(CarreraItem);
