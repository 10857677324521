import React, { useEffect, useState } from 'react';
//import { useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';
import { setTitulo, setLoading } from 'redux/actions/aplication';
// import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

import clienteAxios from 'config/axios';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import { useSelector, useDispatch } from 'react-redux';
import MateriaItem from '../components/carrera/MateriaItem';
import Evento from '../components/Evento';
import Noticia from '../components/Noticia';
import CarreraItem from '../components/carrera/CarreraItem';
import Titulo from '../components/TituloH2';
import { Grilla } from '../components/styles';

function Home() {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);
    const carreras = useSelector(state => state.homeData.carreras);

    const [materias, setMaterias] = useState([]);
    const [eventos, setEventos] = useState([]);
    const [noticias, setNoticias] = useState([]);

    // let history = useHistory();

    //const [lastAccessTids, setLasAccessTids] = useState([]);

    //const token = useSelector(state => state.usuario.token);

    useTitle('mi IES - Home');

    useEffect(() => {
        dispatch(setTitulo(null));
    }, [dispatch]);

    useEffect(() => {
        if (Object.keys(usuario).length > 0) {
            const fetchData = async () => {
                dispatch(setLoading(true));

                let result;

                try {
                    result = await clienteAxios.get(`/get_last_tids`, { params: { rol: usuario.rol } });

                    if (result.data.r === 0) {
                        dispatch(mostrarAlerta(result.data.text, 'Error'));
                        return false;
                    }
                    setMaterias(result.data.materias);
                } catch (error) {
                    console.log(error.data.text);
                    dispatch(mostrarAlerta(error, 'Error'));
                } finally {
                    dispatch(setLoading(false));
                }
            };
            fetchData();
        }
        // eslint-disable-next-line
    }, [usuario]);

    useEffect(() => {
        const fetchData = async () => {
            let result;
            let idsCarreras = [];
            let totalCarreras = [...carreras.docente, ...carreras.alumno];

            for (let index = 0; index < totalCarreras.length; index++) {
                idsCarreras.push(totalCarreras[index].id);
            }
            dispatch(setLoading(true));
            try {
                result = await clienteAxios.get('/eventos', { params: { c: idsCarreras.join(',') } });

                if (result.data.r === 0) {
                    dispatch(mostrarAlerta(result.data.text, 'Error'));
                    return false;
                }
                setEventos(result.data.eventos);

                result = await clienteAxios.get('/noticias', { params: { c: idsCarreras.join(',') } });

                if (result.data.r === 0) {
                    dispatch(mostrarAlerta(result.data.text, 'Error'));
                    return false;
                }
                setNoticias(result.data.noticias);
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (carreras && typeof carreras.docente === 'object' && typeof carreras.alumno === 'object') fetchData();
    }, [carreras, dispatch]);

    if (!Object.keys(usuario).length > 0) return null;

    return (
        <>
            {loading && (
                <Grilla>
                    <div>
                        <Titulo>
                            <Skeleton height={20} />
                        </Titulo>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={130} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={130} />
                        </div>
                    </div>
                    <div>
                        <Titulo>
                            <Skeleton height={20} />
                        </Titulo>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={130} />
                        </div>
                        <div style={{ padding: '15px' }}>
                            <Skeleton height={130} />
                        </div>
                    </div>
                </Grilla>
            )}

            <Grilla>
                {carreras && (
                    <div>
                        {usuario.rol === 'docente' && carreras.docente && carreras.docente.length > 0 && (
                            <>
                                <Titulo>Carreras docente</Titulo>
                                <div>
                                    {carreras.docente.map((item, key) => {
                                        return <CarreraItem data={item} key={key} />;
                                    })}
                                </div>
                                <hr />
                            </>
                        )}

                        {usuario.rol === 'alumno' && carreras.alumno && carreras.alumno.length > 0 && (
                            <>
                                <Titulo>Carreras cursando</Titulo>
                                <div>
                                    {carreras.alumno.map((item, key) => {
                                        return <CarreraItem data={item} key={key} />;
                                    })}
                                </div>
                                <hr />
                            </>
                        )}
                    </div>
                )}

                {usuario.rol === 'alumno' &&  materias.length > 0 && (
                    <div>
                        <Titulo>Materias en progreso</Titulo>
                        <div>
                            {materias.map((item, key) => {
                                return <MateriaItem data={item} key={key} />;
                            })}
                        </div>
                        <hr />
                    </div>
                )}

                {eventos.length > 0 && (
                    <div>
                        <Titulo>Eventos</Titulo>
                        <div>
                            {eventos.map((item, key) => {
                                return <Evento data={item} key={key} />;
                            })}
                        </div>
                        <hr />
                    </div>
                )}

                {noticias.length > 0 && (
                    <div>
                        <Titulo>Noticias</Titulo>
                        <div>
                            {noticias.map((item, key) => {
                                return <Noticia data={item} key={key} />;
                            })}
                        </div>
                        <hr />
                    </div>
                )}
            </Grilla>
        </>
    );
}

export default React.memo(Home, (prevProps, nextProps) => {
    // console.log({prevProps, nextProps})
    return prevProps.token === nextProps.token;
});
