import React from 'react';
import Avatar from 'components/avatar';

export default function Pregunta({usuario, pregunta, setPreguntaId}){
    
    const {txt, time, answers, id} = pregunta;

    return(
        <div className="question-list-question--question-wrapper">
            <Avatar usuario={usuario} />
            <div className="question-list-question--info-container">
                <div className="question-list-question--question-link-row" onClick={()=>setPreguntaId(id)} >
                    <div className="question-list-question--text-content">
                        <div className="question-list-question--title">{txt}</div>
                        {/* { body !== '' &&
                            <div className="question-list-question--body">{parse(body)}</div>
                        } */}
                    </div>
                    <div className="question-list-question--upvote-response-num-container">
                        <div className="question-list-question--response-count-container">
                            <div><span className="question-list-question--num-answers">{answers}</span><span className="question-list-question--comments-icon iesi-small iesi iesi-comments"></span></div>
                        </div>
                    </div>
                </div>
                <div className="font-text-xs">
                    {usuario.nombres.split(' ')[0]} · <span>{time}</span>
                </div>
            </div>                            
        </div>
    )
}
