import { SETEAR_USUARIO, ELIMINAR_USUARIO, PUSH_UNSUSCRIBE, SETEAR_LOADING, SNACKBAR_MSG, ERROR_CARGA_USUARIO, SETEAR_USUARIO_MODE } from 'redux/types';

import clienteAxios from 'config/axios';
import { device_data, localVar } from 'components/constants';
import getUuidDevice from 'components/uuidevice';

export const iniciarSesion = data => {
    return async dispatch => {
        //const token = localVar.getItem('token');
        const uuidDevice = !localVar.getItem('uuidDevice') ? getUuidDevice() : localVar.getItem('uuidDevice');

        if (!localVar.getItem('uuidDevice')) {
            localVar.setItem('uuidDevice', uuidDevice);
        }

        if (Object.keys(data).length === 0) {
            data = { ...data };
        }

        data = { ...data, device_data, uuidDevice };

        dispatch({
            type: SETEAR_LOADING,
            payload: true,
        });

        try {
            const result = await clienteAxios.post('/login-token', data);

            if (result.data.r === 0) {
                dispatch({
                    type: ERROR_CARGA_USUARIO,
                    payload: {
                        title: 'Login',
                        text: result.data.text,
                        actionBtn1: () => dispatch(cerrarSesion()),
                        btn1: 'Aceptar',
                        btn2: null,
                    },
                });

                // dispatch(mostrarAlerta(result.data.text, 'Login', cerrarSesion, 'Aceptar', null));

                cerrarSesion();

                return false;
            }

            let rol = result.data.usuario.esDocente ? 'docente' : 'alumno';
            //result.data.usuario.rol = rol;

            if (result.data.usuario.esDocente && result.data.usuario.esAlumno) {
                rol = localStorage.getItem('rolies');
                if (rol) {
                    if (rol === 'docente') {
                        result.data.usuario.rol = 'docente';
                    } else {
                        result.data.usuario.rol = 'alumno';
                    }
                }
            } else {
                localStorage.setItem('rolies', rol);
                result.data.usuario.rol = rol;
            }

            dispatch({
                type: SETEAR_USUARIO,
                payload: result.data,
            });

            /*
            Ver tiempo q hace q no entra y darle bienvenida y decirle q fue el ultimo tid q vio y en que nodo
            */
            setTimeout(() => {
                dispatch({
                    type: SNACKBAR_MSG,
                    payload: result.data.text,
                });
            }, 1000);
        } catch (error) {
            dispatch({
                type: ERROR_CARGA_USUARIO,
                payload: {
                    title: 'Login',
                    text: error,
                    actionBtn1: () => dispatch(cerrarSesion()),
                    btn1: 'Aceptar',
                    btn2: null,
                },
            });
        } finally {
            dispatch({
                type: SETEAR_LOADING,
                payload: false,
            });
        }
    };
};

export const cerrarSesion = () => {
    return async dispatch => {
        dispatch({
            type: PUSH_UNSUSCRIBE,
        });

        dispatch({
            type: SETEAR_LOADING,
            payload: true,
        });

        const uuidDevice = !localVar.getItem('uuidDevice') ? getUuidDevice() : localVar.getItem('uuidDevice');

        const data = {
            uuidDevice,
        };

        try {
            const result = await clienteAxios.post('/logout', data);
            console.log(result);
        } catch (error) {
            console.log(error);
        } finally {
            dispatch({
                type: SETEAR_LOADING,
                payload: false,
            });
        }

        localVar.removeItem('token');
        localStorage.removeItem('perma');
        localStorage.removeItem('tidConfig');
        localStorage.removeItem('rolies');
        localStorage.clear();
        sessionStorage.clear();

        dispatch({
            type: ELIMINAR_USUARIO,
        });

        //iesAuth.signout();

        if (window.location.pathname !== '/login') window.location.replace('/login');
    };
};

export const recuperarPassword = (data, setverifyData, setError) => {
    return async dispatch => {
        dispatch({
            type: SETEAR_LOADING,
            payload: true,
        });
        try {
            let result = await clienteAxios.post(`/change-password`, data);
            if (result.data.r === 0) {
                setError(result.data.text);
            }
            if (result.data.verify_code) {
                setverifyData({ verify_text: result.data.verify_text, verify_time: result.data.verify_time });
            }
        } catch (error) {
            setError(error);
        } finally {
            dispatch({
                type: SETEAR_LOADING,
                payload: false,
            });
        }
    };
};

export const changeMode = mode => {
    return dispatch => {
        localStorage.setItem('rolies', mode);
        dispatch({
            type: SETEAR_USUARIO_MODE,
            payload: mode,
        });
    };
};
