import React, { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import { mostrarAlerta } from 'redux/actions/alertaActions';
import clienteAxios from 'config/axios';
import Titulo from 'components/TituloH2';
import styled from '@emotion/styled';
import { Table, IconButton, Collapse, Box } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const Container = styled('div')`
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function RowPagare({ row }) {
    return (
        <>
            <TableRow hover tabIndex={-1}>
                <TableCell>{row.Pagare}</TableCell>
                <TableCell>{row.Emision}</TableCell>
                <TableCell>{row.Vencimiento}</TableCell>
                <TableCell>${row.Importe}</TableCell>
                <TableCell>{row.Estado}</TableCell>
            </TableRow>
        </>
    );
}

function RowMovimientos({ row }) {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <TableRow hover tabIndex={-1}>
                <TableCell>{row.Fecha}</TableCell>
                <TableCell>{row.Debe}</TableCell>
                <TableCell>{row.Haber}</TableCell>
                <TableCell>{row.Saldo}</TableCell>
                <TableCell>{row.Importe}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            Comprobante: {row.Comprobante}
                            <br />
                            Nro.: {row.NumeroComprobante}
                            <br />
                            Tipo: {row.TipoComprobante}
                            {row.Descripcion !== '' && (
                                <>
                                    <br />
                                    Descripción: {row.Descripcion}
                                </>
                            )}
                            <br />
                            Nro. Cae: {row.NroCae}
                            <br />
                            {row.Url !== '' && (
                                <a href={row.Url} target="_blank" rel="noreferrer">
                                    FACTURA
                                </a>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function Estadodecuenta() {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);
    const carreras = useSelector(state => state.homeData.carreras);

    const [cuenta, setCuenta] = useState();

    useTitle('Mi Perfil - Estado de cuenta');

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));

            try {
                let result = await clienteAxios.get(`/estado-de-cuenta`);

                setCuenta(result.data.cuenta);
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (Object.keys(usuario).length > 0) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [usuario, carreras]);

    useEffect(() => {
        dispatch(setTitulo('Mi Perfil - Estado de cuenta'));
    }, [dispatch]);

    if (loading)
        return (
            <Container>
                <p>Cargando...</p>
            </Container>
        );

    if (!cuenta) return null;

    const { Saldo, Pagares, Movimientos } = cuenta;
    return (
        <>
            <Container>
                <h2>Saldo: ${Saldo}</h2>
            </Container>

            {Pagares && Pagares.length > 0 && (
                <>
                    <Titulo>Pagarés</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Pagaré</TableCell>
                                    <TableCell>Emisión</TableCell>
                                    <TableCell>Vencimiento</TableCell>
                                    <TableCell>Importe</TableCell>
                                    <TableCell>Estado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Pagares.map((row, key) => {
                                    return <RowPagare key={key} row={row} />;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {Movimientos && Movimientos.length > 0 && (
                <>
                    <Titulo>Movimientos</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Debe</TableCell>
                                    <TableCell>Haber</TableCell>
                                    <TableCell>Saldo</TableCell>
                                    <TableCell>Importe</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Movimientos.map((row, key) => {
                                    return <RowMovimientos key={key} row={row} />;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </>
    );
}
