import React, { useEffect, useState } from 'react';
import { CalendarCss } from 'components/styles';
import Evento from '../Evento';
import Modulo from './Modulo';
import { scrollToBottom } from '../constants';

export default function Calendar({ yyyy, mm, agendaMes }) {
    const [calendar, setCalendar] = useState();
    const [agendaDia, setAgendaDia] = useState([]);
    const [infoDia, setInfoDia] = useState();
    const [eventoDia, setEventoDia] = useState();

    const dias = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    function CheckLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    function weekCount(year, month) {
        let weeks = [],
            firstDate = new Date(year, month, 1),
            lastDate = new Date(year, month + 1, 0),
            numDays = lastDate.getDate();

        let start = 1;
        let end = 7 - firstDate.getDay();
        while (start <= numDays) {
            weeks.push({ start: start, end: end });
            start = end + 1;
            end = end + 7;
            if (end > numDays) end = numDays;
        }
        return weeks.length;
    }

    useEffect(() => {
        let mesnum = mm;
        if (mm.length === 2 && mm !== 10) {
            mesnum = mm.replace('0', '');
        }
        mesnum = parseInt(mesnum) - 1;
        let fecha = new Date(),
            fechaHoy = new Date();
        fecha.setFullYear(yyyy);
        fecha.setMonth(mesnum);

        let calendario = {};
        calendario.mes_actual = fecha.getMonth();
        calendario.mes_hoy = fechaHoy.getMonth();
        calendario.anio_hoy = fechaHoy.getFullYear();
        calendario.dia_hoy = fechaHoy.getDate();
        calendario.mes_nombre = meses[calendario.mes_actual];
        calendario.anio = fecha.getFullYear();
        if (fechaHoy.getFullYear() === fecha.getFullYear() && fechaHoy.getMonth() === fecha.getMonth()) {
            calendario.hoy = fechaHoy.getDate();
        } else {
            calendario.hoy = -1;
        }
        let primero_de_mes = new Date(fecha.getFullYear(), calendario.mes_actual, 1);
        calendario.primer_dia_mes = primero_de_mes.getDay();
        let num_semanas = weekCount(yyyy, mesnum);
        dias[1] += CheckLeapYear(yyyy) ? 1 : 0;

        calendario.semanas = [];
        let dia_numero = 1;
        for (let i = 0; i < num_semanas; i++) {
            calendario.semanas[i] = [];
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < calendario.primer_dia_mes) {
                    // Primera semana del mes, incompleta
                    calendario.semanas[i][j] = '';
                } else {
                    if (dia_numero <= dias[calendario.mes_actual]) {
                        calendario.semanas[i][j] = dia_numero;
                        dia_numero++;
                    }
                }
            } // end for dias semana
        } // end for semanas
        setCalendar(calendario);
        //handleClickDay(6)
        // eslint-disable-next-line
    }, [mm, yyyy]);

    const verifyAgClassEventos = dia => {
        if (agendaMes && agendaMes.eventos.indexOf(dia) !== -1) {
            return true;
        } else {
            return false;
        }
    };

    const verifyAgClassAcad = dia => {
        if (agendaMes && agendaMes.academico.indexOf(dia) !== -1) {
            return true;
        } else {
            return false;
        }
    };

    const verifyAgClassFeriad = dia => {
        if (agendaMes && agendaMes.feriados.indexOf(dia) !== -1) {
            return true;
        } else {
            return false;
        }
    };

    const isDisabledDay = dia =>
        (calendar.mes_actual === calendar.mes_hoy && calendar.anio === calendar.anio_hoy && dia < calendar.dia_hoy) || verifyAgClassFeriad(dia);

    const getMyClass = dia => {
        if (isNaN(dia)) return null;
        let classArr = [];
        let isDisabled = isDisabledDay(dia);
        let isEvent = verifyAgClassEventos(dia);
        //let isFeriad = verifyAgClassFeriad(dia);
        if (dia === calendar.hoy) {
            classArr.push('hoy');
        }
        if (agendaDia && dia === agendaDia) {
            classArr.push('dvisto');
        }
        if (isDisabled) {
            classArr.push('disabledia');
        }
        if (isEvent) {
            classArr.push('act_ev');
        }
        if (!isDisabled && verifyAgClassAcad(dia)) {
            classArr.push('act_acad');
        }
        return classArr.join(' ');
    };

    const handleClickDay = dia => {
        if (!verifyAgClassEventos(dia) && isDisabledDay(dia)) {
            return null;
        }
        setInfoDia(agendaMes.detalle_academicos.filter(item => parseInt(item.dia) === dia)[0]);
        setEventoDia(agendaMes.detalle_eventos.filter(item => item.index === dia));
        setAgendaDia(dia);
        scrollToBottom();
    };

    useEffect(() => {
        if (calendar) {
            handleClickDay(calendar.hoy);
        }
        // eslint-disable-next-line
    }, [calendar]);

    if (!calendar) return null;

    return (
        <>
            <CalendarCss>
                <table className="calendario">
                    <thead>
                        <tr>
                            <th>D</th>
                            <th>L</th>
                            <th>M</th>
                            <th>M</th>
                            <th>J</th>
                            <th>V</th>
                            <th>S</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calendar.semanas.map((week, key) => {
                            return (
                                <tr key={key}>
                                    {week.map((dia, keyDay) => {
                                        let diaInt = parseInt(dia);
                                        return (
                                            <td key={keyDay} className={getMyClass(diaInt)} onClick={() => handleClickDay(diaInt)}>
                                                <span>{dia}</span>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </CalendarCss>

            {infoDia && infoDia.modulos.length > 0 && (
                <>
                    {infoDia.modulos.map((modulo, key) => {
                        return <Modulo key={key} i={key} modulo={modulo} />;
                    })}
                </>
            )}

            {eventoDia &&
                eventoDia.map((evento, key) => {
                    return <Evento style={{ width: '95%', maxWidth: '380px' }} data={evento} key={key} />;
                })}
        </>
    );
}
