import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTitle } from 'react-use';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import { mostrarAlerta } from 'redux/actions/alertaActions';
import clienteAxios from 'config/axios';
import Titulo from 'components/TituloH2';
import styled from '@emotion/styled';
import { Table, IconButton, Collapse, Box } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const Container = styled('div')`
    padding: 30px;
    width: 100%;
    transition: opacity 0.15s ease-out;
`;

const ChipSquare = styled('div')`
    width: 34px;
    height: 30px;
    border-radius: 4px;
    background-color: #00aae4;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
`;

const ChipSquareMin = styled('div')`
    min-width: 20px;
    padding: 0 5px;
    height: 20px;
    border-radius: 4px;
    background-color: #00aae4;
    color: #ffffff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: auto;
`;

function Row({ row }) {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <TableRow hover tabIndex={-1}>
                <TableCell align="center">{row.Semestre}</TableCell>
                <TableCell>{row.Asignatura}</TableCell>
                <TableCell>{row.Fecha}</TableCell>
                <TableCell align="center">{row.Calificacion && row.Calificacion !== '' && <ChipSquare>{row.Calificacion}</ChipSquare>}</TableCell>
                <TableCell>{row.Condicion}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            Modalidad: {row.Modalidad}
                            <br />
                            Ctro. Ext.: {row['Ctro. Ext.']}
                            <br />
                            Ctro. Ubica.: {row['Ctro. Ubica.']}
                            <br />
                            Tribunal: Presidente {row.Presidente} - 1 Vocal {row['1 Vocal']} - 2 Vocal {row['2 Vocal']}
                            <br />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function RowEnCurso({ row }) {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <TableRow hover tabIndex={-1}>
                <TableCell align="center">{row.Semestre}</TableCell>
                <TableCell>{row.Asignatura}</TableCell>
                <TableCell>{row['Fecha Cursado']}</TableCell>
                <TableCell align="center">
                    TP:<ChipSquareMin>{row['TP']}</ChipSquareMin>, P1: <ChipSquareMin>{row['1 IE']}</ChipSquareMin>, P2:{' '}
                    <ChipSquareMin>{row['2 IE']}</ChipSquareMin>
                    {row['Recup'] && row['Recup'] !== '' && (
                        <>
                            , Recup: <ChipSquareMin>{row['Recup']}</ChipSquareMin>
                        </>
                    )}
                </TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            TP: {row['TP']} {row['Fecha TP'] !== '' && <>-Fecha: {row['Fecha TP']}</>}
                            <br />
                            Parcial 1: {row['1 IE']} {row['Fecha 1'] !== '' && <>- Fecha: {row['Fecha 1']}</>}
                            <br />
                            Parcial 2: {row['2 IE']} {row['Fecha 2'] !== '' && <>Fecha: {row['Fecha 2']}</>}
                            <br />
                            {row['Recup'] && (
                                <>
                                    Recup: {row['Recup']} {row['Fecha Recup'] !== '' && <>- Fecha: {row['Fecha Recup']}</>}
                                    <br />
                                </>
                            )}
                            Ctro. Ext.: {row['Ctro. Ext.']}
                            <br />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function RowRegular({ row }) {
    const [open, setOpen] = React.useState(false);
    let can_tp = row['TP'] && row['TP'] !== '' && row['TP'] !== '-';
    let can_p1 = row['1 IE'] && row['1 IE'] !== '' && row['1 IE'] !== '-';
    let can_p2 = row['2 IE'] && row['2 IE'] !== '' && row['2 IE'] !== '-';
    return (
        <>
            <TableRow hover tabIndex={-1}>
                <TableCell align="center">{row.Semestre}</TableCell>
                <TableCell>{row.Asignatura}</TableCell>
                <TableCell>{row['Fecha Fin de Cursado']}</TableCell>
                {window.innerWidth > 376 && (
                    <TableCell align="center">
                        {can_tp && (
                            <>
                                TP:<ChipSquareMin>{row['TP']}</ChipSquareMin>,{' '}
                            </>
                        )}
                        {can_p1 && (
                            <>
                                P1: <ChipSquareMin>{row['1 IE']}</ChipSquareMin>,{' '}
                            </>
                        )}
                        {can_p2 && (
                            <>
                                P2: <ChipSquareMin>{row['2 IE']}</ChipSquareMin>,{' '}
                            </>
                        )}

                        {row['Recup'] && row['Recup'] !== '' && (
                            <>
                                , Recup: <ChipSquareMin>{row['Recup']}</ChipSquareMin>
                            </>
                        )}
                    </TableCell>
                )}
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={window.innerWidth > 376 ? 5 : 4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            TP: {row['TP']} {row['Fecha TP'] !== '' && <>-Fecha: {row['Fecha TP']}</>}
                            <br />
                            Parcial 1: {row['1 IE']} {row['Fecha 1'] !== '' && <>- Fecha: {row['Fecha 1']}</>}
                            <br />
                            Parcial 2: {row['2 IE']} {row['Fecha 2'] !== '' && <>Fecha: {row['Fecha 2']}</>}
                            <br />
                            {row['Recup'] && (
                                <>
                                    Recup: {row['Recup']} {row['Fecha Recup'] !== '' && <>- Fecha: {row['Fecha Recup']}</>}
                                    <br />
                                </>
                            )}
                            Ctro. Ext.: {row['Ctro. Ext.']}
                            <br />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function Analitico() {
    const dispatch = useDispatch();

    let { id_carrera } = useParams();
    id_carrera = parseInt(id_carrera);
    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);
    const carreras = useSelector(state => state.homeData.carreras);

    const [analitico, setAnalitico] = useState();

    useTitle('mi IES - Analítico');

    useEffect(() => {
        const fetchData = async () => {
            let carreraDesc = carreras.alumno.find(i => i.id === id_carrera);

            dispatch(setLoading(true));

            try {
                let data = {
                    id_carrera: id_carrera,
                };
                let result = await clienteAxios.get(`/analitico`, { params: data });
                result.data.analitico.carreraDesc = carreraDesc.carrera;
                dispatch(setTitulo('Mi Perfil - Analítico ' + carreraDesc.carrera));
                setAnalitico(result.data.analitico);
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (Object.keys(usuario).length > 0 && carreras && carreras.alumno && carreras.alumno.findIndex(i => i.id === id_carrera) > -1) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [usuario, carreras, id_carrera]);

    useEffect(() => {
        dispatch(setTitulo('Mi Perfil - Analítico'));
    }, [dispatch]);

    if (loading)
        return (
            <Container>
                <p>Cargando...</p>
            </Container>
        );

    if (!analitico) return null;

    const { carreraDesc, Resolucion, Modalidad, Rendidas, Libres, Beca, Promocionadas, Regularizadas } = analitico;
    const EquivalenciaPase = analitico['Equivalencia o Pase'];
    const EnCurso = analitico['En curso'];
    const EquivalenciaSinFacturar = analitico['Equivalencia sin facturar'];

    return (
        <>
            <Container>
                <h3>Carrera: {carreraDesc}</h3>
                <h3>Analítico - Resolución: {Resolucion}</h3>
                <h3>Modalidad: {Modalidad}</h3>
                {Beca !== '' && Beca !== null && <h3>Beca: {Beca}</h3>}
            </Container>

            {EnCurso && EnCurso.length > 0 && (
                <>
                    <Titulo>ASIGNATURAS EN CURSO</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sem</TableCell>
                                    <TableCell>Asignatura</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Calificación</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EnCurso.map((row, key) => {
                                    return <RowEnCurso key={key} row={row} />;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {Regularizadas && Regularizadas.length > 0 && (
                <>
                    <Titulo>REGULARIZADAS</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sem</TableCell>
                                    <TableCell>Asignatura</TableCell>
                                    <TableCell>Fecha fin de cursado</TableCell>
                                    {window.innerWidth > 376 && <TableCell>Calificación</TableCell>}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Regularizadas.map((row, key) => {
                                    return <RowRegular key={key} row={row} />;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {Promocionadas && Promocionadas.length > 0 && (
                <>
                    <Titulo>Promocionadas</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sem</TableCell>
                                    <TableCell>Asignatura</TableCell>
                                    <TableCell>Fecha Fin de Cursado</TableCell>
                                    <TableCell>Calificaciones</TableCell>
                                    <TableCell>Ctro. Ext.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Promocionadas.map((row, key) => {
                                    let p1 = row['1 IE'];
                                    let p2 = row['2 IE'];

                                    row.Calificaciones = (
                                        <>
                                            1 Parcial: {p1}, 2 Parcial: {p2}
                                        </>
                                    );

                                    return (
                                        <TableRow hover tabIndex={-1} key={key}>
                                            <TableCell>{row.Semestre}</TableCell>
                                            <TableCell>{row.Asignatura}</TableCell>
                                            <TableCell>{row['Fecha Fin de Cursado']}</TableCell>
                                            <TableCell>{row.Calificaciones}</TableCell>
                                            <TableCell>{row['Ctro. Ext.']}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {Rendidas && Rendidas.length > 0 && (
                <>
                    <Titulo>ASIGNATURAS RENDIDAS</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sem</TableCell>
                                    <TableCell>Asignatura</TableCell>
                                    <TableCell>Fecha y hora</TableCell>
                                    <TableCell>Calificación</TableCell>
                                    <TableCell>Condición</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Rendidas.map((row, key) => {
                                    return <Row key={key} row={row} />;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {EquivalenciaPase && EquivalenciaPase.length > 0 && (
                <>
                    <Titulo>Equivalencia o Pase</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sem</TableCell>
                                    <TableCell>Asignatura</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Calificación</TableCell>
                                    <TableCell>Establecimiento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EquivalenciaPase.map((row, key) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={key}>
                                            <TableCell>{row.Semestre}</TableCell>
                                            <TableCell>{row.Asignatura}</TableCell>
                                            <TableCell>{row.Fecha}</TableCell>
                                            <TableCell>{row.Calificacion}</TableCell>
                                            <TableCell>{row.Establecimiento}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            {Libres && Libres.length > 0 && (
                <>
                    <Titulo>Libres</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sem</TableCell>
                                    <TableCell>Asignatura</TableCell>
                                    <TableCell>Fecha Fin de Cursado</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Ctro. Ext.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Libres.map((row, key) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={key}>
                                            <TableCell>{row.Semestre}</TableCell>
                                            <TableCell>{row.Asignatura}</TableCell>
                                            <TableCell>{row['Fecha Fin de Cursado']}</TableCell>
                                            <TableCell>{row.Estado}</TableCell>
                                            <TableCell>{row['Ctro. Ext.']}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {EquivalenciaSinFacturar && EquivalenciaSinFacturar.length > 0 && (
                <>
                    <Titulo>Equivalencia sin facturar</Titulo>

                    <TableContainer className="tablemui-ies">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sem</TableCell>
                                    <TableCell>Asignatura</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Calificación</TableCell>
                                    <TableCell>Establecimiento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EquivalenciaSinFacturar.map((row, key) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={key}>
                                            <TableCell>{row.Semestre}</TableCell>
                                            <TableCell>{row.Asignatura}</TableCell>
                                            <TableCell>{row.Fecha}</TableCell>
                                            <TableCell>{row.Calificacion}</TableCell>
                                            <TableCell>{row.Establecimiento}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </>
    );
}
