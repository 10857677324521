import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import MenuItems from 'components/MenuItems';

const FooterStyled = styled('div')`
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1299;
    background-color: #fff;
    border-top: 1px solid #ccc;
    height: 75px;
    width: 100%;
    justify-content: space-around;
`;

export default function Footer() {
    const isMenuTop = useSelector(state => state.aplication.isMenuTop);

    if (isMenuTop) return null;
    return (
        <FooterStyled>
            <MenuItems />
        </FooterStyled>
    );
}
