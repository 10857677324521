import React, {useState, useContext, useEffect} from 'react';

import HacerPregunta from 'components/tid/preguntas/hacerPregunta';

import preguntasContext from 'context/preguntas/preguntasContext';
import {mostrarAlerta} from 'redux/actions/alertaActions';

import {getWidthPanmat} from 'components/constants';

import './paneles.css';
import VerPregunta from 'components/tid/preguntas/verPregunta';
import Pregunta from 'components/tid/preguntas/pregunta';
import SubLoading from 'components/loading';

import {DivW85} from 'components/styles';

import { useSelector, useDispatch } from 'react-redux';

export default function PanelPreguntas({setOpenPanel, title, idn, setPreguntas, preguntasSize}) {

    const dispatch = useDispatch();

    const dataTid = useSelector((state) => state.dataTid.dataTid);
    const usuario = useSelector(state => state.usuario.usuario);

    const [preguntar, setPreguntar] = useState(false);
    const [preguntaId, setPreguntaId] = useState(false);

    const { obtenerPreguntas, preguntas, total, mensaje, loading, searchQuestions, resetMensaje } = useContext(preguntasContext);

    const data = {
        idn: parseInt(idn),
        TID: dataTid.TID,
        id_materia: dataTid.id_materia,
        id_carrera: dataTid.id_carrera,
        esDocente: usuario.esDocente
    };

    const handleChangeSearch = e => {
        searchQuestions(e.target.value)
    }

    let heightPanelCont = (window.innerHeight - 52) + 'px';

    // Obtener preguntas cuando carga el componente
    useEffect(() => {
        // si hay un error
        if(mensaje) {
            dispatch(mostrarAlerta(mensaje));
            resetMensaje();
        }
        // eslint-disable-next-line
    }, [mensaje]);

    useEffect(() => {
        if(loading) return
        
        if(total !== preguntasSize){
            setPreguntas(total)
            console.log(total)
        }
            
        // eslint-disable-next-line
    }, [setOpenPanel, preguntasSize, total])

    useEffect(() => {
        obtenerPreguntas(data);
        // eslint-disable-next-line
    }, []);

    return(
        <>
        <div id="panelder">
            <div className="headpan">
                <div className="wraperpan">
                    <button className="back" onClick={()=>setOpenPanel(false)}></button> 
                    <div className="panmat" style={{width:getWidthPanmat()}}>Preguntas en "{title}"</div>
                </div>
            </div>            
            <DivW85 className="pandercont panelOverflow mt52 p15" style={{height:heightPanelCont}}>
                {preguntar &&
                    <> 
                      <HacerPregunta isOpen={setPreguntar} idn={idn} dataTid={dataTid} />
                    </>
                }
                {preguntaId !== false && 
                    <>
                        <VerPregunta isOpen={setPreguntaId} preguntaId={preguntaId} usuario={usuario} />
                    </>
                }
                {(!preguntar && preguntaId === false) &&
                <>
                    <div className="fx-c fx-jsb mb15">
                        <div className="question-overview--filter-results-summary--2k-XZ">
                            {!(loading || total < 1) && 
                                <>
                                    {total} {total === 1 ? 
                                        <>pregunta en esta página</> : <>preguntas en esta página</>
                                    }
                                </>
                            }
                        </div>
                        {!dataTid.imModerador && 
                            <button type="button" className="btn btn-sm btn-quintinary" onClick={()=>setPreguntar(true)}>Preguntar</button>
                        }
                    </div>

                    {loading ? <p><SubLoading /> Cargando preguntas...</p> : 
                        <>
                        {total === 0 ? <p>No hay preguntas realizadas.</p>  :                            
                            <>
                            {total > 3 ?
                            <div className="question-search">
                                <input type="text" className="search" onChange={handleChangeSearch} placeholder="Buscá una pregunta" />
                            </div>
                            : null }

                            {total !== preguntas.length ? <p className="mb15">{preguntas.length} resultados de {total}</p> : null}

                            <div className="question-list--questions">
                                {preguntas.map(data => (
                                    <Pregunta 
                                        key={data.id}
                                        usuario={data.usuario}
                                        pregunta={data.pregunta} 
                                        setPreguntaId={setPreguntaId}
                                    />
                                ))}
                                
                            </div>

                            {/* <div className="question-list--load-more"><button type="button" className="question-list--load-more-button btn btn-quaternary">Ver más</button></div> */}
                            </>
                        }
                        </>
                    }
                </>
                }
            </DivW85>
        </div>
        </>
    )
}
