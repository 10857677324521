import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { getRandomString, passwordValidation } from '../components/constants';

import logo from 'img/mi-ies-vertical.svg';
import SubLoading from '../components/loading';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import { useSelector, useDispatch } from 'react-redux';
import { iniciarSesion, changeMode, recuperarPassword } from 'redux/actions/usuarioActions';

import styled from '@emotion/styled';
import ButtonMiES from '../components/ButtonMiES';
import VerifyDialog from 'components/VerifyDialog';

const FormWrapper = styled('div')`
    display: block;
    button {
        color: #00aae4;
        :hover {
            color: #666;
        }
    }
    form {
        max-width: 400px;
        margin: auto;
        padding: 0 20px;
        margin-bottom: 30px;
        .loginLogo {
            margin-bottom: 30px;
            margin-top: 30px;
            height: 120px;
        }
        input {
            width: 100%;
            border: solid 1px #e1e1e1;
            color: #666;
            padding: 0;
            padding: 0 20px;
            text-align: center;
            ::placeholder {
                text-align: center;
            }
        }
        .ajustes {
            padding: 15px 0;
            width: 100%;
            margin-right: 0;
        }
        button.w100 {
            width: 100%;
        }
        button {
            height: 42px;
            margin: auto;
            width: 100%;
            transition: all 0.3s ease-out;
            :hover {
                background-color: #666;
            }
            :disabled {
                background-color: #666;
            }
        }
        button[type='submit'] {
            border: none;
            background-color: #00aae4;
            color: #fff;
        }
        button.btn-secondary {
            margin-top: 10px;
            background-color: #ff6859;
            color: #fff;
        }
        p {
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }
`;

const SelectMod = styled('div')`
    max-width: 400px;
    margin: auto;
    padding: 0 20px;

    p {
        margin-bottom: 30px;
    }
`;

const Container = styled('div')`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
`;

const IOSSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#009ddd',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#009ddd',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function Login({ iesAuth }) {
    let history = useHistory();
    //let location = useLocation();

    const [selectMode, setselectMode] = useState(false);

    const dispatch = useDispatch();

    useTitle('Colegio Universitario IES - Acceso');

    // const [loading, setLoading] = useState(true);
    const loading = useSelector(state => state.usuario.loading);
    const usuario = useSelector(state => state.usuario.usuario);

    const [forgotpass, setforgotpass] = useState(false);
    const [verifyData, setverifyData] = useState(null);

    const onClosePassVerify = () => {
        setverifyData(null);
        setforgotpass(false);
    };

    const namesForm = {
        dni: getRandomString(15),
        dninpass: getRandomString(15),
        pass: getRandomString(15),
        newpass: getRandomString(15),
    };
    const reverseNamesForm = {};
    Object.keys(namesForm).forEach(key => (reverseNamesForm[namesForm[key]] = key));

    const [form, setForm] = useState({ dni: '', pass: '', guardasesion: false });

    //const { from } = location.state || { from: { pathname: '/' } };

    //const isTid = from.pathname.indexOf('/tid') > -1;

    let login = e => {
        e.preventDefault();

        const dni = form.dni.trim().replace(/\.+/g, '');
        const pass = form.pass.trim();

        if (dni.length < 5) {
            dispatch(mostrarAlerta('Escriba su dni', 'Login'));
            return;
        }
        let valid = passwordValidation(pass);
        if (!valid.ok) {
            dispatch(mostrarAlerta(valid.error, 'Login'));
            return;
        }

        if (forgotpass) {
            handleSubmitForgot(e);
            return;
        }

        const data = { dni, pass, guardasesion: form.guardasesion };

        localStorage.setItem('perma', form.guardasesion);

        dispatch(iniciarSesion(data));
    };

    const handleChange = e => {
        if (e.target.name === 'guadasesion') {
            setForm({ ...form, guardasesion: e.target.checked });
        } else {
            const nameField = reverseNamesForm[e.target.name];
            setForm({ ...form, [nameField]: e.target.value });
        }
    };

    useEffect(() => {
        if (Object.keys(usuario).length > 0) {
            iesAuth.authenticate(() => {
                if (usuario.esDocente && usuario.esAlumno && !usuario.rol) {
                    let rol = localStorage.getItem('rolies');
                    if (!rol || rol !== 'docente' || rol !== 'alumno') {
                        //history.push('/app/selectmode');
                        setselectMode(true);
                    } else {
                        history.push('/app');
                    }
                } else {
                    history.push('/app');
                }
                //history.replace(!isTid ? '/app' : from);
            });
        }
    }, [usuario, history, iesAuth]);

    useEffect(() => {
        if (forgotpass) {
            setForm({ dni: '', pass: '', guardasesion: false });
        }
    }, [forgotpass]);

    const handleClickMode = mode => {
        dispatch(changeMode(mode));
        history.push('/app');
    };

    const setError = e => {
        dispatch(mostrarAlerta(e, 'Recupero de contraseña'));
    };

    const handleSubmitForgot = e => {
        e.preventDefault();
        dispatch(recuperarPassword(form, setverifyData, setError));
    };

    return (
        <Container>
            {verifyData !== null && <VerifyDialog type="pass" open={verifyData !== null} onClose={onClosePassVerify} data={verifyData} />}
            {selectMode ? (
                <SelectMod>
                    <p>Selecciona la sesión en donde quieras ingresar</p>
                    <ButtonMiES onClick={() => handleClickMode('docente')}>Sesión Docente</ButtonMiES>
                    <ButtonMiES onClick={() => handleClickMode('alumno')}>Sesión Alumno</ButtonMiES>
                </SelectMod>
            ) : (
                <>
                    <FormWrapper>
                        <form onSubmit={login} autoComplete="off">
                            <div>
                                <img src={logo} alt="" className="loginLogo" />
                                {forgotpass && <h2>¿Olvidaste tu contraseña?</h2>}
                            </div>

                            {forgotpass && <p>Ingresá tu DNI</p>}
                            <input
                                type="text"
                                pattern="[0-9]*"
                                value={form.dni}
                                onChange={handleChange}
                                name={namesForm.dni}
                                placeholder=" DNI "
                                tabIndex="1"
                                autoFocus
                            />

                            {forgotpass && <p>Ingresá tu nueva contraseña</p>}
                            <input
                                type="password"
                                value={form.pass}
                                onChange={handleChange}
                                name={namesForm.pass}
                                placeholder={!forgotpass ? 'Contraseña' : 'Nueva Contraseña'}
                                tabIndex="2"
                            />

                            {!forgotpass && (
                                <FormControlLabel
                                    className="ajustes"
                                    control={<IOSSwitch checked={form.guardasesion} onChange={handleChange} name="guadasesion" />}
                                    label="Guardar mi sesión en este dispositivo"
                                />
                            )}

                            {!forgotpass ? (
                                <button className="w100" type="submit" tabIndex="3" disabled={loading}>
                                    {loading ? <SubLoading /> : <>LOGIN</>}
                                </button>
                            ) : (
                                <button type="submit" tabIndex="6" disabled={loading} className="btn-secondary">
                                    {loading ? <SubLoading /> : <>RESTABLECER CONTRASEÑA</>}
                                </button>
                            )}
                        </form>

                        {!forgotpass ? (
                            <button type="button" onClick={() => setforgotpass(true)} disabled={loading}>
                                ¿Olvidaste tu contraseña?
                            </button>
                        ) : (
                            <button style={{ marginTop: '10px' }} type="button" tabIndex="4" disabled={loading} onClick={() => setforgotpass(false)}>
                                Volver
                            </button>
                        )}
                    </FormWrapper>
                </>
            )}
        </Container>
    );
}
