import { CARGA_APP, SETEAR_LOADING_APP, ERROR_CARGA_APP, CARGA_APP_NUMMENSAJESNUEVOS } from 'redux/types';

import clienteAxios from 'config/axios';

export const getData = () => {
    return async dispatch => {
        dispatch({
            type: SETEAR_LOADING_APP,
            payload: true,
        });
        try {
            const result = await clienteAxios.get('/app_data');

            if (result.data.r === 0) {
                dispatch({
                    type: ERROR_CARGA_APP,
                    payload: {
                        title: 'Error',
                        text: result.data.text,
                    },
                });
                return;
            }
            dispatch({
                type: CARGA_APP,
                payload: result.data,
            });
        } catch (error) {
            dispatch({
                type: ERROR_CARGA_APP,
                payload: {
                    title: 'Error',
                    text: error,
                },
            });
            dispatch({
                type: SETEAR_LOADING_APP,
                payload: false,
            });
        }
    };
};

export const setNumMensajesNuevos = num => {
    return dispatch => {
        dispatch({
            type: CARGA_APP_NUMMENSAJESNUEVOS,
            payload: num,
        });
    };
};

export const getNumMensajesNuevos = (rol, lastID) => {
    return async dispatch => {
        try {
            const result = await clienteAxios.get('/mensajesnuevos', { params: { rol, lastID } });

            if (result.data.r !== 1) {
                dispatch({
                    type: ERROR_CARGA_APP,
                    payload: {
                        title: 'Error',
                        text: result.data.text,
                    },
                });
                return;
            }
            dispatch(setNumMensajesNuevos(result.data.total));
        } catch (error) {
            dispatch({
                type: ERROR_CARGA_APP,
                payload: {
                    title: 'Error',
                    text: error,
                },
            });
        }
    };
};
