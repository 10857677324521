import React from 'react';

import { IS_ANDROID, fromApp } from 'components/constants';

import './footer.css';

import {useSelector} from 'react-redux';

function Footer({ loading, idn, history, getNodoData }) {

    const sesionTid = useSelector((state) => state.dataTid.sesionTid);
    const dataTid = useSelector((state) => state.dataTid.dataTid);
    const realPercentView = useSelector((state) => state.dataTid.realPercentView);

    const nodo_data = getNodoData(idn);

    const iraNodo = (id, n = 0) => {
        if(loading){
            return;
        }
        
        const indice = dataTid.indice;
        let indexArr = 0;

        if( id === 0 ){
            for ( let j in indice ) {
                if(indice[j].id === nodo_data.id){
                    indexArr = j;
                    break;
                }
            }
            if( n < 0 ){
                indexArr--
            }
            if( n > 0 ){
                indexArr++
            }
            if( indexArr < 0 || indexArr > indice.length-1 ){
                return
            }            
            history.push(`/tid/${dataTid.TID}/page/${indice[indexArr].id}`);
        }else{
            if(sesionTid.idn === id){
                //es el mismo id actual
                
            }else{
                
                history.push(`/tid/${dataTid.TID}/page/${id}`);
            }
        }
    }

    return(
        <footer className={IS_ANDROID && fromApp ? `table upfooter` : 'table'}>
            <div className={realPercentView > 99.5 ? 'favance gVerdeBg' : 'favance'} style={{width:`${realPercentView}%`}}></div>
            { (nodo_data && nodo_data.id) ?
            <>
            <div className="cell cw22 borg">
                { dataTid && dataTid.indice[0].id !== nodo_data.id ? 
                <div className={loading ? 'fizq disable' : 'fizq'} onClick={() => iraNodo(0, -1)}></div>
                : null }
            </div>
            <div className="fcen cell borg">
                <div className="fcentx">{nodo_data.t}</div>
            </div>
            <div className="cell cw22 borg">
                { dataTid && dataTid.indice[dataTid.indice.length-1].id !== nodo_data.id ? 
                <div className={loading ? 'fder disable' : 'fder'} onClick={() => iraNodo(0, 1)}></div>
                : null }
            </div>
            </>
            : null }
        </footer>
    )
}

export default React.memo(Footer)