import { SETEAR_USUARIO, ELIMINAR_USUARIO, SETEAR_LOADING, ERROR_CARGA_USUARIO, SETEAR_USUARIO_MODE } from 'redux/types';

import { localVar } from 'components/constants';

const initialState = {
    usuario: {},
    token: null,
    error: null,
    loading: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case SETEAR_LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case ERROR_CARGA_USUARIO:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case SETEAR_USUARIO_MODE:
            const newUser = { ...state.usuario };
            newUser.rol = action.payload;
            return {
                ...state,
                usuario: newUser,
            };
        case SETEAR_USUARIO:
            if (action.payload.token === undefined) return { ...state };
            localVar.setItem('token', action.payload.token);
            return {
                ...state,
                usuario: action.payload.usuario,
                token: action.payload.token,
                loading: false,
                error: null,
            };
        case ELIMINAR_USUARIO:
            return initialState;
        default:
            return state;
    }
}
