import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ImgOk from '../../img/materia-ok.svg';
import styled from '@emotion/styled';

const Container = styled('div')`
    padding: 15px;
    display: flex;
    font-size: 14px;
    h3 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
    div div.MuiCircularProgress-colorPrimary {
        color: ${props => (props.progress <= 99 ? '#4cb684' : '#00aae4')};
    }

    div div.MuiBox-root.complete {
        background-image: url(${props => props.imgok});
        background-position: center;
        background-repeat: no-repeat;
    }
    div div.tidtx {
        :before {
            content: 'TID';
            position: absolute;
            top: 54px;
        }
    }
    .circular1 {
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 1;
    }
`;

export default function ProgresoItem({ progress }) {
    
    return (
        <Container imgok={ImgOk} progress={progress}>
            <Box position="relative" display="inline-flex">
                <CircularProgress size={46} thickness={2} variant="determinate" value={progress} className="circular1" />
                <CircularProgress size={44} thickness={1} variant="determinate" value={100} style={{ color: '#c4c4c4' }} />

                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '46px' }}
                    className={progress > 99 ? 'complete tidtx' : 'tidtx'}
                >
                    {progress <= 99 && (
                        <Typography variant="caption" component="div" color="textSecondary">
                            {progress}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Container>
    );
}
