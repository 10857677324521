import React from 'react';
import { useHistory } from 'react-router-dom';

import { IS_ANDROID, IS_IOS, fromApp } from 'components/constants';

import tidImg from 'img/tid.svg';
import importarImg from 'img/importar.svg';
import acercaImg from 'img/acerca.svg';
import archivoImg from 'img/archivo.svg';
import reporteImg from 'img/reporte.svg';
import ayudaImg from 'img/ayuda.svg';
import consulta_docImg from 'img/consulta_doc.svg';
import ies21 from 'img/logo-ies.svg';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenMenu } from 'redux/actions/aplication';

import { importarSesion } from 'redux/actions/tidActions';

import logoSolo from '../../../img/mi-ies-icon.svg';

import { DivW85, MenuIz, MenuIzTable, MenuIzHead, MenuIzWrap, MenuIzIndice, MenuIzIndiceItem } from 'components/styles';

export default function Menu() {
    let history = useHistory();

    const isTid = history.location.pathname.indexOf('/tid') > -1;

    const usuario = useSelector(state => state.usuario.usuario);
    const dispatch = useDispatch();

    const sesionTid = useSelector(state => state.dataTid.sesionTid);
    const dataTid = useSelector(state => state.dataTid.dataTid);

    const { idn } = sesionTid && isTid ? sesionTid : 0;

    const getVisto = id => {
        if (!sesionTid.avanceNodos['id' + id]) {
            return false;
        } else {
            return true;
        }
    };

    const isActive = path => history.location.pathname.indexOf(path) > -1;

    const importarSesionHandler = () => {
        dispatch(setOpenMenu(false));
        const text = `¿Está seguro que desea sobreescribir la sesión actual por la de <strong>${dataTid.importa_ses_obj.device}</strong>?<br />No podrá recuperar el avance actual.`;
        dispatch(mostrarAlerta(text, 'Importar sesión', () => dispatch(importarSesion())));
    };

    const go = page => {
        dispatch(setOpenMenu(false));
        history.push(`/tid/${dataTid.TID}/${page}`);
    };

    const iraNodo = id => {
        dispatch(setOpenMenu(false));
        history.push(`/tid/${dataTid.TID}/page/${id}`);
    };

    const descargaPDF = () => {
        dispatch(setOpenMenu(false));
        window.open(`${dataTid.url_pdf}`, '_blank');
    };

    const openAyuda = () => {
        dispatch(setOpenMenu(false));
        window.open('https://www.ies21.edu.ar/tid6/ayuda/', '_blank');
    };

    const openApp = () => {
        dispatch(setOpenMenu(false));
        if (IS_IOS && fromApp) {
            window.location = 'comies21util://open?page=materias';
            setTimeout(function () {
                if (!document.webkitHidden) {
                    window.location = 'http://itunes.apple.com/app/id1262103263';
                }
            }, 25);
        } else if (IS_ANDROID && fromApp) {
            window.location = 'intent://com.ies21.util/#Intent;scheme=launch;package=com.ies21.util;S.content=materias;end';
        } else {
            history.push('/app');
        }
    };

    if (!Object.keys(usuario).length > 0) return null;

    return (
        <MenuIz>
            <MenuIzWrap>
                <MenuIzHead onClick={() => dispatch(setOpenMenu(false))}>
                    <img src={isTid ? tidImg : ies21} alt="" />
                    <br />
                    <p>
                        {isTid ? (
                            <>
                                {dataTid.materia}
                                <br />
                            </>
                        ) : null}

                        <strong>
                            {usuario.nombres} {usuario.apellidos}
                        </strong>
                    </p>
                </MenuIzHead>

                <DivW85>
                    <MenuIzTable>
                        <tbody>
                            {isTid && (
                                <>
                                    <tr onClick={openApp} className={history.location.pathname === '/app' ? 'bo active' : 'bo'}>
                                        <td>
                                            <img src={logoSolo} width="18" alt="mi ies" />
                                        </td>
                                        <td>Volver a Mi IES</td>
                                    </tr>
                                    {dataTid.importa_ses_obj.id ? (
                                        <tr onClick={importarSesionHandler} className="bo">
                                            <td>
                                                <img src={importarImg} alt="" />
                                            </td>
                                            <td>
                                                Importar avance
                                                <br />
                                                <span className="f10">
                                                    Última modificación: {dataTid.importa_ses_obj.fecha} de {dataTid.importa_ses_obj.device}
                                                </span>
                                            </td>
                                        </tr>
                                    ) : null}
                                    <tr onClick={() => go('editorial')} className={isActive('editorial') ? 'bo active' : 'bo'}>
                                        <td>
                                            <img src={acercaImg} alt="" />
                                        </td>
                                        <td>Editorial</td>
                                    </tr>
                                    <tr className="nocursor">
                                        <td colSpan="2" className="sim timen">
                                            <strong>CONTENIDO</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="sim nopad">
                                            <DivW85 className="pandercont">
                                                <MenuIzIndice>
                                                    {dataTid.indice.map(({ id, t, n }, index) => {
                                                        return (
                                                            <MenuIzIndiceItem
                                                                styleParams={{ activo: id === idn, visto: getVisto(id), n }}
                                                                key={index}
                                                                onClick={() => iraNodo(id)}
                                                            >
                                                                {t}
                                                            </MenuIzIndiceItem>
                                                        );
                                                    })}
                                                </MenuIzIndice>
                                            </DivW85>
                                        </td>
                                    </tr>

                                    {dataTid.url_pdf !== '' && (
                                        <tr onClick={descargaPDF} className="bo">
                                            <td>
                                                <img src={archivoImg} alt="" />
                                            </td>
                                            <td>Descargar PDF</td>
                                        </tr>
                                    )}

                                    <tr onClick={() => go('errores')} className={isActive('errores') ? 'bo active' : 'bo'}>
                                        <td>
                                            <img src={reporteImg} alt="" />
                                        </td>
                                        <td>Reportar problema</td>
                                    </tr>

                                    {dataTid.consulta_doc && (
                                        <tr onClick={() => go('consulta')} className={isActive('consulta') ? 'bo active' : 'bo'}>
                                            <td>
                                                <img src={consulta_docImg} alt="" />
                                            </td>
                                            <td>Consulta al tutor</td>
                                        </tr>
                                    )}
                                </>
                            )}
                            <tr onClick={openAyuda} className="bo">
                                <td>
                                    <img src={ayudaImg} alt="" />
                                </td>
                                <td>Ayuda</td>
                            </tr>
                        </tbody>
                    </MenuIzTable>
                </DivW85>
            </MenuIzWrap>
        </MenuIz>
    );
}
