import React from 'react';
import styled from '@emotion/styled';
import Titulo from 'components/TituloH2';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    margin-bottom: 40px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    padding: 15px;
`;

const Items = styled('div')`
    display: flex;
    justify-content: space-between;
    margin: 15px;
    div {
        width: 100%;
        height: 10px;
        margin-left: 3px;
    }
    div:first-of-type {
        margin-left: 0;
    }
`;

const Falta = styled('div')`
    background-color: ${props => props.bg};
`;
const Data = styled('div')`
    text-align: right;
`;

export default function InasistenciasBox({ data }) {
    /*data.permitidas = 12;
    data.usadas = 3;*/

    if (!data || data.length < 1 || !data.permitidas || data.permitidas === 0) return null;

    let permitidas = [];
    for (let index = 0; index < data.permitidas; index++) {
        permitidas.push(index);
    }

    let colorItems = data.permitidas - data.usadas < 2 ? '#d42a2a' : '#00aae4';
    return (
        <div>
            <Titulo>INASISTENCIAS</Titulo>
            <Container>
                <Items>
                    {permitidas.map((item, key) => {
                        let isColored = key < data.usadas;
                        return <Falta bg={isColored ? colorItems : '#e5e5e5'} key={key}></Falta>;
                    })}
                </Items>
                <Data>
                    {data.usadas} de {data.permitidas}
                </Data>
            </Container>
        </div>
    );
}
