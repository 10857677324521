import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import Header from '../../components/tid/header/header';

import { useSelector, useDispatch } from 'react-redux';
import { cargarTid } from 'redux/actions/tidActions';

export default function Editorial() {
    const { TID } = useParams();

    const dispatch = useDispatch();

    const dataTid = useSelector(state => state.dataTid.dataTid);
    const usuario = useSelector(state => state.usuario.usuario);

    useTitle(!dataTid ? 'Colegio Universitario IES' : 'IES - Editorial - ' + dataTid.materia);

    const [loading, setLoading] = useState(true);

    const paginaRef = useRef();

    useEffect(() => {
        if (dataTid) {
            let htmlNode = '';

            const intMed = Object.keys(dataTid.medias);

            intMed.forEach(key => {
                const media = dataTid.medias[key];
                if (media.inst && media.inst === 1) {
                    if (media.m === 20) {
                        htmlNode += `${media.incod}<hr>`;
                    } else if (media.m === 1) {
                        htmlNode += `<img src="${dataTid.url_tid}${media.src}" class="im"><hr />`;
                    } else if (media.m === 0) {
                        htmlNode += `<a href="${media.href}">${media.txt}</a><hr />`;
                    } else if (media.m === 2) {
                        htmlNode += `<p>${media.t}</p><hr />`;
                    }
                }
            });

            if (htmlNode === '') {
                htmlNode = '<p>Sin información editorial.</p>';
            }

            paginaRef.current.innerHTML = htmlNode;

            window.scrollTo(0, 0);

            setLoading(false);
        }
        // eslint-disable-next-line
    }, [dataTid]);

    useEffect(() => {
        if (TID) {
            //setTID(TID);
            dispatch(cargarTid(TID, usuario.rol));
            // eslint-disable-next-line
        }
    }, [TID, dispatch, usuario]);

    /*
    useEffect(() => { 
        setTID(parseInt(TID));
        // eslint-disable-next-line
    }, []);  
*/
    return (
        <React.Fragment>
            <Header
                titulo={!loading ? 'Editorial: ' + dataTid.materia : 'Cargando datos...'}
                avatarData={undefined}
                utilesVisible={false}
                paginaRef={undefined}
                loading={loading}
                imagesLoaded={false}
                isTid={true}
                showPreguntasBtn={false}
                showPreguntas={undefined}
                openPreguntas={false}
                showUtilsBtn={false}
                showUtils={undefined}
                openUtils={false}
                sendAction={undefined}
            />

            <div className="section institucionales">
                {dataTid ? <h1>{dataTid.materia}</h1> : null}
                <div ref={paginaRef}></div>
            </div>
        </React.Fragment>
    );
}
