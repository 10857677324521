import React from 'react';
import styled from '@emotion/styled';
import Titulo from './TituloH2';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    margin-bottom: 40px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    padding: 5px 15px;
`;

export default function LayoutBox({ children, title }) {
    return (
        <div>
            <Titulo>{title}</Titulo>
            <Container>{children}</Container>
        </div>
    );
}
