import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useSwipeable } from 'react-swipeable';
import { makeStyles } from '@material-ui/core/styles';
import {IS_MOBILE, winSize, getOffsetTop} from 'components/constants';
import './scroll.css';

import {useSelector, useDispatch} from 'react-redux';
import {setAvance} from 'redux/actions/tidActions';

export default function Scroll({ paginaRef, idn }) {

    const sesionTid = useSelector((state) => state.dataTid.sesionTid);
    const dataTid = useSelector((state) => state.dataTid.dataTid);
    const dispatch = useDispatch();

    const nodo_data = dataTid ? dataTid.indice.filter( obj => obj.id === idn )[0] : {id:null, t:null};

    const [opaShad, setOpaShad ] = useState(0);

    const useStyles = makeStyles({
        root: {
          boxShadow: `-20px 0 20px 0 rgba(0, 0, 0, ${opaShad})`
        },
    });

    const classes = useStyles();

    const scrollRef = useRef(null);
    const scrollingRef = useRef(null);
    const tooltipRef = useRef(null);
    const scrollavanceRef = useRef(null);

    const [scroll, setScroll] = useState([]);
    const [percbar, setPercbar] = useState(0);

    const percentageSeen = useCallback( () => {
        if(!paginaRef || !paginaRef.current){
            return 100;
        }
        const sc = paginaRef.current,
            viewportHeight = window.screen.height,
            scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop,
            elementOffsetTop = 0,
            elementHeight = sc.offsetHeight-viewportHeight;
        
        if (elementOffsetTop > (scrollTop + viewportHeight)) {
            return 0;
        } else if ((elementOffsetTop + elementHeight) < scrollTop) {
            return 100;
        } else {
            const distance = (scrollTop + viewportHeight) - elementOffsetTop; 
            const percentage = distance / ((viewportHeight + elementHeight) / 100);

            return Math.round(percentage);
        }
    }, [paginaRef]);

    const setAvancePer = useCallback(() => {
        if(!scrollavanceRef || !scrollavanceRef.current) return;

        const p = percentageSeen();
        scrollavanceRef.current.style.height = `${p}%`;
        setPercbar(p);
        const dataAvance = sesionTid.avanceNodos;
        if(p >= 90){
            if(dataAvance[`id${idn}`] !== nodo_data.p && idn === nodo_data.id){
                dataAvance[`id${idn}`] = nodo_data.p;
                dispatch(setAvance(dataAvance));
            }
        }
    }, [idn, nodo_data.id, nodo_data.p, percentageSeen, sesionTid.avanceNodos, dispatch]);

    const handleMouseLeave = e => {
        if(IS_MOBILE){
            return;
        }
        tooltipRef.current.style.display = "none";
    }

    const handleMouseMove = e => {
        if(IS_MOBILE){
            return;
        }
        let posY = e.clientY;
        tooltipRef.current.style.top = posY + "px";
    }

    const handleMouseOver = e => {
        if(IS_MOBILE || e.target.innerText === ''){
            return;
        }        
        tooltipRef.current.innerHTML = e.target.innerText;
        tooltipRef.current.style.top = "50px";
        tooltipRef.current.style.right = "18px";

        let posY = e.clientY;

        tooltipRef.current.style.top = posY+"px";
        tooltipRef.current.style.display = "block";
    }

    /// scroll event
    //const [scrollEventAdded, setscrollEventAdded ] = useState(false);
    const handleScroll = () => {
        // console.log(window.onscroll !== null) 
        if(!idn || !dataTid) return;
        setAvancePer();
    };
    const addScrollEvent = () => {
        if(!window.onscroll)
            window.onscroll = handleScroll;
        //window.addEventListener('scroll', handleScroll, false);
    }
    /// end scroll event
      
    const scrollearA = id => {
        let elem = document.getElementById(id);

        //elem.scrollIntoView({behavior: 'smooth'})
        
        let top = getOffsetTop(elem) - 52 - 13;
        window.scrollTo({
            top: top,
            left: 0,
            behavior: 'smooth',
        });
        
        setRpanel(100);
        setOpaShad(0);
    };

    const getScroll = useCallback(() => {
        const elements = paginaRef.current.querySelectorAll('.th,.ti,.st,.finh');

        let numid = 0;
        let minimo = 0;
        let maximo = 100;
        let items_scr = [];
        let totaper = 0;
        let percent;
        let percent_ant = 0;

        elements.forEach(element => {
            let txt = element.textContent;
            if(txt !== '' || element.className === "finh"){
                numid++;
                let classe = `i${element.className}`;
                let dist_top = getOffsetTop(element);
                element.id = `ss${numid}`;
                if( dist_top < minimo || minimo === 0 ){
                    minimo = dist_top;
                }
                items_scr.push([numid, dist_top - minimo, txt, classe]);
            }
        });

        minimo = items_scr.length > 0 ? items_scr[0][1] : 0;
        maximo = items_scr.length > 0 ? items_scr[items_scr.length-1][1] : 0;

        const itemsScroll = [];

        for(let i = 0; i < items_scr.length; i++){
            let percent_tmp = (items_scr[i][1] * 100) / maximo;
            let itemPage = `ss${items_scr[i][0]}`;
            let itemClass = items_scr[i][3];
            let itemText = items_scr[i][2];

            percent = percent_tmp - percent_ant;
            percent_ant = percent_tmp;
            percent = Math.round( percent * 10 ) / 10;
            if(isNaN(percent)){
                percent = 0;
            }
            totaper += percent;
            percent = (i === 0 && percent === 0) ? 1 :percent;
            totaper = totaper > 100 ? 100 : totaper;
            itemsScroll.push({totaper, percent, itemPage, itemClass, itemText, itemKey:i});
        };
        return itemsScroll;
    }, [paginaRef]);

      

    const [rPanel, setRpanel] = useState(100);
    const [lastrPanel, setlastRpanel] = useState(100);

    const stylePanel = {
        right: `-${rPanel}%`
    }

    const limitSwipePerc = winSize().width > 640 ? 50 : 10;

    const handlers = useSwipeable({
        onSwipedLeft: e => swipingLeft(e),
        onSwipedRight: e => swipingRight(e),
        onSwiped: e => swiped(e),
        onSwiping: e => swiping(e),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const swiping = e => {
        if(!IS_MOBILE) return;
        let percent = (e.deltaX / winSize().width) * 100;
        percent = parseInt(lastrPanel-percent);
        percent = percent < limitSwipePerc ? limitSwipePerc : percent;
        percent = percent >= 100 ? 100 : percent;
        let percOp = (e.deltaX * 100) / 15;
        percOp = percOp > 50 ? 50 : percOp;
        percOp = Math.ceil(percOp) / 100;
        if(percOp !== opaShad){
            setOpaShad(percOp);
        }
        setRpanel(percent);
    }
    
    const swipingLeft = e => {
        if(!IS_MOBILE) return;
        setRpanel(limitSwipePerc);
    }
    const swipingRight = e => {
        if(!IS_MOBILE) return;
        setRpanel(100);
        setOpaShad(0);
    }    
    const swiped = e => {
        if(!IS_MOBILE) return;
        setlastRpanel(rPanel);
    }


    useEffect(() => {  
        if(scroll.length > 0){
            const hwind = window.screen.height;
            const percent_tot = 100-( (52 + 52) * 100) / hwind;
            const elements = scrollRef.current.querySelectorAll('.trow');
            const itemsSize = scroll.length;
            const scrolllin = scrollingRef.current;
            const vscroll = scrollRef.current;
            vscroll.style.height = percent_tot+"%";

            if( itemsSize <= 2 || hwind >= paginaRef.current.offsetHeight ){
                vscroll.style.visibility = "hidden";
            }else{
                vscroll.style.visibility = "visible";
            }

            scrolllin.style.height = (getOffsetTop(elements[elements.length-1])-getOffsetTop(elements[0])+elements[elements.length-1].offsetHeight-20)+"px";
            scrolllin.style.top = (getOffsetTop(elements[0])-52+13)+"px";
        }
        // eslint-disable-next-line
    }, [scroll])

    useEffect(() => {  
        if(paginaRef.current){
            scrollRef.current.style.visibility = "hidden";
            const sc = getScroll();
            setScroll(sc);
            handleScroll();
            window.onscroll = null;
            addScrollEvent();
        }
        // eslint-disable-next-line
    }, [paginaRef])

    /*
    // primera vez
    useEffect(() => {  
        if(scroll.length === 0){
            const sc = getScroll();
            setScroll(sc);
            handleScroll();
        }
        // eslint-disable-next-line
    }, [])
*/
    return (
        <React.Fragment>
            <div ref={tooltipRef} className="tooltip"></div>
            <div {...handlers}>
                <div ref={scrollRef} className="vscroll normal_panelcont" style={stylePanel}>
                    <div className="wrapScroll">
                        { scroll.map( ({totaper, percent, itemPage, itemClass, itemText, itemKey}) => {
                                return (
                                    <div key={itemKey} className="trow" onClick={() => scrollearA(itemPage)}>
                                        <span onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} onMouseOver={handleMouseOver} className={totaper <= percbar ? `${itemClass} ${itemClass}c` : itemClass} style={{height:`${percent}%`}}>{itemText}</span>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div ref={scrollingRef} className="scrolllin">
                        <div ref={scrollavanceRef} className="scroll_avance"></div>
                        <div className="scroll_bg"></div>
                    </div>
                    <div className={`bgapple ${classes.root}`}></div>
                </div>  
            </div>
        </React.Fragment>
    );
}