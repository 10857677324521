import React, {useRef} from 'react';

import Dialog from '@material-ui/core/Dialog';

export default function LinkWindow(props) {
    const {open, onClose, selTxt, selUrl, savedSelection, restoreSelection} = props;

    const hrefRef = useRef();
    const textRef = useRef();

    const okAction = () => {

        const _hrefRef = hrefRef.current.value.trim();
        const _textRef = textRef.current.value.trim() !== '' ? textRef.current.value.trim() : _hrefRef;

        const html = _hrefRef!== '' ? `<a href="${_hrefRef}" target="_blank" rel="noopener noreferrer">${_textRef}</a>` : _textRef;

        const restored = restoreSelection(savedSelection);
        if(restored){
            document.execCommand('insertHTML', false, html);
        }
        
        onClose();
    }

    return(
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <div className="dilogContent">
            <h2 id="responsive-dialog-title">{selUrl === '' ? <>Insertar</> : <>Editar</>} enlace</h2>
            
                <div className="form-group">
                    <label htmlFor="href" className="control-label">URL</label>
                    <input type="text" ref={hrefRef} required name="href" id="href" className="form-control" autoFocus defaultValue={selUrl}/>
                </div>

                <div className="form-group">
                    <label htmlFor="text" className="control-label">Texto</label>
                    <input type="text" ref={textRef} name="text" id="text" className="form-control" defaultValue={selTxt}/>
                </div>

                <div className="DialogActions">
                    <button onClick={onClose} className="btn btn-default">
                        Cancelar
                    </button>
                    <button onClick={okAction} className="btn btn-primary">
                        Insertar
                    </button>
                </div>
                    
            </div>
        </Dialog>
    )
}