import React, {useRef} from 'react';

import './note.css';
import Modal from '@material-ui/core/Modal';

import imgclose from 'img/close.svg'
import imgEliminar from 'img/Eliminar.svg'
import imgGuardar from 'img/Guardar.svg'


export default function Note({title, txt, closeNota, eliminaNota, guardarNota, open}) {

    const ref = useRef('');

    return(
        <Modal
            open={open}
            onClose={closeNota}
        >
            
            <div className="bnota">
                <div className="notah">
                    {title} - Nota <button onClick={closeNota} className="notac"><img src={imgclose} width="10" height="10" alt="cerrar" /></button>
                </div>

                <div className="notab">
                    <div ref={ref} className="notabs h400" contentEditable="true" suppressContentEditableWarning={true} autoFocus>{txt}</div>
                </div>
                <div className="notaf">
                    <button onClick={eliminaNota} className="notae">
                        <div className="contbot"><img src={imgEliminar} className="notaBtn" alt="Eliminar" /> Eliminar</div>
                    </button>
                    <button onClick={() => guardarNota(ref.current.innerText)} className="notag">
                        <div className="contbot"><img src={imgGuardar} className="notaBtn" alt="Guardar" /> Guardar</div>
                    </button>
                </div>
            </div>
                
        </Modal>
    )
}