import React from 'react';
import styled from '@emotion/styled';
import SubLoading from 'components/loading';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import backIcon from '../../img/back.svg';
import logo from '../../img/mi-ies-logo.svg';
import logoSolo from '../../img/mi-ies-icon.svg';
import MessageButton from './MessageButton';
import MenuItems from 'components/MenuItems';

const HeaderStyled = styled('div')`
    background-color: #fff;
    width: 100%;
    position: fixed;
    height: ${props => props.h};
    top: 0;
    z-index: 1300;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    color: #323232;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    justify-content: space-between;

    .flex-center {
        display: flex;
        align-items: center;
    }

    h1 {
        display: flex;
        align-items: center;
        color: #323232;
        text-align: left;
        font-weight: bold;
        margin: 0;
        height: ${props => props.h};
        line-height: ${props => props.h};
        font-size: 25px;
        margin-right: 15px;
        margin-left: 15px;

        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
    }

    h2 {
        display: flex;
        align-items: baseline;
        color: #323232;
        text-align: left;
        margin: 0;
        height: ${props => props.h};
        line-height: ${props => props.h};
        font-size: 24px;
        font-weight: 900;
        margin-right: 15px;
        margin-left: 15px;

        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
        img {
            margin-right: 10px;
        }
    }

    h3 {
        display: flex;
        align-items: baseline;
        color: #323232;
        text-align: left;
        margin: 0;
        height: ${props => props.h};
        line-height: ${props => props.h};
        font-size: 20px;
        font-weight: 700;
        margin-right: 15px;

        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
        img {
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 520px) {
        h1 {
            word-break: break-all;
            font-size: 20px;
        }
        h2 {
            word-break: break-all;
            font-size: 18px;
        }
        h3 {
            word-break: break-all;
            font-size: 16px;
        }
    }
`;

const ContainerMenu = styled('div')`
    display: flex;
    width: 364px;
    flex-direction: row;
    margin-right: 5px;
`;

const BackButton = styled('button')`
    background-image: url(${props => props.bgback});
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: ${props => props.h};
`;

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export default function Header() {
    const loading1 = useSelector(state => state.usuario.loading);
    const loading2 = useSelector(state => state.aplication.loading);
    const loading3 = useSelector(state => state.homeData.loading);
    const titulo = useSelector(state => state.aplication.titulo);
    const isMenuTop = useSelector(state => state.aplication.isMenuTop);

    const location = useLocation();
    const history = useHistory();

    let loading = loading1 || loading2 || loading3;

    let primaryContent = location.pathname.split('/').length < 4;
    primaryContent = location.pathname.indexOf('app/carrera') > -1 || primaryContent;
    let intoMateria = location.pathname.indexOf('app/materia') !== -1;
    primaryContent = intoMateria ? false : primaryContent;

    let h = isMenuTop ? '72px' : '52px';

    return (
        <HeaderStyled h={h}>
            <div className="flex-center" style={{ width: isMenuTop ? getWidth() - 394 + 'px' : getWidth() - 93 + 'px' }}>
                {!primaryContent && <BackButton h={h} bgback={backIcon} type="button" disabled={loading} onClick={() => history.goBack()}></BackButton>}
                {!titulo ? (
                    <h1>
                        <img src={logo} alt="mi IES" />
                    </h1>
                ) : (
                    <>
                        {primaryContent ? (
                            <h2>
                                <img src={logoSolo} alt="mi IES" /> {titulo}
                            </h2>
                        ) : (
                            <h3>
                                {getWidth() > 480 && <img src={logoSolo} alt="mi IES" />}
                                {titulo}
                            </h3>
                        )}
                    </>
                )}

                {loading && (
                    <>
                        <SubLoading />
                    </>
                )}
            </div>
            {isMenuTop ? (
                <ContainerMenu>
                    <MenuItems isMenuTop={isMenuTop} />
                    <MessageButton isMenuTop={isMenuTop} />
                </ContainerMenu>
            ) : (
                <MessageButton isMenuTop={isMenuTop} />
            )}
        </HeaderStyled>
    );
}
