import React, {useRef, useEffect, useCallback} from 'react';

import {getOffsetTop} from 'components/constants';

import Slide from '@material-ui/core/Slide';

import {HeaderInputSearch, HeaderSearchBtn} from 'components/styles';

const searchWord = {
    findAndReplaceDOMText: window.findAndReplaceDOMText,
    instance: null,
};

const Search = (props) => {
    const {utilesVisible, paginaRef, loading, imagesLoaded, visibleSearch, setvisibleSearch} = props;

    const refSearch = useRef();
    const htmlPageMemo = useRef();

    const searchText = useCallback(
        (txt) => {
            if (loading) return;

            searchWord.instance && searchWord.instance.revert();

            if (txt.length > 0) {
                searchWord.instance = searchWord.findAndReplaceDOMText(document.getElementById('sc'), {
                    find: txt,
                    wrap: 'span',
                    wrapClass: 'resultSearch',
                });

                console.log('Results search: ' + searchWord.instance.reverts.length);

                if (document.getElementsByClassName('resultSearch')[0] !== undefined) {
                    const elem = document.getElementsByClassName('resultSearch')[0];
                    window.scrollTo({
                        top: getOffsetTop(elem) - 52 - 13,
                        behavior: 'smooth',
                    });
                }
            }
        },
        [loading]
    );

    useEffect(() => {
        if (loading || paginaRef === undefined) return;

        if (visibleSearch) {
            refSearch.current.focus();
        }
        // eslint-disable-next-line
    }, [refSearch, loading, visibleSearch]);

    useEffect(() => {
        if (!imagesLoaded || loading) {
            setvisibleSearch(false);
        }
    }, [imagesLoaded, loading, setvisibleSearch]);

    useEffect(() => {
        if (utilesVisible && visibleSearch) {
            searchWord.instance && searchWord.instance.revert();
            setvisibleSearch(false);
        }
    }, [utilesVisible, visibleSearch, setvisibleSearch]);

    const handlerSearch = (open) => {
        if (!open) {
            searchText('');
        } else {
            htmlPageMemo.current = paginaRef.current.innerHTML;
        }
        setvisibleSearch(open);
    };

    return (
        <React.Fragment>
            <Slide direction="left" in={visibleSearch} mountOnEnter unmountOnExit>
                <HeaderInputSearch
                    type="text"
                    ref={refSearch}
                    onChange={(e) => searchText(e.target.value)}
                    placeholder="Buscá en esta página"
                />
            </Slide>
            <HeaderSearchBtn
                uv={utilesVisible}
                vs={visibleSearch}
                onClick={() => handlerSearch(!visibleSearch)}
            ></HeaderSearchBtn>
        </React.Fragment>
    );
};
export default React.memo(Search, (prevProps, nextProps) => {});
