import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import clienteAxios from 'config/axios';
import Header from '../../components/tid/header/header';

import { mostrarAlerta } from '../../redux/actions/alertaActions';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenMenu } from '../../redux/actions/aplication';
import { cargarTid } from '../../redux/actions/tidActions';

export default function Consulta() {
    const dispatch = useDispatch();

    let history = useHistory();

    const { TID } = useParams();

    const usuario = useSelector(state => state.usuario.usuario);

    const dataTid = useSelector(state => state.dataTid.dataTid);
    const sesionTid = useSelector(state => state.dataTid.sesionTid);

    useTitle(!dataTid ? 'Colegio Universitario IES' : 'IES - Consulta tutor - ' + dataTid.materia);

    const mensajeRef = useRef();

    const [loading, setLoading] = useState(true);
    const [dataTutor, setdataTutor] = useState({});

    const iraNodo = id => {
        history.push(`/tid/${TID}/page/${id}`);
        dispatch(setOpenMenu(false));
    };

    const sendAction = async () => {
        const mensaje = mensajeRef.current.value.trim();

        if (mensaje.length < 10) {
            dispatch(mostrarAlerta('Mensaje demasiado corto.', 'Enviar consulta al tutor'));
            return;
        }

        const data = {
            email: usuario.email,
            nombre: `${usuario.apellidos}, ${usuario.nombres}`,
            TID: parseInt(TID),
            id_carrera: dataTid.id_carrera,
            id_materia: dataTid.id_materia,
            mensaje,
            idn: sesionTid.idn,
        };

        setLoading(true);

        try {
            const result = await clienteAxios.post('/consulta_tutor', data);
            dispatch(mostrarAlerta(result.data.text, 'Consulta'));
        } catch (error) {
            dispatch(mostrarAlerta(error, 'Consulta'));
        }

        setLoading(false);
    };

    useEffect(() => {
        if (dataTid && Object.keys(usuario).length > 0) {
            if (!dataTid.consulta_doc) {
                iraNodo(sesionTid.idn);
            }

            const fetchTutor = async () => {
                setLoading(true);

                const data = { TID: parseInt(TID) };

                try {
                    const result = await clienteAxios.get('/get_datos', { params: data });

                    if (result.data.r === 0) {
                        dispatch(mostrarAlerta(result.data.text, 'Consulta'));
                        setLoading(false);
                        return;
                    }

                    const { nombres, apellidos, email } = result.data;

                    setdataTutor({ nombres, apellidos, email });

                    if (email === '') {
                        dispatch(mostrarAlerta(`No hay tutor asignado a <strong>${dataTid.materia}</strong> para consultas.`, 'Consulta'));
                    }
                } catch (error) {
                    dispatch(mostrarAlerta(error, 'Consulta'));
                }
                setLoading(false);
            };
            fetchTutor();
        }
        // eslint-disable-next-line
    }, [dataTid]);

    useEffect(() => {
        if (TID) {
            //setTID(TID);
            dispatch(cargarTid(TID, usuario.rol));
            // eslint-disable-next-line
        }
    }, [TID, dispatch, usuario]);

    /*useEffect(() => { 
        setTID(parseInt(TID));
        // eslint-disable-next-line
    }, []); 
    */
    return (
        <>
            <Header
                titulo={!loading ? 'Mensaje al Docente' : 'Cargando datos...'}
                avatarData={undefined}
                utilesVisible={false}
                paginaRef={undefined}
                loading={loading}
                imagesLoaded={false}
                isTid={false}
                showPreguntasBtn={false}
                showPreguntas={undefined}
                openPreguntas={false}
                showUtilsBtn={false}
                showUtils={undefined}
                openUtils={false}
                sendAction={sendAction}
            />

            {!loading && (
                <div className="section media flex nosombra pl0 pr0 absPos">
                    {dataTutor && dataTutor.email !== '' ? (
                        <>
                            <div className="contp f16">
                                <strong>De:</strong> {usuario.email}
                            </div>
                            <div className="contp f16">
                                <strong>Para:</strong> {dataTutor.nombres} {dataTutor.apellidos}
                            </div>
                            <div className="contp bgmgris" onClick={() => mensajeRef.current.focus()}>
                                Explicá brevemente si tenés alguna duda con los conceptos en el TID de {dataTid.materia}.
                            </div>
                            <textarea className="txfull" autoFocus ref={mensajeRef}></textarea>
                        </>
                    ) : (
                        <div className="contp f16">No hay datos de tutor asignado a {dataTid.materia}.</div>
                    )}
                </div>
            )}
        </>
    );
}
