import {CARGA_TID, IMPORTAR_SESION, GUARDAR_SESION, ACTUALIZAR_AVANCE, SETEAR_LOADING_TID, ERROR_CARGA_TID} from 'redux/types';

const initialState = {
    dataTid: null,
    sesionTid: null,
    realPercentView: 0,
    loading: false,
    error: null
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case SETEAR_LOADING_TID:
            return{
                ...state,
                loading: action.payload
            }
        case ERROR_CARGA_TID:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case CARGA_TID:
            return {
                ...state,
                dataTid: action.payload.dataTid,
                sesionTid: action.payload.sesionTid,
                loading: false,
                error: null
            }
        case ACTUALIZAR_AVANCE:
            return {
                ...state,
                realPercentView: action.payload,
                error: null
            }
        case GUARDAR_SESION:
            console.log(action.payload)
            return {
                ...state,
                sesionTid: action.payload,
                error: null
            }
        case IMPORTAR_SESION:
            const newDtid = {...state.dataTid, importa_ses_obj:{}}
            return {
                ...state,
                sesionTid: action.payload,
                dataTid: newDtid,
                error: null
            }
        default:
            return state;
    }
}
