import React, { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import clienteAxios from 'config/axios';
import { mostrarAlerta } from 'redux/actions/alertaActions';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import Skeleton from 'react-loading-skeleton';

import styled from '@emotion/styled';

const Container = styled('div')`
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TramiteItem = styled('a')`
    display: block;
    padding:12px;
    font-size: 16px;
    width: 100%;
`

export default function Tramites() {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);
    const carreras = useSelector(state => state.homeData.carreras);
    const [tramites, setTramites] = useState([]);

    useTitle('mi IES - trámites');

    useEffect(() => {
        dispatch(setTitulo('Trámites'));
    }, [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));

            let id_carreras = [];
            if (usuario.rol === 'alumno') {
                carreras.alumno.forEach(element => {
                    id_carreras.push(element.id);
                });
            } else {
                carreras.docente.forEach(element => {
                    id_carreras.push(element.id);
                });
            }

            let result;

            try {
                let url = usuario.rol === 'alumno' ? 'tramites' : 'tramites-docente';
                result = await clienteAxios.get(`/${url}`, { params: { id_carreras: id_carreras.join(',') } });

                if (result.data.r === 0) {
                    dispatch(mostrarAlerta(result.data.text, 'Error'));
                    return false;
                }

                setTramites(result.data.tramites);
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (carreras !== null && carreras.alumno) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [carreras]);

    if (!Object.keys(usuario).length > 0) return null;

    return (
        <>
            {loading ? (
                <>
                    <div style={{ padding: '15px', margin: 'auto' }}>
                        <Skeleton height={46} />
                        <Skeleton height={46} />
                        <Skeleton height={46} />
                        <Skeleton height={46} />
                        <Skeleton height={46} />
                    </div>
                </>
            ) : (
                <Container>
                    {tramites.map((tramite, key) => {
                        return (
                            <TramiteItem key={key} href={tramite.url} target="_blank" rel="noreferrer">
                                {tramite.txt}
                            </TramiteItem>
                        );
                    })}
                </Container>
            )}
        </>
    );
}
