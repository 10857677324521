import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const DownIcon = props => {
    // 576
    return (
        <SvgIcon viewBox="0 0 26 26" focusable="false" role="img" aria-hidden="true" {...props}>
            <path fill="none" d="M0 0h25.022v25.022H0z" />
            <path fill="#323232" d="M16.507 13.424h-3.126V3H11.3v10.424H8.169l4.169 4.17zM4 19.678v2.085h16.677v-2.085z" transform="translate(.173 .129)" />
        </SvgIcon>
    );
};

