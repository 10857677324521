import React from 'react';
import imgLoader from 'img/loader.svg';

export default function SubLoading() {

    return(
        <span className="subloading">
            <img src={imgLoader} width="20" alt="loading" />
        </span>
    );
}