import React, { useState, useContext, useEffect } from 'react';
import Editor from 'components/editor/editor';
import SubLoading from 'components/loading';

import preguntasContext from 'context/preguntas/preguntasContext';
import { mostrarAlerta } from 'redux/actions/alertaActions';

import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';

export default function HacerPregunta({ isOpen, dataTid, idn }) {
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const { success, errorformulario, agregarPregunta, mostrarError, mensaje, resetMensaje, resetForm } = useContext(preguntasContext);

    const [loading, setLoading] = useState(false);

    const [pregunta, guardarPregunta] = useState({
        titulo: '',
        details: '',
        idn: parseInt(idn),
        TID: dataTid.TID,
        id_materia: dataTid.id_materia,
        id_carrera: dataTid.id_carrera,
    });

    const { titulo } = pregunta;

    const onChangePregunta = e => {
        guardarPregunta({
            ...pregunta,
            [e.target.name]: e.target.value,
        });
    };

    const setDetails = d => {
        guardarPregunta({
            ...pregunta,
            details: d,
        });
    };

    const onSubmitPregunta = e => {
        e.preventDefault();

        if (titulo === '') {
            mostrarError();
            return;
        }

        setLoading(true);
        agregarPregunta(pregunta);
    };

    useEffect(() => {
        // si hay un error
        if (mensaje) {
            dispatch(mostrarAlerta(mensaje));
            setLoading(false);
            resetMensaje();
        }
        // eslint-disable-next-line
    }, [mensaje]);

    useEffect(() => {
        // si envio correctamente
        if (success) {
            enqueueSnackbar('Tu pregunta ha sido enviada!', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            setTimeout(() => {
                resetForm();
                isOpen(false);
            }, 1000);
        }
        // eslint-disable-next-line
    }, [success]);

    return (
        <>
            <button type="button" className="btn btn-default" onClick={() => isOpen(false)}>
                Volver a las preguntas
            </button>

            <div className="mt-space-lg font-text-sm well">
                <p>
                    <b>Consejos para obtener respuesta rápida</b>
                </p>
                <ul className="bulleted-list">
                    <li>Busque en el listado si su pregunta ya se ha hecho antes</li>
                    <li>Sea claro y detallado, proporcione la información lo más clara posible</li>
                    <li>Revise la ortografía y gramática</li>
                </ul>
            </div>

            <div>
                <form onSubmit={onSubmitPregunta}>
                    <div className="form-group form-group-sm">
                        <div className="question-editor--label">
                            <b>Pregunta</b>
                        </div>
                        <div className="form-control-counter-container">
                            <input
                                type="text"
                                name="titulo"
                                value={titulo}
                                onChange={onChangePregunta}
                                maxLength="255"
                                autoFocus={true}
                                className="question-editor--title-editor form-control input-sm"
                                placeholder="Tu consulta relacionada a la página actual"
                            />
                        </div>
                        {errorformulario ? <p className="mensaje error">Escriba su pregunta</p> : null}
                    </div>
                    <div className="question-editor--label">
                        <b>Detalles (opcional)</b>
                    </div>
                    <div className="">
                        <Editor getHtml={setDetails} />
                    </div>
                    <div className="question-editor--buttons btn-toolbar">
                        <button disabled={titulo.length < 3 || loading} type="submit" className="w100p btn btn-primary">
                            {loading && <SubLoading />}Publicar
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}
