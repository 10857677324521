import React, { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

import clienteAxios from 'config/axios';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import { setNumMensajesNuevos } from 'redux/actions/homeData';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBtnIconL from '../img/arrow-left.svg';
import ArrowBtnIconR from '../img/arrow-right.svg';
import MensajeItem from 'components/MensajeItem';
import { scrollToBottom } from '../components/constants';

const Container = styled('div')`
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button:disabled {
        opacity: 0.3;
    }
`;

const MoreBtn = styled('button')`
    margin-top: 20px;
`;

export default function Mensajes() {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);
    const nummensajes = useSelector(state => state.homeData.mensajes);

    const [mensajes, setMensajes] = useState([]);
    const [nextPage, setnextPage] = useState(0);
    const [msgLoading, setmsgLoading] = useState(false);

    useTitle('mi IES - mensajes');

    const showMore = async page => {
        scrollToBottom();

        const lastID = localStorage.getItem('notificationlastID') ? parseInt(localStorage.getItem('notificationlastID')) : 0;

        setmsgLoading(true);
        dispatch(setLoading(true));
        try {
            let result = await clienteAxios.get(`/mensajes`, { params: { rol: usuario.rol, page: page === 0 ? 1 : page, lastID } });

            if (result.data.r === 0) {
                dispatch(mostrarAlerta(result.data.text, 'Error'));
                return false;
            }

            let actualmessages = nummensajes - result.data.newMessages;
            if (actualmessages >= 0) dispatch(setNumMensajesNuevos(actualmessages));

            if (result.data.mensajes.length > 0) {
                let lId = result.data.mensajes[0].id;
                if (lastID < lId) {
                    localStorage.setItem('notificationlastID', lId);
                }
            }

            setMensajes([...mensajes, ...result.data.mensajes]);
            setnextPage(result.data.nextPage);
        } catch (error) {
            dispatch(mostrarAlerta(error, 'Error'));
        } finally {
            setmsgLoading(false);
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (Object.keys(usuario).length > 0) showMore(1);

        // eslint-disable-next-line
    }, [usuario]);

    useEffect(() => {
        dispatch(setTitulo('Mensajes'));
    }, [dispatch]);

    if (!Object.keys(usuario).length > 0) return null;

    return (
        <>
            {loading && !msgLoading ? (
                <>
                    <div style={{ width: '100%' }}>
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                    </div>
                </>
            ) : (
                <Container iconarrowr={ArrowBtnIconR} iconarrowl={ArrowBtnIconL}>
                    {mensajes.length === 0 && !msgLoading && <p>No hay mensajes</p>}
                    {mensajes.map((msg, key) => {
                        return <MensajeItem msg={msg} key={key} />;
                    })}
                    {nextPage !== 0 && (
                        <div>
                            <MoreBtn type="button" disabled={msgLoading} onClick={() => showMore(nextPage)}>
                                Mostrar más
                            </MoreBtn>
                        </div>
                    )}
                    {msgLoading && <p>Cargando</p>}
                </Container>
            )}
        </>
    );
}
