import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import Avatar from './avatar';

const Container = styled('div')`
    padding: 15px;
    display: flex;
    font-size: 18px;
    border-bottom: 1px solid #707070;
    align-self: flex-start;
    width: 100%;
    transition: opacity 0.15s ease-out;
    cursor: pointer;
    .avt {
        margin-right: 10px;
        width: 42px;
        height: 42px;
    }
    .fromtext {
        width: 100%;
    }
    .visto {
        display: flex;
        align-items: center;
        margin-left: 5px;
    }
    :hover {
        opacity: 0.7;
    }
`;

const From = styled('h2')`
    font-size: 16px;
    font-weight: ${props => (props.visto ? 'normal' : 'bold')};
    color: ${props => (props.visto ? '#323232' : '#323232')};
    padding: 0;
    margin: 0;
`;

const Text = styled('p')`
    font-size: 14px;
    font-weight: ${props => (props.visto ? 'normal' : 'bold')};
    color: ${props => (props.visto ? '#929191' : '#323232')};
    padding: 0;
    margin: 0;
`;

const Fecha = styled('span')`
    font-size: 12px;
    font-weight: normal;
    color: #929191;
    padding: 0;
    margin: 0;
    font-style: italic;
`;

const MarcaVisto = styled('span')`
    width: 9px;
    min-width: 9px;
    height: 9px;
    background-color: #00aae4;
    border-radius: 50%;
    display: block;
`;

export default function MensajeItem({ msg }) {
    const { avatar, id, title, visto, date, from } = msg;
    const dataAvatar = { nombres: '', apellidos: '', initials: '', foto: avatar, moderador: false };
    let history = useHistory();

    return (
        <Container
            onClick={() => {
                history.push(`/app/mensaje/${id}`);
            }}
        >
            <div className="avt">
                <Avatar usuario={dataAvatar} radius={42} />
            </div>
            <div className="fromtext">
                <From visto={visto}>
                    {from} <Fecha>{date}</Fecha>
                </From>
                <Text visto={visto}>{title}</Text>
            </div>
            <div className="visto">{!visto && <MarcaVisto />}</div>
        </Container>
    );
}
