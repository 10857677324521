import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

export default function Snackbar() {
    const { enqueueSnackbar } = useSnackbar();
    const snackMsg = useSelector(state => state.aplication.snackmsg);

    useEffect(() => {
        if (snackMsg) {
            if (snackMsg.txt) {
                enqueueSnackbar(snackMsg.txt, snackMsg.rest);
            } else {
                enqueueSnackbar(snackMsg);
            }
        }
    }, [snackMsg, enqueueSnackbar]);

    return <></>;
}
