import axios from 'axios';
import { API_BASE, VERAPP } from 'components/constants';
import { localVar } from 'components/constants';

/*
const clienteAxios = axios.create({
    baseURL : process.env.REACT_APP_BACKEND_URL
});
*/

const clienteAxios = axios.create({
    baseURL: API_BASE,
});

clienteAxios.interceptors.request.use(
    function (config) {
        const token = localVar.getItem('token') ? localVar.getItem('token') : null;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        if (config.data === undefined) {
            config['data'] = { VERAPP: VERAPP };
            if (config['params'] === undefined) config['params'] = {};
            config['params']['VERAPP'] = VERAPP;
        } else {
            config.data.VERAPP = VERAPP;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
/*
clienteAxios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
});
*/
export default clienteAxios;
