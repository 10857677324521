import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getData, getNumMensajesNuevos } from 'redux/actions/homeData';

import Skeleton from 'react-loading-skeleton';
import ImgCarrera from '../../img/footer/Carrera.svg';
import ImgCarreraHover from '../../img/footer/CarreraHover.svg';

export default function BtnCarreras({ Button, istop }) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const carreras = useSelector(state => state.homeData.carreras);

    const loading1 = useSelector(state => state.usuario.loading);
    const loading2 = useSelector(state => state.aplication.loading);
    const loading3 = useSelector(state => state.homeData.loading);

    const updateNumNewMessages = () => {
        const lastID = localStorage.getItem('notificationlastID') ? parseInt(localStorage.getItem('notificationlastID')) : 0;
        dispatch(getNumMensajesNuevos(usuario.rol, lastID));
    };

    useEffect(() => {
        if (Object.keys(usuario).length > 0) {
            dispatch(getData());
        }
    }, [usuario, dispatch]);

    useEffect(() => {
        if (Object.keys(usuario).length > 0 && carreras) {
            updateNumNewMessages();
            const interval = setInterval(() => {
                updateNumNewMessages();
            }, 120000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [usuario, carreras, dispatch]);

    if (!carreras || !carreras.docente)
        return (
            <Button disabled>
                <Skeleton height={27} width={27} />
                <div style={{ marginTop: '7px' }}>Cargando...</div>
            </Button>
        );

    let totalCarreras = [...carreras.docente, ...carreras.alumno];
    let loc = location.pathname.split('app').pop().split('/')[1];

    let loading = loading1 || loading2 || loading3;

    return (
        <Button
            disabled={loading}
            onClick={() =>
                history.push(
                    totalCarreras.length > 1 ? '/app/carreras' : `/app/carrera/${totalCarreras[0].id}/${carreras.alumno.length > 0 ? 'alumno' : 'docente'}`
                )
            }
            className={loc === 'carreras' || loc === 'carrera' || loc === 'materia' ? 'active' : null}
            bg={ImgCarrera}
            bgh={ImgCarreraHover}
            type="button"
            istop={istop}
        >
            <div className="Img"></div>
            <div style={{ marginTop: '7px' }}>{totalCarreras.length > 1 ? 'Carreras' : 'Carrera'}</div>
        </Button>
    );
}
