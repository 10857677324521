import React, { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

import clienteAxios from 'config/axios';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import { setLoading, setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import Calendar from 'components/Agenda/Calendar';
import ArrowBtnIconL from '../img/arrow-left.svg';
import ArrowBtnIconR from '../img/arrow-right.svg';

const Container = styled('div')`
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .calendar-header {
        text-align: left;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        max-width: 400px;
        width: 100%;
        justify-content: space-between;
        padding: 0 20px;
    }

    .prevMonth {
        background-image: url(${props => props.iconarrowl});
        background-position: left;
    }
    .nextMonth {
        background-image: url(${props => props.iconarrowr});
        background-position: right;
    }
    .prevMonth,
    .nextMonth {
        background-repeat: no-repeat;
        background-size: contain;
        width: 40px;
        height: 15px;
    }
    button:disabled {
        opacity: 0.3;
    }
`;

export default function Calendario() {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);
    const carreras = useSelector(state => state.homeData.carreras);

    const [agendaMes, setAgendaMes] = useState();

    const [agendaQuery, setAgendaQuery] = useState();

    function formatNums(n) {
        if (n.length === 1) {
            n = '0' + n;
        }
        return n;
    }

    const d = new Date();
    const [yyyy, setYyyy] = useState(d.getFullYear());
    const [mm, setMm] = useState(formatNums(String(d.getMonth() + 1)));

    useTitle('mi IES - calendario');

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));
            let id_carreras = [];
            if (usuario.rol === 'alumno') {
                carreras.alumno.forEach(element => {
                    id_carreras.push(element.id);
                });
            } else {
                carreras.docente.forEach(element => {
                    id_carreras.push(element.id);
                });
            }

            let result;

            try {
                let url = usuario.rol === 'alumno' ? 'agenda' : 'agenda-docente';
                result = await clienteAxios.get(`/${url}`, { params: { anio: yyyy, mes: mm, id_carreras: id_carreras.join(',') } });

                if (result.data.r === 0) {
                    dispatch(mostrarAlerta(result.data.text, 'Error'));
                    return false;
                }

                setAgendaMes(result.data.agenda);
                setAgendaQuery(result.data.dataquery);
                //dispatch(setTitulo(result.data.materia.desc));
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (carreras !== null && carreras.alumno) {
            fetchData();
        }

        // eslint-disable-next-line
    }, [carreras, yyyy, mm]);

    useEffect(() => {
        dispatch(setTitulo('Calendario'));
    }, [dispatch]);

    const handleClickMonth = n => {
        let anio_nuevo = yyyy,
            mes_nuevo = parseInt(mm) + n;

        if (mes_nuevo > 11) {
            anio_nuevo = anio_nuevo + 1;
            mes_nuevo = 0;
        } else if (mes_nuevo < 1) {
            anio_nuevo = anio_nuevo - 1;
            mes_nuevo = 11;
        }
        setYyyy(anio_nuevo);
        setMm(formatNums(String(mes_nuevo)));
    };

    if (!Object.keys(usuario).length > 0) return null;
    if(!agendaQuery) return null;

    return (
        <>
            {!agendaMes || loading ? (
                <>
                    <div style={{ padding: '15px', maxWidth: '400px', margin: 'auto' }}>
                        <Skeleton height={360} />
                    </div>
                </>
            ) : (
                <Container iconarrowr={ArrowBtnIconR} iconarrowl={ArrowBtnIconL}>
                    <div className="calendar-header">
                        <div>
                            {agendaQuery.mes} {agendaQuery.anio}
                        </div>
                        <div>
                            <button
                                disabled={parseInt(mm) <= d.getMonth() + 1}
                                className="prevMonth"
                                type="button"
                                onClick={() => handleClickMonth(-1)}
                            ></button>
                            <button className="nextMonth" type="button" onClick={() => handleClickMonth(1)}></button>
                        </div>
                    </div>
                    <Calendar yyyy={yyyy} mm={mm} agendaMes={agendaMes} />
                </Container>
            )}
        </>
    );
}
