import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import clienteAxios from 'config/axios';
import { mostrarAlerta } from 'redux/actions/alertaActions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import SubLoading from 'components/loading';
import styled from '@emotion/styled';

const BarTime = styled('div')`
    width: 100%;
    border: 1px solid #ff6859;
    .barbg {
        width: ${props => props.width}%;
        height: 4px;
        background-color: #ff6859;
    }
`;

export default function VerifyDialog(props) {
    const { open, onClose, data, type } = props;
    const { verify_text, verify_time } = data;
    let seconds = verify_time;
    const [timeLeft, setTimeLeft] = useState(seconds);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);

    let title = type === 'pass' ? 'Comprobación para cambio de contraseña' : 'Comprobación de nuevo email';

    useEffect(() => {
        if (!timeLeft) {
            dispatch(mostrarAlerta('Finalizó el tiempo de espera para la verificación.', 'No se pudo verificar la cuenta'));
            onClose();
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [timeLeft]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setLoading(true);
            let data = {
                code,
            };
            let result;
            if (type === 'pass') {
                result = await clienteAxios.post(`/verifypass`, data);
            } else {
                result = await clienteAxios.post(`/verifymail`, data);
            }

            if (result.data.r === 0) {
                dispatch(mostrarAlerta(result.data.text, 'Error'));
                return false;
            }
            onClose(type === 'pass' ? null : result.data.email);
            enqueueSnackbar(result.data.text, {
                variant: 'success',
            });
        } catch (error) {
            dispatch(mostrarAlerta(error, 'Error'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
            <form onSubmit={handleSubmit}>
                <div className="dilogContent">
                    <h2 id="responsive-dialog-title">{title}</h2>
                    <p>{verify_text}</p>
                    <p></p>

                    <div className="form-group" style={{ maxWidth: '250px', margin: 'auto' }}>
                        <label htmlFor="text" className="control-label">
                            Código
                        </label>
                        <input
                            type="text"
                            style={{ fontSize: '20px', textAlign: 'center', letterSpacing: '5px' }}
                            onChange={e => setCode(e.target.value)}
                            className="form-control"
                        />
                        <span className="f11">(tiempo restante: {timeLeft} segundos)</span>
                        <BarTime width={(timeLeft / seconds) * 100}>
                            <div className="barbg"></div>
                        </BarTime>
                        <br />
                    </div>

                    <div className="DialogActions">
                        <button onClick={onClose} type="button" className="btn btn-default">
                            Cancelar
                        </button>
                        <button onClick={handleSubmit} disabled={code < 4 || loading} type="submit" className="btn btn-primary">
                            {loading && <SubLoading />}Verificar
                        </button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
}
