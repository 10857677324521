import {
    MOSTRAR_ALERTA,
    OCULTAR_ALERTA
} from '../types';

const initialState = {
    alerta: {}
}

// eslint-disable-next-line
export default function(state = initialState, action) {
    switch(action.type) {
        case MOSTRAR_ALERTA:
            return {
                ...state,
                alerta: action.payload
            }
        case OCULTAR_ALERTA:
            return {
                ...state,
                alerta: {}
            }
        default:
            return state;
    }
}
