import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Menu from './menu';
import { setOpenMenu } from 'redux/actions/aplication';

export default function MenuHome() {
    const dispatch = useDispatch();
    const openMenu = useSelector(state => state.aplication.openMenu);
    const [configMenu, setconfigMenu] = useState(false);
    const toggleDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        dispatch(setOpenMenu(open));
        setconfigMenu(true);
    };
    return (
        <SwipeableDrawer disableSwipeToOpen={false} open={openMenu} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
            <Menu open={configMenu} />
        </SwipeableDrawer>
    );
}
