export const OBTENER_PREGUNTAS = 'OBTENER_PREGUNTAS';
export const PREGUNTA_ACTUAL = 'PREGUNTA_ACTUAL';
export const AGREGAR_PREGUNTA = 'AGREGAR_PREGUNTA';
export const PREGUNTA_ERROR = 'PREGUNTA_ERROR';
export const RESET_FORM = 'RESET_FORM';
export const VALIDAR_FORMULARIO = 'VALIDAR_FORMULARIO';
export const REMOTE_LOADING = 'REMOTE_LOADING';
export const SEARCH_QUESTIONS = 'SEARCH_QUESTIONS';
export const AGREGAR_RESPUESTA = 'AGREGAR_RESPUESTA';
export const REMOTE_LOADING_INLINE = 'REMOTE_LOADING_INLINE';
export const ELIMINAR_PREGUNTA = 'ELIMINAR_PREGUNTA';
export const ELIMINAR_RESPUESTA = 'ELIMINAR_RESPUESTA';
export const RESET_MENSAJE = 'RESET_MENSAJE';
export const PREGUNTA_FOLLOW = 'PREGUNTA_FOLLOW';
export const RESPUESTA_UPVOTES = 'RESPUESTA_UPVOTES';

export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

export const PUSH_LOADIG = 'PUSH_LOADIG';
export const READY_PUSH = 'READY_PUSH';
export const PUSH_SUPPORTED = 'PUSH_SUPPORTED';
export const PUSH_ESTASUSCRIPTO = 'PUSH_ESTASUSCRIPTO';
export const PUSH_PARAMS = 'PUSH_PARAMS';
export const PUSH_ERRORSUSCRIBE = 'PUSH_ERRORSUSCRIBE';
export const PUSH_USCONSENT = 'PUSH_USCONSENT';
export const PUSH_SUSCRIBE = 'PUSH_SUSCRIBE';
export const PUSH_UNSUSCRIBE = 'PUSH_UNSUSCRIBE';

export const SETEAR_USUARIO = 'SETEAR_USUARIO';
export const SETEAR_USUARIO_MODE = 'SETEAR_USUARIO_MODE';
export const ELIMINAR_USUARIO = 'ELIMINAR_USUARIO';
export const SETEAR_LOADING = 'SETEAR_LOADING';
export const ERROR_CARGA_USUARIO = 'ERROR_CARGA_USUARIO';

export const SNACKBAR_MSG = 'SNACKBAR_MSG';

export const CARGA_TID = 'CARGA_TID';
export const IMPORTAR_SESION = 'IMPORTAR_SESION';
export const GUARDAR_SESION = 'GUARDAR_SESION';
export const ACTUALIZAR_AVANCE = 'ACTUALIZAR_AVANCE';
export const SETEAR_LOADING_TID = 'SETEAR_LOADING_TID';
export const ERROR_CARGA_TID = 'ERROR_CARGA_TID';
export const GUARDANDO_SESION = 'GUARDANDO_SESION';

export const SET_OPEN_MENU = 'SET_OPEN_MENU';
export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_APP_TITULO = 'SET_APP_TITULO';
export const SET_APP_ISMENUTOP = 'SET_APP_ISMENUTOP';

export const CARGA_APP = 'CARGA_APP';
export const SETEAR_LOADING_APP = 'SETEAR_LOADING_APP';
export const ERROR_CARGA_APP = 'ERROR_CARGA_APP';
export const CARGA_APP_NUMMENSAJESNUEVOS = 'CARGA_APP_NUMMENSAJESNUEVOS';
