import React, { useEffect } from 'react';
import { useTitle } from 'react-use';
import { setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Avatar from 'components/avatar';
import { Link } from 'react-router-dom';
import ButtonMiES from 'components/ButtonMiES';
import { changeMode } from 'redux/actions/usuarioActions';
import { mostrarAlerta } from 'redux/actions/alertaActions';
import { cerrarSesion } from 'redux/actions/usuarioActions';

const Container = styled('div')`
    padding: 30px;
    hr {
        width: 100%;
        height: 1px;
        background: #c0c0c0;
    }
`;
const UserContainer = styled('div')`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    h3 {
        margin: 0;
    }
    div:nth-of-type(2) {
        padding-left: 12px;
    }
`;

const LinkStyled = styled(Link)`
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #323232;
    width: 100%;
    display: block;
    padding: 15px;
`;

const ButtonStyled = styled('button')`
    font-size: 16px;
    text-align: left;
    width: 100%;
    display: block;
    padding: 15px;
`;

export default function Perfil() {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const carreras = useSelector(state => state.homeData.carreras);

    useTitle('mi IES - Perfil');

    useEffect(() => {
        dispatch(setTitulo('Perfil'));
    }, [dispatch]);

    if (!Object.keys(usuario).length > 0) return null;

    const handleClickMode = () => {
        let mode = usuario.rol === 'docente' ? 'alumno' : 'docente';
        dispatch(changeMode(mode));
        //history.push('/app');
    };

    const logOut = () => {
        const text = '¿Está seguro que desea cerrar la sesión actual?';
        dispatch(mostrarAlerta(text, 'Cerrar sesión', () => dispatch(cerrarSesion())));
    };

    return (
        <Container>
            <UserContainer>
                <div>
                    <Avatar usuario={usuario} radius={47} />
                </div>
                <div>
                    <h3>
                        {usuario.nombres} {usuario.apellidos}
                    </h3>
                    {usuario.dni && <span>{usuario.dni}</span>}
                </div>
            </UserContainer>
            <hr />
            <LinkStyled to={`perfil/datospersonales`}>Datos personales</LinkStyled>
            <hr />
            {usuario.rol === 'alumno' && (
                <>
                    {carreras && carreras.alumno && carreras.alumno.length > 0 && (
                        <>
                            {carreras.alumno.map((carrera, key) => {
                                if (carrera.id === 0) return null;
                                return (
                                    <LinkStyled key={key} to={`perfil/analitico/${carrera.id}`}>
                                        Analítico {carrera.carrera}
                                    </LinkStyled>
                                );
                            })}
                        </>
                    )}
                    <hr />
                    <LinkStyled to={`perfil/estadodecuenta`}>Estado de cuenta</LinkStyled>
                    <hr />
                </>
            )}
            <LinkStyled to={`perfil/credencial`}>Credencial IES</LinkStyled>
            <hr />
            <LinkStyled to={`perfil/ajustes`}>Ajustes</LinkStyled>

            {usuario.esDocente && usuario.esAlumno && (
                <>
                    <hr />
                    <ButtonMiES onClick={handleClickMode}>Cambiar a Sesión {usuario.rol === 'docente' ? 'Alumno' : 'Docente'}</ButtonMiES>
                </>
            )}
            <hr />
            <ButtonStyled className="gRojo" onClick={logOut}>
                Cerrar sesión
            </ButtonStyled>
        </Container>
    );
}
