import React, { useState } from 'react';

import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

import { winSize, winSizeModal } from 'components/constants';

const MEDIA_TIPO = {
    IMAGEN: 1,
    TEXTO: 2,
    CODIGO: 17,
    YOUTUBE: 3,
    MP4: 34,
    WIDGUET: 20,
    INTERACTIVA: 8,
    MP3: 5,
};

const useStyles = makeStyles(theme => ({
    texto: {
        position: 'absolute',
        maxWidth: winSize().width - 20,
        width: winSizeModal(),
        maxHeight: winSize().height - 20,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'auto',
    },
    info: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'auto',
    },
    codigo: {
        position: 'absolute',
        maxWidth: winSize().width - 20,
        width: winSize().width,
        maxHeight: winSize().height - 10 - 52,
        backgroundColor: '#00aae4',
        boxShadow: theme.shadows[5],
        padding: 0,
        marginTop: 22,
        overflowY: 'auto',
    },
    imagen: {
        position: 'absolute',
        width: winSize().width,
        maxHeight: winSize().height,
        boxShadow: theme.shadows[5],
        overflowY: 'auto',
        padding: '10px',
        backgroundColor: '#fff',
    },
    img: {
        backgroundColor: '#fff',
    },
    h2: {
        fontSize: 16,
        paddingRight: 20,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: 20,
        color: '#fff',
    },
}));

export default function Media({ open, closeMedia, mediaData }) {
    const classes = useStyles();
    const [showInfo, setShowInfo] = useState(false);

    if (Object.keys(mediaData).length === 0) return null;

    let mediaRender = <div></div>;

    const { f: fuente, w: wMed, h: hMed, istxt, id: idMed, i: info, incod, alt, src, t: title, c: cont, ps, URLTID } = mediaData;

    let tipoMed = istxt ? 2 : mediaData.m;

    let aspect = wMed / hMed;
    let responsiveW = window.innerWidth;
    let responsiveH = Math.round(responsiveW / aspect);
    let fullscreen = false;

    responsiveW = wMed;
    responsiveH = hMed;

    if (wMed < 1) {
        responsiveW = '100%';
        responsiveH = '100%';
        fullscreen = true;
    }

    let classRender = null;

    const infoalt =
        !fullscreen && idMed && info && info !== '' && tipoMed !== MEDIA_TIPO.IMAGEN && tipoMed !== MEDIA_TIPO.TEXTO && tipoMed !== MEDIA_TIPO.CODIGO;

    switch (tipoMed) {
        case MEDIA_TIPO.IMAGEN:
            // imagen
            const onloadImg = e => {
                e.target.className = document.getElementById('mediaOnload').className.replace(' bgmm', '');
            };
            mediaRender = (
                <div id="mediaOnload" className={`${classes.imagen} bgmm modalStyle`}>
                    <InnerImageZoom src={src} alt={alt} className={classes.img} zoomSrc={src} onLoad={onloadImg} />
                    {/* <PinchZoomPan zoomButtons={false} position={'center'}>
                        <img alt={alt} src={src} className={classes.img} onLoad={onloadImg} />
                    </PinchZoomPan> */}
                </div>
            );
            break;
        case MEDIA_TIPO.TEXTO:
            // texto
            mediaRender = (
                <div className={`${classes.texto} modalStyle`}>
                    {title !== cont && title !== '' && <h2>{title}</h2>}
                    {cont && <p>{cont}</p>}
                    {info && <p dangerouslySetInnerHTML={{ __html: info }}></p>}
                </div>
            );
            break;
        case MEDIA_TIPO.CODIGO:
            // codigo
            mediaRender = (
                <div className={`${classes.codigo} modalStyle`}>
                    <h2 className={classes.h2}>{title}</h2>
                    {cont && <>{cont}</>}
                    {info && <div dangerouslySetInnerHTML={{ __html: info }}></div>}
                </div>
            );
            break;

        case MEDIA_TIPO.YOUTUBE:
            // video youtube

            /*mediaRender = (<div style={{ maxWidth: `${responsiveW}px`, maxHeight: `${responsiveH}px` }}>
                <div className="ifrav">
                    <iframe width={responsiveW} height={responsiveH} src={`https://www.youtube.com/embed/${idMed}?showinfo=0&autoplay=1`} frameBorder="0" allowFullScreen title={title}>{title}</iframe>
                </div>
            </div>*/

            mediaRender = (
                <div className="ifravok bgmm">
                    <iframe
                        width={responsiveW}
                        height={responsiveH}
                        src={`https://www.youtube-nocookie.com/embed/${idMed}?rel=0&showinfo=0&autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={title}
                    ></iframe>
                </div>
            );
            break;
        case MEDIA_TIPO.MP4:
            // video mp4
            mediaRender = (
                <div className="ifrav" style={{ paddingBottom: 0 }}>
                    <video width={responsiveW} height={responsiveH} controls autoplay>
                        <source src={`${URLTID}me/${idMed}.mp4`} type="video/mp4" />
                        <p>Puede que esta multimedia no se reproduzca en su navegador</p>
                    </video>
                </div>
            );
            break;
        case MEDIA_TIPO.WIDGUET:
        case MEDIA_TIPO.INTERACTIVA:
            // widget
            // interactiva
            let scrolling = 'yes';
            if (ps === 1) {
                scrolling = 'no';
            }

            classRender = fullscreen ? 'fscreen media' : 'media';

            mediaRender = (
                <div className="ifrai">
                    {tipoMed !== 20 ? (
                        <iframe
                            width={responsiveW}
                            height={responsiveH}
                            className={ps === 1 ? 'toct' : null}
                            src={`${URLTID}me/${idMed}`}
                            scrolling={scrolling}
                            frameBorder="0"
                            allowFullScreen
                            title={title}
                        >
                            {title}
                        </iframe>
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: incod }}></div>
                    )}
                </div>
            );
            break;
        case MEDIA_TIPO.MP3:
            // audio
            mediaRender = (
                <div className="ifrav">
                    <audio controls autoPlay>
                        <source src={`${URLTID}me/${idMed}.mp3`} type="audio/mpeg" />
                        <p>Puede que esta multimedia no se reproduzca en su navegador</p>
                    </audio>
                </div>
            );
            break;

        default:
            break;
    }

    return (
        <Modal open={open} onClose={closeMedia}>
            {[MEDIA_TIPO.IMAGEN, MEDIA_TIPO.TEXTO, MEDIA_TIPO.YOUTUBE, MEDIA_TIPO.CODIGO].indexOf(tipoMed) > -1 ? (
                <>
                    <header className="media">
                        <button className="back" onClick={closeMedia}></button>
                    </header>
                    {mediaRender}
                    {fuente ? <div className="fuente-abs">Fuente: {fuente}</div> : null}
                </>
            ) : (
                <>
                    <header className="conten">
                        <button className="back" onClick={closeMedia}></button>
                        <h1
                            style={
                                !infoalt
                                    ? {
                                          paddingLeft: 20,
                                      }
                                    : {
                                          paddingLeft: 68,
                                      }
                            }
                        >
                            {title}
                        </h1>
                        {infoalt && <div onClick={() => setShowInfo(!showInfo)} className={showInfo ? 'info active' : 'info'}></div>}
                    </header>
                    <section className={classRender ? classRender : 'media'}>
                        {infoalt && showInfo && (
                            <div className="info_media">
                                <p dangerouslySetInnerHTML={{ __html: info }}></p>
                            </div>
                        )}
                        {mediaRender}
                        {fuente && fuente !== '' ? <p className="fuente">Fuente: {fuente}</p> : null}
                    </section>
                </>
            )}
        </Modal>
    );
}
