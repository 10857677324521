import clienteAxios from 'config/axios';
import { device_data } from 'components/constants';

import {
    PUSH_LOADIG,
    READY_PUSH,
    PUSH_SUPPORTED,
    PUSH_ESTASUSCRIPTO,
    PUSH_PARAMS,
    PUSH_ERRORSUSCRIBE,
    PUSH_USCONSENT,
    PUSH_SUSCRIBE,
    PUSH_UNSUSCRIBE,
} from 'redux/types';

export const askUserPermission = async () => {
    return await Notification.requestPermission();
};

export const initPush = dni => {
    // console.log("initPush");

    return dispatch => {
        dispatch({
            type: PUSH_LOADIG,
            payload: true,
        });

        const Pushwoosh = window.Pushwoosh;

        Pushwoosh.push([
            'init',
            {
                logLevel: 'error', // possible values: error, info, debug
                applicationCode: 'C7E03-9A6B0',
                safariWebsitePushID: 'ies21.edu.ar',
                defaultNotificationTitle: 'IES 21',
                defaultNotificationImage: '/logo192.png',
                autoSubscribe: false,
                userId: dni,
            },
        ]);

        Pushwoosh.push(function (api) {
            // Register user ID
            api.registerUser(dni);
        });

        Pushwoosh.push([
            'onReady',
            function () {
                console.log('Pushwoosh ready');
                dispatch(onReady());
            },
        ]);

        Pushwoosh.push([
            'onUnsubscribe',
            function () {
                return dispatch => {
                    dispatch({
                        type: PUSH_ESTASUSCRIPTO,
                        payload: false,
                    });
                    dispatch({
                        type: PUSH_LOADIG,
                        payload: false,
                    });
                    dispatch(getPWParams());
                };
            },
        ]);

        Pushwoosh.push([
            'onSubscribe',
            function () {
                return dispatch => {
                    dispatch({
                        type: PUSH_ESTASUSCRIPTO,
                        payload: true,
                    });
                    dispatch({
                        type: PUSH_LOADIG,
                        payload: false,
                    });
                    dispatch(getPWParams());
                };
            },
        ]);
    };
};

const onReady = () => {
    const Pushwoosh = window.Pushwoosh;

    const pushSupported = Pushwoosh.isAvailableNotifications();

    return dispatch => {
        dispatch({
            type: PUSH_SUPPORTED,
            payload: pushSupported,
        });
        dispatch(getExistingSuscription());
    };
};

const getExistingSuscription = () => {
    return async dispatch => {
        const Pushwoosh = window.Pushwoosh;

        dispatch({
            type: PUSH_LOADIG,
            payload: true,
        });

        try {
            const estaSuscripto = await Pushwoosh.isSubscribed();

            dispatch({
                type: PUSH_ESTASUSCRIPTO,
                payload: estaSuscripto,
            });

            dispatch({
                type: READY_PUSH,
                payload: true,
            });

            dispatch(getPWParams());

            getNotDisplayMsg();
        } catch (e) {
            console.log(e);
            setTimeout(() => {
                onReady();
            }, 4000);
        }

        dispatch({
            type: PUSH_LOADIG,
            payload: false,
        });
    };
};

const getPWParams = () => {
    return async dispatch => {
        dispatch({
            type: PUSH_LOADIG,
            payload: true,
        });

        const Pushwoosh = window.Pushwoosh;

        const params = await Pushwoosh.getParams();

        // console.log(params);
        updateWebPushToken(params);

        dispatch({
            type: PUSH_PARAMS,
            payload: params,
        });

        dispatch({
            type: PUSH_LOADIG,
            payload: false,
        });
    };
};

const updateWebPushToken = async params => {

    const data = {
        hwid: params.hwid,
        pushToken: params.pushToken ? params.pushToken : '',
        estaSuscripto: params.pushToken ? true : false,
        device_data: device_data,
    };

    try {
        const result = await clienteAxios.put('/update-web-pushtoken', data);

        if (result.data.r === 0) {
            console.log(result.data.text);
            return;
        }
        if (result.data === undefined) {
            console.log(result);
            return;
        }

        console.log('Se regsitro hwid ' + params.hwid);
    } catch (error) {
        console.log(error);
    }
};

export const onClickUnsubscribe = () => {
    return dispatch => {
        dispatch({
            type: PUSH_UNSUSCRIBE,
        });
    };
};

export const onClickSubscribe = () => {
    return dispatch => {
        dispatch({
            type: PUSH_SUSCRIBE,
        });
    };
};

export const onClickAskUserPermission = (onAccept = null) => {
    return dispatch => {
        dispatch({
            type: PUSH_LOADIG,
            payload: true,
        });
        dispatch({
            type: PUSH_ERRORSUSCRIBE,
            payload: false,
        });
        askUserPermission().then(consent => {
            dispatch({
                type: PUSH_USCONSENT,
                payload: consent,
            });
            if (consent !== 'granted') {
                dispatch({
                    type: PUSH_ERRORSUSCRIBE,
                    payload: {
                        name: 'Consentimiento denegado',
                        message: 'Has denegado el consentimiento para recibir notificaciones',
                        code: 0,
                    },
                });
                dispatch({
                    type: PUSH_LOADIG,
                    payload: false,
                });
            } else {
                dispatch({
                    type: PUSH_LOADIG,
                    payload: false,
                });
                if (onAccept !== null) onAccept();
            }
        });
    };
};

export const getNotDisplayMsg = async () => {
    const Pushwoosh = window.Pushwoosh;

    try {
        const existingSubscription = await Pushwoosh.isSubscribed();

        console.log('getNotDisplayMsg existingSubscription:', existingSubscription);

        if (existingSubscription) {
            const opened = await Pushwoosh.pwinbox.messagesWithNoActionPerformedCount();
            console.log(`${opened} messages opened`);

            const unread = await Pushwoosh.pwinbox.unreadMessagesCount();
            console.log(`${unread} messages unread`);

            const totalmessages = await Pushwoosh.pwinbox.messagesCount();
            console.log(`${totalmessages} messages`);

            const messages = await Pushwoosh.pwinbox.loadMessages();

            if (totalmessages > 0) {
                console.log(messages);
            }
        }
    } catch (e) {
        console.log(e);
    }
};
