import React, { useState, useEffect, useCallback } from 'react';
import { useLocalStorage, useTitle } from 'react-use';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { getFreeSizeLocalstorage, checkRemainingBytes } from '../../components/constants';
import getUuidDevice from '../../components/uuidevice';

// import usePushNotifications from 'hooks/usePushNotificationsWoosh';
import SubLoading from '../../components/loading';

import { cerrarSesion } from 'redux/actions/usuarioActions';
import { setTitulo } from 'redux/actions/aplication';
import { useSelector, useDispatch } from 'react-redux';
import { onClickAskUserPermission, onClickUnsubscribe, onClickSubscribe } from 'redux/actions/notificacionesActions';

import { mostrarAlerta } from 'redux/actions/alertaActions';
import Skeleton from 'react-loading-skeleton';

const IOSSwitch = withStyles(theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#009ddd',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#009ddd',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function Ajustes() {
    useTitle('Colegio Universitario IES - Ajustes');

    const dispatch = useDispatch();

    const userConsent = useSelector(state => state.notificaciones.userConsent);
    const soportaNotificaciones = useSelector(state => state.notificaciones.soportaNotificaciones);
    const errorsuscribe = useSelector(state => state.notificaciones.errorsuscribe);
    const loadingPush = useSelector(state => state.notificaciones.loadingPush);
    const estaSuscripto = useSelector(state => state.notificaciones.estaSuscripto);
    const isReady = useSelector(state => state.notificaciones.isReady);
    const hwid = useSelector(state => state.notificaciones.hwid);
    const userId = useSelector(state => state.notificaciones.userId);
    const pushToken = useSelector(state => state.notificaciones.pushToken);
    const loading = useSelector(state => state.aplication.loading);

    const isConsentGranted = userConsent === 'granted';

    const usuario = useSelector(state => state.usuario.usuario);

    const [loadingConfig, setLoadingConfig] = useState(true);
    const [storageData, setStorageData] = useState({ espaciousado: 0, espaciolibre: 0, total: 0, percocup: 0 });

    const [tidConfig, setTidConfig] = useLocalStorage('tidConfig', { aumentarTxt: false });
    const [uuidDevice] = useLocalStorage('uuidDevice', getUuidDevice(), { raw: true });
    const token = useSelector(state => state.usuario.token);

    const [checkAjustes, setCheckAjustes] = useState({
        notificaciones: false,
        utiles: false,
        aumentarTxt: tidConfig.aumentarTxt,
    });

    const [notificacionesChk, setnotificacionesChk] = useState(false);

    const handleChange = e => {
        if (e.target.name === 'aumentarTxt') {
            //console.log(e.target.checked)
            setTidConfig({ ...tidConfig, aumentarTxt: e.target.checked });
        }
        if (e.target.name === 'notificaciones') {
            if (!e.target.checked && userConsent === 'granted') {
                dispatch(
                    mostrarAlerta(
                        'Está seguro que no desea recibir más notificaciones?, pueden ser importantes para su vida académica.',
                        'Notificaciones',
                        unsubscribeNotificaciones,
                        'Cancelar Suscripción',
                        'Lo pensaré'
                    )
                );
            }

            if (e.target.checked) {
                if (userConsent === 'denied') {
                    dispatch(
                        mostrarAlerta(
                            'Para recibir notificaciones debe desbloquear el consentimiento desde las preferencias de la página en su navegador',
                            'Notificaciones'
                        )
                    );
                    setnotificacionesChk(false);
                }

                if (userConsent === 'default') {
                    dispatch(
                        mostrarAlerta(
                            'Para recibir notificaciones en este navegador debe aceptar el permiso a continuación',
                            'Notificaciones',
                            setNotificacionesPush,
                            'Aceptar',
                            null
                        )
                    );
                }

                if (userConsent === 'granted') {
                    setNotificacionesPush();
                }
            }
        } else {
            setCheckAjustes({ ...checkAjustes, [e.target.name]: e.target.checked });
        }
    };

    const onShowNotificationPermissionDialog = () => {
        //console.log(userConsent);
        if (userConsent === 'granted' && isReady) {
            dispatch(onClickSubscribe());
        }
    };
    const setNotificacionesPush = () => {
        dispatch(onClickAskUserPermission(onShowNotificationPermissionDialog));
    };

    const unsubscribeNotificaciones = () => {
        dispatch(onClickUnsubscribe());
    };

    const logOut = () => {
        const text = '¿Está seguro que desea cerrar la sesión actual?';
        dispatch(mostrarAlerta(text, 'Cerrar sesión', () => dispatch(cerrarSesion())));
    };

    const getallStorage = (dni, all) => {
        var keysOutput = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while (i--) {
            if (keys[i].indexOf(dni) > -1) {
                if (all) {
                    keysOutput.push(keys[i]);
                } else {
                    if (keys[i].indexOf('t') === 0) {
                        keysOutput.push(keys[i]);
                    }
                }
            }
        }

        return keysOutput;
    };

    const clearUtilesStorage = () => {
        const keys = getallStorage(usuario.dni, false);
        keys.forEach(item => {
            localStorage.removeItem(item);
        });
        finishInit();
    };

    const clearSesionStorage = () => {
        const keys = getallStorage(usuario.dni, true);
        keys.forEach(item => {
            localStorage.removeItem(item);
        });

        finishInit();
    };

    const clearInfo = e => {
        let text = '';
        switch (e.target.name) {
            case 'lib1':
                text = 'Se va a liberar el espacio usado por utilidades de estudio de todos los TID en este dispositivo.';
                dispatch(mostrarAlerta(text, 'Eliminar', clearUtilesStorage));
                break;
            case 'lib2':
                text = 'Se va a liberar el espacio usado por la sesión completa.';
                dispatch(mostrarAlerta(text, 'Eliminar', clearSesionStorage));
                break;
            case 'lib3':
                text = 'Se va a liberar todo el espacio usado por esta aplicación.';
                dispatch(mostrarAlerta(text, 'Eliminar', localStorage.clear()));
                break;
            default:
                break;
        }
    };

    const finishInit = useCallback(() => {
        console.log('finishInit');

        setCheckAjustes({ ...checkAjustes, aumentarTxt: tidConfig.aumentarTxt });

        //setTimeout(() => {

        let espaciousado = checkRemainingBytes();
        let espaciolibre = getFreeSizeLocalstorage();
        let total = (espaciousado + espaciolibre).toFixed(0);
        let percocup = ((espaciousado * 100) / (espaciousado + espaciolibre)).toFixed(1);

        espaciousado = espaciousado.toFixed(0);
        espaciolibre = espaciolibre.toFixed(0);

        setStorageData({
            espaciousado,
            espaciolibre,
            total,
            percocup,
        });

        setLoadingConfig(false);

        //}, 500);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Object.keys(usuario).length > 0) {
            if ('Notification' in window) {
                if (isReady) {
                    finishInit();
                }
            } else {
                console.log('Este navegador no es compatible con las notificaciones de escritorio');
                finishInit();
            }
        }
        // eslint-disable-next-line
    }, [usuario, isReady]);

    useEffect(() => {
        const isCheck = soportaNotificaciones && isConsentGranted && estaSuscripto;
        if (notificacionesChk !== isCheck) {
            setnotificacionesChk(isCheck);
        }
    }, [soportaNotificaciones, isConsentGranted, estaSuscripto, notificacionesChk]);

    useEffect(() => {
        if (loadingPush) {
            console.log(loadingPush);
        } else if (!loadingPush && !errorsuscribe) {
            if (userConsent !== 'granted' || !estaSuscripto) {
                setnotificacionesChk(false);
            }
            setnotificacionesChk(soportaNotificaciones && isConsentGranted && estaSuscripto);
        }
        // eslint-disable-next-line
    }, [loadingPush, userConsent, errorsuscribe]);

    useEffect(() => {
        dispatch(setTitulo('Ajustes'));
    }, [dispatch]);

    if (!Object.keys(usuario).length > 0) return null;

    return (
        <>
            {loading || loadingConfig ? (
                <>
                    <div style={{ padding: '15px' }}>
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                    </div>
                </>
            ) : (
                <>
                    <FormControlLabel
                        className="ajustes"
                        control={<IOSSwitch checked={notificacionesChk} onChange={handleChange} name="notificaciones" />}
                        label={
                            loadingPush || !token ? (
                                <SubLoading />
                            ) : soportaNotificaciones ? (
                                <>Permitir notificaciones</>
                            ) : (
                                <>Este dispositivo no permite notificaciones</>
                            )
                        }
                        disabled={!soportaNotificaciones || loadingPush}
                    />
                    {errorsuscribe && <p className="error">{errorsuscribe.message}</p>}
                    {soportaNotificaciones && notificacionesChk && (
                        <div className="ajustes">
                            <div className="f11 ajustesP">hwid: {hwid}</div>
                            <div className="f11 ajustesP">userId: {userId}</div>
                            <div className="f11 ajustesP" title={pushToken}>
                                pushToken: {pushToken}
                            </div>
                        </div>
                    )}

                    {/* <FormControlLabel
                    className="ajustes"
                    control={
                        <IOSSwitch
                            checked={checkAjustes.utiles}
                            onChange={handleChange}
                            name="utiles"
                        />
                    }
                    label={`Mostrar siempre los botones de marcado y notas en el texto`}
                /> */}

                    <div className="ajustes">
                        <div className="f11 ajustesP">uitid: {uuidDevice}</div>

                        <div className="f11 ajustesP" title={token}>
                            token: {token}
                        </div>
                    </div>

                    <hr className="w100" />
                    <div className="ajustes">
                        <p>
                            <strong>Visualización de contenido</strong>
                        </p>
                    </div>
                    <FormControlLabel
                        className="ajustes"
                        control={<IOSSwitch checked={checkAjustes.aumentarTxt} onChange={handleChange} name="aumentarTxt" />}
                        label="Aumentar tamaño de texto en material de estudio"
                    />

                    <hr className="w100" />

                    <div className="ajustes">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>
                                            <strong>Espacio de almacenamiento</strong>
                                            <span className="conteesp">
                                                <span style={{ width: `${storageData.percocup < 1 ? 1 : storageData.percocup}%` }}></span>
                                            </span>
                                            <span className="f10">
                                                {storageData.espaciousado} Kb en uso y {storageData.espaciolibre} Kb disponibles.
                                            </span>
                                        </p>

                                        <p className="f11">El espacio ocupado por la sesión completa, y el espacio libre.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {/* <table><tbody><tr>
                        <td><p>Liberar espacio usado por anotaciones y resúmenes en este dispositivo</p></td>
                    <td width="30"></td>
                    <td align="right" valign="top">
                        <button className="gRojo" name="lib1" onClick={clearInfo}>Liberar espacio</button>
                    </td></tr></tbody></table> */}

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Liberar espacio usado en este dispositivo</p>
                                    </td>
                                    <td width="30"></td>
                                    <td align="right" valign="top">
                                        <button className="gRojo" name="lib2" onClick={clearInfo}>
                                            Liberar espacio
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {usuario.debug && (
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>Liberar TODO el espacio</p>
                                        </td>
                                        <td width="30"></td>
                                        <td align="right" valign="top">
                                            <button className="gRojo" name="lib3" onClick={clearInfo}>
                                                Eliminar todo
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>

                    {usuario.debug && (
                        <>
                            <hr className="w100" />
                            <FormControlLabel
                                className="ajustes"
                                control={<IOSSwitch checked={checkAjustes.debug} onChange={handleChange} name="debug" />}
                                label={`Mostrar cónsola. Para control interno.`}
                            />
                        </>
                    )}

                    <hr className="w100" />

                    <div className="ajustes">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>
                                            Cerrar sesión de{' '}
                                            <strong>
                                                {usuario.nombres}, {usuario.apellidos}
                                            </strong>
                                        </p>
                                        <p className="f11">Se eliminarán los datos del navegador</p>
                                    </td>
                                    <td width="30"></td>
                                    <td align="right" valign="top">
                                        <button className="gRojo" onClick={logOut}>
                                            Cerrar sesión
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
}
