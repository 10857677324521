import React, { useState } from 'react';
import styled from '@emotion/styled';
import ClipIcon from '../../img/clip.svg';
import Titulo from 'components/TituloH2';
import clienteAxios from 'config/axios';
import { useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import SubLoading from 'components/loading';
import imgEliminar from 'img/Eliminar.svg';
import { useDispatch } from 'react-redux';
import { mostrarAlerta } from 'redux/actions/alertaActions';

const Container = styled('div')`
    background-color: #fff;
    margin: 15px;
    margin-bottom: 40px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e1e1;
    padding: 8px;
    > div {
        padding: 7px;
    }
    > div img {
        margin-right: 7px;
    }
    .file-item {
        position: relative;
        display: flex;
        flex-direction: column;
        > div {
            margin-left: 40px;
            font-size: 12px;
            margin-top: -14px;
        }
        a > span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 68px);
        }
    }
    .btnDelete {
        position: absolute;
        right: 0;
    }
`;

const getColor = props => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const ContainerDrop = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    .dropedFiles {
        font-weight: bold;
        color: #999;
    }
`;

export default function ArchivosBox({ data, changeData, rol = 'alumno' }) {
    const dispatch = useDispatch();
    const { id_materia, id_carrera } = useParams();
    const totalFiles = 10;
    const usedFiles = data.archivos_data?.archivos?.length ? data.archivos_data.archivos.length : 0;
    const maxSize = 1e7;
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOnDrop = dropFiles => {
        let reparado = 0;

        let occupedSlots = [];
        if (data?.archivos_data?.archivos) {
            data.archivos_data.archivos.forEach(el => {
                occupedSlots.push(el.key);
            });
        }

        for (let slot = 1; slot <= totalFiles; slot++) {
            const isOccupied = occupedSlots.indexOf(slot) > -1;
            if (!isOccupied && dropFiles[reparado]) {
                dropFiles[reparado].key = slot;
                reparado++;
            }
        }
        setFiles(dropFiles);
    };

    const { getRootProps, getInputProps, acceptedFiles, fileRejections, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: 'application/x-rar-compressed, application/zip, application/x-zip-compressed, multipart/x-zip',
        maxFiles: totalFiles - usedFiles,
        maxSize,
        onDrop: handleOnDrop,
    });

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {parseInt(file.size / 1024)} Kb
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return (
            <li key={file.path}>
                {file.path} - {parseInt(file.size / 1024)} Kb
                <ul>
                    {errors.map(e => (
                        <li key={e.code}>{e.message}</li>
                    ))}
                </ul>
            </li>
        );
    });

    const handleSubmit = async e => {
        e.preventDefault();

        const sendData = new FormData();
        files.forEach((file, index) => {
            sendData.append('file' + file.key, file);
        });
        let form = { id_materia, id_carrera, details: '' };
        sendData.append('form', JSON.stringify(form));
        //data.append('form', JSON.stringify(form));

        try {
            setLoading(true);
            let res = await clienteAxios.post(`/archivos`, sendData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (res.data.r === 1) {
                changeData({ ...data, archivos_data: res.data.archivos_data });
                setFiles([]);
            } else {
                enqueueSnackbar(res.data.text, {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = item => {
        const confirmAlert = async item => {
            let form = { id_materia, id_carrera, key: item.key, id: data.archivos_data.id, file: item.url };
            try {
                setLoading(true);
                let res = await clienteAxios.put(`/archivos`, form);
                if (res.data.r === 1) {
                    changeData({ ...data, archivos_data: res.data.archivos_data });
                    setFiles([]);
                } else {
                    enqueueSnackbar(res.data.text, {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    });
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        };

        const text = '¿Está seguro que desea eliminar el archivo?';
        dispatch(mostrarAlerta(text, 'Eliminar', () => confirmAlert(item)));
    };

    if ((!data.archivos_data?.archivos || data.archivos_data?.archivos.length < 1) && rol === 'alumno') return null;

    return (
        <div>
            <Titulo>ARCHIVOS</Titulo>
            <Container>
                {rol === 'docente' && totalFiles - usedFiles > 0 && (
                    <form onSubmit={handleSubmit}>
                        <ContainerDrop {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                            <input {...getInputProps()} disabled={loading} />
                            <p>Arrastre y suelte los archivos, o haga click aquí</p>
                            <em>({totalFiles - usedFiles} archivos permitidos)</em>
                            <em>Cada archivo no debe superar {parseInt(maxSize / 1024 / 1024)} Mb</em>
                            {files.length > 0 && (
                                <aside>
                                    {acceptedFiles.length > 0 && (
                                        <>
                                            <h4>Archivos aceptados</h4>
                                            <ul>{acceptedFileItems}</ul>
                                        </>
                                    )}
                                    {fileRejections.length > 0 && (
                                        <>
                                            <h4>Archivos rechazados</h4>
                                            <ul>{fileRejectionItems}</ul>
                                        </>
                                    )}
                                </aside>
                            )}
                        </ContainerDrop>
                        {files.length > 0 && (
                            <div className="question-editor--buttons btn-toolbar">
                                <button disabled={loading} type="submit" className="w100p btn btn-primary">
                                    {loading && <SubLoading />}Publicar
                                </button>
                            </div>
                        )}
                    </form>
                )}
                {data.archivos_data?.archivos &&
                    data.archivos_data.archivos.map((item, key) => {
                        return (
                            <div className="file-item" key={key}>
                                <a href={item.url} download={item.file} target="_blank" rel="noopener noreferrer" color="primary">
                                    <img src={ClipIcon} alt="" />
                                    <span>{item.file}</span>
                                </a>
                                <div>{item.updated_at}</div>
                                {rol === 'docente' && (
                                    <button className="btnDelete" disabled={loading} onClick={() => handleDelete(item)} title="Eliminar">
                                        {loading ? <SubLoading /> : <img src={imgEliminar} alt="Eliminar" />}
                                    </button>
                                )}
                            </div>
                        );
                    })}
            </Container>
        </div>
    );
}
