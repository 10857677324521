import React, {useState, useEffect} from 'react';
import Slide from '@material-ui/core/Slide';

import {mostrarAlerta} from 'redux/actions/alertaActions';
import {useDispatch} from 'react-redux';

import './paneles.css';
import {getOffsetTop, getWidthPanmat} from 'components/constants';

import imgClose from 'img/close.svg';

import {DivW, TableW} from 'components/styles';

export default function PanelUtiles({setOpenPanel, title, panelnots, panelresums, eliminarNotas, eliminarResumenes}) {
    const dispatch = useDispatch();

    const [activePanel, setActivePanel] = useState(panelnots.length > 0 ? 1 : 2);
    const [activeMenuBottom, setactiveMenuBottom] = useState(false);

    const scrollToItem = (item) => {
        const elem = document.getElementById(item);
        window.scrollTo({
            top: getOffsetTop(elem) - 52 - 13,
            behavior: 'smooth',
        });
        setOpenPanel(false);
    };

    const tdClassNamePan = (q, ap, pl) => {
        let output = '';
        if (ap === 1 && q === 1) {
            output = 'activo';
        }
        if (ap === 2 && q === 2) {
            output = 'activo';
        }
        if (pl < 1) {
            output += ' disable';
        }
        return output;
    };

    const clearNotas = () => {
        setactiveMenuBottom(false);
        dispatch(
            mostrarAlerta(
                '¿Está seguro que desea eliminar anotaciones de "' + title + '"?',
                'Notas',
                eliminarNotas,
                'Eliminar',
                'Cancelar'
            )
        );
    };

    const clearResumenes = () => {
        setactiveMenuBottom(false);
        dispatch(
            mostrarAlerta(
                '¿Está seguro que desea eliminar resúmenes de "' + title + '"?',
                'Resumen',
                eliminarResumenes,
                'Eliminar',
                'Cancelar'
            )
        );
    };

    const descargaResumen = () => {
        setactiveMenuBottom(false);
        const elms = document.getElementsByClassName('s');

        let salida = 'Resumen de "' + title + '"\n\n';

        for (let i = 0; i < elms.length; i++) {
            if (elms[i].parentElement.tagName === 'A') {
                console.log(elms[i].parentElement.href);
                salida += `[mm: ${elms[i].innerText}]\n`;
            } else {
                salida += elms[i].innerText + '\n';
            }
        }

        const filename = 'Resumen de ' + title + '.txt';

        const uriContent = 'data:application/octet-stream,' + encodeURIComponent(salida);
        //var newWindow = window.open(uriContent, 'Resumen');

        const element = document.createElement('a');
        element.setAttribute('href', uriContent);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

        //var file = new File([salida], $scope.nodo_data.t+".txt", {type: "text/plain;charset=utf-8"});
    };

    let heightPanelCont = window.innerHeight - 109 + 'px';

    useEffect(() => {
        setactiveMenuBottom(false);
    }, [activePanel]);

    return (
        <>
            <div id="panelder">
                <DivW className="headpan">
                    <div className="wraperpan">
                        <button className="back" onClick={() => setOpenPanel(false)}></button>
                        <div className="panmat" style={{width: getWidthPanmat()}}>
                            {title}
                        </div>
                        {((activePanel === 1 && panelnots.length > 0) || (activePanel === 2 && panelresums.length > 0)) && (
                            <button className="submenu" onClick={() => setactiveMenuBottom(!activeMenuBottom)}></button>
                        )}
                    </div>
                </DivW>

                <TableW className="pander mt52">
                    <tbody>
                        <tr>
                            <td className={tdClassNamePan(1, activePanel, panelnots.length)} onClick={() => setActivePanel(1)}>
                                Notas ({panelnots.length})<div className="factivo"></div>
                            </td>
                            <td className={tdClassNamePan(2, activePanel, panelresums.length)} onClick={() => setActivePanel(2)}>
                                Resúmenes ({panelresums.length})<div className="factivo"></div>
                            </td>
                        </tr>
                    </tbody>
                </TableW>

                <DivW className="pandercont panelOverflow" style={{marginTop: '109px', height: heightPanelCont}}>
                    {activePanel === 1 && (
                        <ul className="itnotas">
                            {panelnots.map((item) => {
                                return (
                                    <li key={item.id} onClick={() => scrollToItem('n' + item.id)}>
                                        {item.tx}
                                    </li>
                                );
                            })}
                            {panelnots.length < 1 && <li className="none">No hay notas realizadas en esta página</li>}
                        </ul>
                    )}
                    {activePanel === 2 && (
                        <ul className="itresum">
                            {panelresums.map((item) => {
                                return (
                                    <li key={item.id} onClick={() => scrollToItem('n' + item.id)}>
                                        {item.tx}
                                    </li>
                                );
                            })}
                            {panelresums.length < 1 && <li className="none">No hay texto marcado en esta página</li>}
                        </ul>
                    )}

                    <Slide direction={'up'} in={activeMenuBottom} mountOnEnter unmountOnExit>
                        <DivW className="submenu_pander">
                            <div className="head_subm">
                                <div id="close_subm" onClick={() => setactiveMenuBottom(false)}>
                                    <img src={imgClose} width="10" height="10" alt="close" />
                                </div>
                                Opciones
                            </div>
                            {activePanel === 1 && (
                                <div className="cont_subm">
                                    <button className="btn_sm bgelim" onClick={clearNotas}>
                                        Eliminar notas
                                    </button>
                                </div>
                            )}
                            {activePanel === 2 && (
                                <div className="cont_subm">
                                    <button className="btn_sm bgdesc" onClick={descargaResumen}>
                                        Descargar resúmenes
                                    </button>
                                    <button className="btn_sm bgelim" onClick={clearResumenes}>
                                        Eliminar resúmenes
                                    </button>
                                </div>
                            )}
                        </DivW>
                    </Slide>
                </DivW>
            </div>
        </>
    );
}
