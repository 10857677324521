import React, { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setTitulo, setLoading } from 'redux/actions/aplication';
import { mostrarAlerta } from 'redux/actions/alertaActions';
import clienteAxios from 'config/axios';

import styled from '@emotion/styled';
import Avatar from '../components/avatar';

const Container = styled('div')`
    padding: 30px;
    display: flex;
    flex-direction: ${props => (props.ishtml ? 'column' : 'row')};
    font-size: 18px;
    border-bottom: 1px solid #707070;
    align-self: flex-start;
    width: 100%;
    transition: opacity 0.15s ease-out;
    .avt {
        margin-right: 15px;
        width: 42px;
        height: 42px;
    }
    .fromtext {
        width: 100%;
    }
    .responsive {
        max-width: 100%;
        height: auto;
    }
`;

const From = styled('h2')`
    font-size: 16px;
    font-weight: normal;
    color: #323232;
    padding: 0;
    margin: 0;
`;

const Fecha = styled('p')`
    font-size: 12px;
    font-weight: normal;
    color: #929191;
    padding: 0;
    margin: 0;
    font-style: italic;
    margin-bottom: 15px;
`;

const Text = styled('p')`
    font-size: 14px;
    font-weight: normal;
    color: #929191;
    padding: 0;
    margin: 0;
`;
const Link = styled('a')`
    font-size: 14px;
    font-weight: bold;
`;

export default function Mensaje() {
    const { id } = useParams();
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.usuario);
    const loading = useSelector(state => state.aplication.loading);

    const [mensaje, setMensaje] = useState();

    useTitle('mi IES - mensaje');

    useEffect(() => {
        dispatch(setTitulo('Mensaje'));
    }, [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));

            let result;

            try {
                result = await clienteAxios.get(`/mensaje`, { params: { id, rol: usuario.rol } });

                if (result.data.r === 0) {
                    dispatch(mostrarAlerta(result.data.text, 'Error'));
                    return false;
                }

                setMensaje(result.data.mensaje);
                dispatch(setTitulo(`Mensaje de ${result.data.mensaje.from}`));
            } catch (error) {
                dispatch(mostrarAlerta(error, 'Error'));
            } finally {
                dispatch(setLoading(false));
            }
        };
        if (Object.keys(usuario).length > 0) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [usuario, id]);

    if (loading || !mensaje) return null;

    const { avatar, data, date, from, aviso, params, isHtml, title } = mensaje;
    const { link, linkTitle } = data;

    const dataAvatar = { nombres: '', apellidos: '', initials: '', foto: avatar, moderador: false };

    if (params) console.log(params);

    return (
        <Container ishtml={isHtml}>
            <div className="avt">
                <Avatar usuario={dataAvatar} radius={42} />
            </div>
            <div className="fromtext">
                <From>{from}</From>
                <Fecha>{date}</Fecha>
                {title !== null && <Text>{title}</Text>}
                {link && (
                    <Link href={link} target="_blank" rel="noopener noreferrer">
                        {linkTitle}
                    </Link>
                )}
                {isHtml && (
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: aviso,
                        }}
                    />
                )}
            </div>
        </Container>
    );
}
