import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import clienteAxios from 'config/axios';
import SubLoading from 'components/loading';

import { useSnackbar } from 'notistack';

export default function DenunciaWin({ open, handleClose, data }) {
    const { enqueueSnackbar } = useSnackbar();

    const [tipo, setTipo] = useState('');
    const [texto, setTexto] = useState('');
    const [loading, setloading] = useState(false);

    const submitForm = async e => {
        e.preventDefault();

        if (tipo === '' || texto.length < 4) {
            enqueueSnackbar('Complete los datos solicitados.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            return;
        }

        setloading(true);

        data.tipoDenuncia = tipo;
        data.texto = texto;

        try {
            const result = await clienteAxios.post('/pregunta-denunciar', data);

            setloading(false);

            if (result.data.r === 0) {
                enqueueSnackbar(result.data.text, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
                return;
            }
            if (result.data === undefined) {
                enqueueSnackbar('No se pudo enviar la denuncia, disculpe las molestias', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
                return;
            }

            enqueueSnackbar('Hemos recibido la denuncia, en breve nos contactaremos', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });

            handleClose();
        } catch (error) {
            let errorstr = typeof error === 'object' ? error.toString() : error;
            /*if(error.response.status === 401){

            }*/
            setloading(false);
            enqueueSnackbar(errorstr, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <div className="dilogContent">
                <h2>Denunciar contenido inapropiado</h2>
                <p>
                    Docentes y directores de IES revisan el contenido denunciado para determinar si infringe los Términos del servicio o las Directrices
                    comunitarias. Si tenes una pregunta o un problema técnico, contacta con nuestro equipo de desarrollo.
                </p>
                <br />

                <form onSubmit={submitForm}>
                    <div className="form-group">
                        <div className="form-control-single-select-container">
                            <select required={true} className="form-control" onChange={e => setTipo(e.target.value)}>
                                <option value="">-- Tipo de incidencia --</option>
                                <option value="Contenido inadecuado">Contenido inadecuado</option>
                                <option value="Comportamiento inadecuado">Comportamiento inadecuado</option>
                                <option value="Contenido de spam">Contenido de spam</option>
                                <option value="Otros">Otros</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <textarea
                            required={true}
                            onChange={e => setTexto(e.target.value)}
                            placeholder="Describa el incidente"
                            rows="6"
                            className="textarea--discription form-control"
                        ></textarea>
                    </div>

                    <div className="DialogActions">
                        <button type="submit" disabled={loading} className="btn btn-primary">
                            {loading && <SubLoading />}Enviar
                        </button>
                        <button onClick={handleClose} className="btn btn-default">
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}
