import React, {useState, useEffect} from 'react';
import Note from 'components/tid/note/note';

import {useDispatch, useSelector} from 'react-redux';
import {guardaSesion} from 'redux/actions/tidActions';

export default function useUtiles({paginaRef}) {
    const dispatch = useDispatch();

    const sesionTid = useSelector((state) => state.dataTid.sesionTid);

    const [panelnots, setPanelnots] = useState([]);
    const [panelresums, setPanelresums] = useState([]);
    const [elem_highlighter, setElem_highlighter] = useState();
    const [noteTxt, setnoteTxt] = useState(false);

    const rangy = window.rangy;

    let serializedHighlights, serializedHighlights_notes;

    const [resaltadoResumen, setresaltadoResumen] = useState();
    const [resaltadoNotas, setresaltadoNotas] = useState();

    const marcarResumen = () => {
        resaltadoResumen.addClassApplier(
            rangy.createClassApplier('s', {
                ignoreWhiteSpace: true,
                elementTagName: 'span',
            })
        );

        resaltadoNotas.addClassApplier(
            rangy.createClassApplier('note', {
                ignoreWhiteSpace: true,
                elementTagName: 'span',
            })
        );

        

        if (sesionTid.sesion['resumen' + sesionTid.idn]) {
            //console.log(sesion['resumen'+idn]);
            serializedHighlights = sesionTid.sesion['resumen' + sesionTid.idn];
        } else {
            serializedHighlights = '';
        }
        if (sesionTid.sesion['notas' + sesionTid.idn]) {
            serializedHighlights_notes = sesionTid.sesion['notas' + sesionTid.idn];
            //console.log(sesion['notas'+idn]);
        } else {
            serializedHighlights_notes = '';
        }
        
        if (serializedHighlights !== '') {
            try {
                resaltadoResumen.deserialize(serializedHighlights);
            } catch (e) {
                console.log('Error marcarResumen');
                resetResumenes();
            }
            //console.log(rangy.isRangeValid)
        }

        if (serializedHighlights_notes !== '') {
            resaltadoNotas.deserialize(serializedHighlights_notes);
        }

        

        if (resaltadoResumen.highlights.filter((e) => e.containerElementId !== 'sc').length > 0) {
            console.log("no sc detected")
            resaltadoResumen.removeAllHighlights();
            let SH = serializedHighlights.split('|');
            let newSH = '';
            SH.forEach((e) => {
                if (e !== 'type:textContent') {
                    if (e.indexOf('$sc') !== -1) {
                        newSH = newSH + '|' + e;
                    }
                } else {
                    newSH = newSH + e;
                }
            });

            serializedHighlights = newSH;

            if (serializedHighlights === 'type:textContent') {
                serializedHighlights = '';
                resetResumenes();
            } else {
                resaltadoResumen.deserialize(serializedHighlights);
            }
        }

        setIdsNotas();
        setIdsResumenes();
    };

    useEffect(() => {
        if (resaltadoResumen && resaltadoNotas) {
            marcarResumen();
        }
        // eslint-disable-next-line
    }, [resaltadoResumen, resaltadoNotas]);

    const clearSelection = () => {
        if (window.getSelection) {
            window.getSelection().removeAllRanges();
        } else if (document.selection) {
            document.selection.empty();
        }
    };

    const setIdsNotas = () => {
        const query = paginaRef.current.querySelectorAll('.note');
        let panelnotsTmp = [];
        query.forEach((value) => {
            let tx = '';
            let hnid;
            if (resaltadoNotas.getHighlightForElement(value)) {
                hnid = resaltadoNotas.getHighlightForElement(value).id;
                value.id = 'n' + hnid;
                if (sesionTid.sesion['nota' + sesionTid.idn + '_' + hnid]) {
                    tx = sesionTid.sesion['nota' + sesionTid.idn + '_' + hnid];
                }
                panelnotsTmp.push({id: hnid, tx: trimText(tx, 60)});
            }
        });
        setPanelnots(panelnotsTmp);
        clearSelection();
    };

    const setIdsResumenes = () => {
        const query = paginaRef.current.querySelectorAll('.s');

        let hidant = 0;
        let panelresuTmp = [];

        query.forEach((value) => {
            try {
                let elemHg = resaltadoResumen.getHighlightForElement(value);
                if (elemHg) {
                    let hnid = elemHg.id;
                    if (hnid !== hidant && value.innerText !== '') {
                        value.id = 'n' + hnid;
                        panelresuTmp.push({id: hnid, tx: trimText(value.innerText, 60)});
                    }
                    hidant = hnid;
                }
            } catch (e) {
                console.log('Error resaltado', value);
            }
        });
        setPanelresums(panelresuTmp);
        clearSelection();
    };

    const trimText = (text, maxLength) => {
        if (text === null) {
            return '';
        }
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const setUnaPalabra = () => {
        const selection = rangy.getSelection();
        const range = selection.getRangeAt(0);

        range.expand('word', {
            wordOptions: {
                includeTrailingSpace: false,
            },
        });

        let txt_sel = rangy.getSelection().text();
        const regex = /\s+/gi;
        let cantwords = txt_sel.trim().replace(regex, ' ').split(' ').length;

        cantwords--;
        range.moveEnd('word', -cantwords);

        selection.setSingleRange(range);

        txt_sel = rangy.getSelection().text();
        cantwords = txt_sel.trim().replace(regex, ' ').split(' ').length;

        if (cantwords > 1) {
            setUnaPalabra();
        }
    };

    //type:textContent|0$5$70$s$sc|74$91$71$s$|448$456$72$s$sc
    const resaltarSelectedText = () => {
        try {
            resaltadoResumen.highlightSelection('s', {containerElementId: 'sc'});
        } catch (e) {
            console.log('Error resaltarSelectedText');
        }

        let tmpSesion = {...sesionTid.sesion};

        let actualHL = resaltadoResumen.serialize();

        //actualHL = 'type:textContent|0$5$70$s$sc|74$91$71$s$|448$456$72$s$sc'

        if (resaltadoResumen.highlights.filter((e) => e.containerElementId !== 'sc').length > 0) {
            resaltadoResumen.removeAllHighlights();
            console.log("no sc detect")
            let SH = actualHL.split('|');
            let newSH = '';
            SH.forEach((e) => {
                if (e !== 'type:textContent') {
                    if (e.indexOf('$sc') !== -1) {
                        newSH = newSH + '|' + e;
                    }
                } else {
                    newSH = newSH + e;
                }
            });

            actualHL = newSH;

            console.log(actualHL);
        }

        if (actualHL !== 'type:textContent') {
            resaltadoResumen.deserialize(actualHL);
            tmpSesion['resumen' + sesionTid.idn] = actualHL;
            setIdsResumenes();

            //setSesion(tmpSesion);

            dispatch(guardaSesion(sesionTid.idn, tmpSesion));
        }
    };

    const resetResumenes = () => {
        let tmpSesion = {...sesionTid.sesion};
        delete tmpSesion['resumen' + sesionTid.idn];
        setIdsResumenes();
        dispatch(guardaSesion(sesionTid.idn, tmpSesion));
    };

    const unResaltarSelectedText = () => {
        try {
            resaltadoResumen.unhighlightSelection();
        } catch (e) {
            console.log('Error unResaltarSelectedText');
        }

        let tmpSesion = {...sesionTid.sesion};

        tmpSesion['resumen' + sesionTid.idn] = resaltadoResumen.serialize();
        if (resaltadoResumen.serialize() === 'type:textContent') {
            delete tmpSesion['resumen' + sesionTid.idn];
        }

        //setSesion(tmpSesion);

        setIdsResumenes();

        //setSesion(tmpSesion);

        dispatch(guardaSesion(sesionTid.idn, tmpSesion));
    };

    const noteSelectedText = () => {
        setUnaPalabra();
        resaltadoNotas.highlightSelection('note', {containerElementId: 'sc'});
        let tmpSesion = {...sesionTid.sesion};
        tmpSesion['notas' + sesionTid.idn] = resaltadoNotas.serialize();
        setIdsNotas();
        //setSesion(tmpSesion);
        //guardaSesion(guardaSesion(sesionTid.idn, tmpSesion));
        dispatch(guardaSesion(sesionTid.idn, tmpSesion));
    };

    const viewNote = (target) => {
        let txtNota = false;
        if (resaltadoNotas.getHighlightForElement(target)) {
            let elem = resaltadoNotas.getHighlightForElement(target);
            setElem_highlighter(elem);
            const nota_actual = elem.id;

            if (sesionTid.sesion['nota' + sesionTid.idn + '_' + nota_actual]) {
                txtNota = sesionTid.sesion['nota' + sesionTid.idn + '_' + nota_actual];
            } else {
                txtNota = '';
            }
        }
        setnoteTxt(txtNota);
    };

    const closeNota = () => {
        setElem_highlighter(null);
        setnoteTxt(false);
    };

    const eliminarResumenes = function () {
        if (sesionTid.sesion['resumen' + sesionTid.idn]) {
            let tmpSesion = {...sesionTid.sesion};

            delete tmpSesion['resumen' + sesionTid.idn];

            resaltadoResumen.removeAllHighlights();

            setIdsResumenes();
            //setSesion(tmpSesion);
            dispatch(guardaSesion(sesionTid.idn, tmpSesion));
        }
    };

    const eliminarNotas = () => {
        if (sesionTid.sesion['notas' + sesionTid.idn]) {
            let tmpSesion = {...sesionTid.sesion};

            const query = paginaRef.current.querySelectorAll('.note');

            query.forEach((value) => {
                let hnid = resaltadoNotas.getHighlightForElement(value).id;
                delete tmpSesion['nota' + sesionTid.idn + '_' + hnid];
            });
            delete tmpSesion['notas' + sesionTid.idn];

            resaltadoNotas.removeAllHighlights();

            setIdsNotas();
            //setSesion(tmpSesion);
            dispatch(guardaSesion(sesionTid.idn, tmpSesion));
        }
    };

    const clearNode = () => {
        resaltadoResumen.removeAllHighlights();
        resaltadoNotas.removeAllHighlights();
    };

    const eliminaNota = () => {
        let tmpSesion = {...sesionTid.sesion};

        if (elem_highlighter) {
            const nota_actual = elem_highlighter.id;

            resaltadoNotas.removeHighlights([elem_highlighter]);

            delete tmpSesion['nota' + sesionTid.idn + '_' + nota_actual];

            resaltadoNotas.unhighlightSelection();
            tmpSesion['notas' + sesionTid.idn] = resaltadoNotas.serialize();

            if (resaltadoNotas.serialize() === 'type:textContent') {
                delete tmpSesion['notas' + sesionTid.idn];
            }
        }

        setnoteTxt(false);
        setElem_highlighter(null);

        setIdsNotas();
        //setSesion(tmpSesion);
        dispatch(guardaSesion(sesionTid.idn, tmpSesion));
    };

    const guardarNota = (txt) => {
        const nota_actual = elem_highlighter.id;

        let tmpSesion = {...sesionTid.sesion};

        tmpSesion['nota' + sesionTid.idn + '_' + nota_actual] = txt;

        setnoteTxt(false);
        setElem_highlighter(null);

        setIdsNotas();
        //setSesion(tmpSesion);
        dispatch(guardaSesion(sesionTid.idn, tmpSesion));
        //guardaSesion(guardaSesion(sesionTid.idn, tmpSesion));
    };

    const NoteNode = ({title}) => {
        if (noteTxt === false) return null;

        return (
            <Note
                txt={noteTxt}
                closeNota={closeNota}
                open={noteTxt !== false}
                guardarNota={guardarNota}
                eliminaNota={eliminaNota}
                title={title}
            />
        );
    };

    const updateUtiles = () => {
        if (sesionTid.idn && sesionTid && sesionTid.sesion && paginaRef.current) {
            rangy.init();
            rangy.config.alertOnWarn = false;
            rangy.config.alertOnFail = false;
            setresaltadoResumen(rangy.createHighlighter());
            setresaltadoNotas(rangy.createHighlighter());
        }
    };
    /*
    useEffect ( () => {
        if(idn && sesionTid && sesionTid.sesion && paginaRef.current){
            rangy.init();
            rangy.config.alertOnWarn = false;
            rangy.config.alertOnFail = false;
            setresaltadoResumen(rangy.createHighlighter());
            setresaltadoNotas(rangy.createHighlighter());
        }
        // eslint-disable-next-line
    }, [idn, paginaRef])

*/

    return {
        noteTxt,
        panelresums,
        panelnots,
        clearNode,
        updateUtiles,
        resaltarSelectedText,
        unResaltarSelectedText,
        noteSelectedText,
        viewNote,
        closeNota,
        eliminaNota,
        guardarNota,
        eliminarNotas,
        eliminarResumenes,
        NoteNode,
    };
}
