import { IMPORTAR_SESION, GUARDAR_SESION, CARGA_TID, ACTUALIZAR_AVANCE, ERROR_CARGA_TID, SETEAR_LOADING_TID } from 'redux/types';

import clienteAxios from 'config/axios';

export const cargarTid = (TID, rol) => {
    return async dispatch => {
        dispatch({
            type: SETEAR_LOADING_TID,
            payload: true,
        });

        try {
            const result = await clienteAxios.get('/get_tid', { params: { TID, rol } });

            if (result.data.r === 0) {
                dispatch({
                    type: ERROR_CARGA_TID,
                    payload: {
                        title: 'Error',
                        text: result.data.text,
                    },
                });
                return;
            }
            /*
            console.log(
                ' dni:' + usuario.dni + '\n TID:' + TID + '\n id_carrera:' + result.data.dataTid.id_carrera,
                '\n id_materia:' + result.data.dataTid.id_materia
            );
*/
            //console.log('eliminar linea !!!!!!!!!!!!!!!!');
            //dTid.consulta_doc = true;

            dispatch({
                type: CARGA_TID,
                payload: result.data,
            });
        } catch (error) {
            dispatch({
                type: ERROR_CARGA_TID,
                payload: {
                    title: 'Error',
                    text: error,
                },
            });

            dispatch({
                type: SETEAR_LOADING_TID,
                payload: false,
            });
        }
    };
};

export const setRealPercentView = avance => {
    return dispatch => {
        dispatch({
            type: ACTUALIZAR_AVANCE,
            payload: avance,
        });
    };
};

export const setAvance = (avance = null) => {
    return (dispatch, getState) => {
        const state = getState();
        const { sesionTid, dataTid, realPercentView } = state.dataTid;

        const dataAvance = avance ? avance : sesionTid.avanceNodos;

        let totalPoints = 0;
        let ptosvistos = 0;

        const indice = dataTid.indice;
        Object.keys(indice).forEach(key => {
            const puntaje = indice[key].p;
            totalPoints += puntaje;
            if (dataAvance['id' + indice[key].id]) {
                ptosvistos += puntaje;
            }
        });

        const newPercent = Math.ceil((ptosvistos * 100) / totalPoints);

        if (realPercentView !== newPercent && realPercentView > 0) {
            if (avance) {
                console.log('debe guardar avance');
                dispatch(guardaSesion(sesionTid.idn, null, avance));
            }
        }

        dispatch({
            type: ACTUALIZAR_AVANCE,
            payload: newPercent,
        });
    };
};

export const guardaSesion = (idnSes, sesion = null, avanceNodos = null) => {
    //let guardandoSesion;
    let dataSesionSendTmp;

    return async (dispatch, getState) => {
        const state = getState();

        const { sesionTid, dataTid } = state.dataTid;

        idnSes = parseInt(idnSes);

        const data = { idn: idnSes, TID: dataTid.TID };

        if (!dataSesionSendTmp) {
            // recien inicia
            dataSesionSendTmp = { ...sesionTid };
        }

        let hayCambio = false;

        if (avanceNodos) {
            // cambia avance
            data.avanceNodos = avanceNodos;
            dataSesionSendTmp.avanceNodos = avanceNodos;
            hayCambio = true;
            dispatch({
                type: GUARDAR_SESION,
                payload: { ...sesionTid, avanceNodos },
            });
            //setSesionTid({...sesionTid, avanceNodos});
        }

        if (sesion) {
            // cambia sesion
            data.sesion = sesion;
            dataSesionSendTmp.sesion = sesion;
            hayCambio = true;
            dispatch({
                type: GUARDAR_SESION,
                payload: { ...sesionTid, sesion },
            });
            // setSesionTid({...sesionTid, sesion});
        }

        if (dataSesionSendTmp.idn !== idnSes) {
            // cambia idn
            data.idn = idnSes;
            dataSesionSendTmp.idn = idnSes;
            hayCambio = true;
            dispatch({
                type: GUARDAR_SESION,
                payload: { ...sesionTid, idn: idnSes },
            });
            //setSesionTid({...sesionTid, idn:idnSes});
        }

        if (hayCambio) {
            //guardandoSesion = true;
            try {
                const result = await clienteAxios.post('/set_sesion', data);
                //guardandoSesion = false;
                if (parseInt(result.data.r) === 0) {
                    dispatch({
                        type: ERROR_CARGA_TID,
                        payload: {
                            title: 'TID',
                            text: result.data.text,
                        },
                    });
                }
            } catch (error) {
                //guardandoSesion = false;
                dispatch({
                    type: ERROR_CARGA_TID,
                    payload: {
                        title: 'Error',
                        text: error,
                    },
                });
            }
        } else {
            console.log('no guarda sesion idnSes:' + idnSes);
        }
    };
};

export const importarSesion = () => {
    return async (dispatch, getState) => {
        const state = getState();

        const { sesionTid, dataTid } = state.dataTid;

        const data = {
            id: dataTid.importa_ses_obj.id,
            TID: dataTid.TID,
        };

        try {
            const result = await clienteAxios.get('/get_sesion', { params: data });

            if (parseInt(result.data.r) === 0) {
                dispatch({
                    type: ERROR_CARGA_TID,
                    payload: {
                        title: 'Sesión',
                        text: result.data.text,
                    },
                });
            } else {
                dispatch({
                    type: IMPORTAR_SESION,
                    payload: {
                        ...sesionTid,
                        avanceNodos: JSON.parse(result.data.avanceNodos),
                        sesion: JSON.parse(result.data.sesion),
                        idn: result.data.idn,
                    },
                });

                // setDataTid({...dataTid, importa_ses_obj:{}});
            }
        } catch (error) {
            dispatch({
                type: ERROR_CARGA_TID,
                payload: {
                    title: 'Error',
                    text: error,
                },
            });
        }
    };
};
