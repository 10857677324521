import React from 'react';
import { CalendarInfoCss } from 'components/styles';
import { coloresModulos as colores } from '../../components/constants';

export default function Modulo({ modulo, i }) {
    let arrModulos = [];
    for (let index = 0; index < modulo.modulos; index++) {
        arrModulos.push(index);
    }

    return (
        <CalendarInfoCss>
            <tbody>
                <tr>
                    <td width="4" bgcolor={`#${colores[modulo.dinum][i]}`}></td>
                    <td width="6"></td>
                    <td className="f14">
                        <strong>
                            {modulo.hini}
                            <br />
                            {modulo.hfin}
                        </strong>
                    </td>
                    <td width="6"></td>
                    <td>
                        {modulo.materia}
                        <br />
                        <span className="f10">
                            módulos
                            <div className="modulos">
                                {arrModulos.map(index => {
                                    return <span key={index} style={{ backgroundColor: `#${colores[modulo.dinum][index]}` }}></span>;
                                })}
                            </div>{' '}
                            {modulo.aula}
                        </span>
                    </td>
                </tr>
            </tbody>
        </CalendarInfoCss>
    );
}
