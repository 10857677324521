import React from 'react';
import { stringToHslColor } from 'components/constants';
import ies21 from 'img/logo-ies-azul.svg';

export default function Avatar({ usuario, radius }) {
    const { nombres, apellidos, initials, foto, moderador } = usuario;
    const r = radius ? radius : 36;
    const mt = radius ? '0px' : '7px';
    const rpx = r + 'px';

    const fontSize = (radius / 28) * 0.8 + 'rem';

    return (
        <div className="positionRelative">
            {foto ? (
                <div>
                    {moderador ? <img src={ies21} alt="ies" className="moderador" /> : null}
                    <img
                        alt={`${nombres}, ${apellidos}`}
                        className="user-avatar user-avatar--image"
                        height={r}
                        width={r}
                        src={foto}
                        style={{ minWidth: rpx, minHeight: rpx }}
                    />
                </div>
            ) : (
                <div
                    className="user-avatar user-avatar--initials"
                    style={{
                        backgroundColor: stringToHslColor(nombres + apellidos),
                        fontSize: fontSize,
                        fontWeight: 'bold',
                        width: `${r}px`,
                        height: `${r}px`,
                        marginTop: mt,
                    }}
                >
                    {moderador ? <img src={ies21} alt="ies" className="moderador" /> : null}
                    <div className="user-avatar__inner fx-c">
                        <span className="user-initials">{initials}</span>
                    </div>
                </div>
            )}
        </div>
    );
}
