import React from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { ocultarAlerta } from 'redux/actions/alertaActions';

function SimpleAlert() {
    const dispatch = useDispatch();

    const dialogProps = useSelector(state => state.alerta.alerta);

    let history = useHistory();

    if (Object.keys(dialogProps).length < 1) return null;

    const { title, text, btn1, btn2, actionBtn1, actionBtn1Params } = dialogProps;

    const isConfirm = actionBtn1 !== null;

    let textOk = typeof text === 'object' ? text.toString() : text;

    if (!textOk) textOk = 'Ocurrió un problema';

    const handlerBtn1 = () => {
        dispatch(ocultarAlerta());
        if (isConfirm) {
            if (actionBtn1Params !== null) {
                actionBtn1(actionBtn1Params);
            } else {
                actionBtn1();
            }
        }
    };

    const handlerDefault = () => {
        if (textOk === 'Error: Network Error') {
            history.push('/perror/' + encodeURIComponent(history.location.pathname));
        }
        dispatch(ocultarAlerta());
    };

    return (
        <Dialog open={dialogProps !== null} onClose={() => dispatch(ocultarAlerta())} aria-labelledby="responsive-dialog-title">
            <div className="dilogContent">
                {title ? <h2 id="responsive-dialog-title">{title}</h2> : null}
                <p dangerouslySetInnerHTML={{ __html: textOk }}></p>
                <div className="DialogActions">
                    {isConfirm ? (
                        <>
                            {btn1 && (
                                <button autoFocus onClick={handlerBtn1} className="btn btn-primary">
                                    {btn1}
                                </button>
                            )}
                            {btn2 && (
                                <button onClick={() => dispatch(ocultarAlerta())} autoFocus className="btn btn-default">
                                    {btn2}
                                </button>
                            )}
                        </>
                    ) : (
                        <button onClick={handlerDefault} autoFocus className="btn btn-primary">
                            Aceptar
                        </button>
                    )}
                </div>
            </div>
        </Dialog>
    );
}

export default function Alert() {
    return ReactDOM.createPortal(<SimpleAlert />, document.getElementById('root'));
}
